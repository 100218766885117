import styled from 'styled-components';

export const Credits = styled.span`
  color: ${props => props.theme.colors.grayBlack};
  font-size: 12px;
  position: fixed;
  bottom: 8px;
  right: 8px;

  svg {
    margin-right: 5px;
  }
`;
