import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { ThemeContext } from 'styled-components';
import { uuid } from 'uuidv4';
import filesize from 'filesize';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Setor } from '../../../models/Setor';
import { Loading } from '../../../components/Loading';
import HeaderTable from '../../../components/HeaderTable';
import ModalConexao from '../../../components/ModalConexao';
import { InputOverview } from '../../../components/InputOverview';
import Search from '../../../components/Search';
import Pagination from '../../../components/Pagination';
import { SelectCustom } from '../../../components/SelectCustom';
import Button from '../../../components/Button';
import FileList from '../../../components/FileList';
import Upload from '../../../components/Upload';
import DropDownPagination from '../../../components/DropDownPagination';
import { ItemBase, ModalDuplicar } from '../../../components/ModalDuplicar';
import { arquivoModeloSetoresUrlAws } from '../../../utils/urlsBucketAws';
import getValidationErrors from '../../../utils/getValidationErrors';

import IconEdit from '../../../assets/IconEdit.svg';
import IconRemove from '../../../assets/IconRemove.svg';
import IconFileUploadBtn from '../../../assets/IconFileUploadBtn.svg';
import {
  Container,
  Content,
  Aba,
  ContainerAba,
  ContainerButton,
  AnimationContainer,
  ContainerTable,
  ContainerPagination,
  ContainerSemResultados,
  ConainerCadastroOption,
  RadioBox,
  ButtonDupl,
} from './styles';

interface SignUpFormData {
  empresa: number;
  nome: string;
  setor: string;
}

interface EmpresasInterface {
  ATIVO: boolean;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  ID: number;
  Id_Empresas: number;
  NOME: string;
  NUMERO: string;
}

interface Uploaded {
  file: File | null;
  id: string;
  name: string;
  readableSize: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  type: string;
}

interface SetorCheck extends Setor {
  checked: boolean;
}

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '40', id: '40' },
  { valor: '80', id: '80' },
  { valor: '160', id: '160' },
  { valor: 'Todos', id: '1' },
];

const headersCoord = [
  { name: 'Empresa', field: 'companyId', sortable: true },
  { name: 'Setor', field: 'name', sortable: true },
  { name: 'Editar', field: 'editar', sortable: false },
  { name: 'Remover', field: 'remover', sortable: false },
];

const headersViewer = [
  { name: 'Empresa', field: 'companyId', sortable: true },
  { name: 'Setor', field: 'name', sortable: true },
];

export const Setores: React.FC = () => {
  const { empresaPrincipal, user } = useAuth();
  const { addToast } = useToast();
  const { colors } = useContext(ThemeContext);
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loadingSet, setLoadingSet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [setorNomeEdit, setSetorNomeEdit] = useState('');
  const [response, setResponse] = useState<SetorCheck[]>([]);
  const [setor, setSetor] = useState<Setor>();
  const [cadastrar, setCadastrar] = useState(true);
  const [editar, setEditar] = useState(false);
  const [showModalDupl, setShowModalDupl] = useState(false);

  const [search, setSearch] = useState('');

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);
  const [sorting, setSorting] = useState({ field: '', order: '' });

  const [empresas, setEmpresas] = useState<EmpresasInterface[]>([]);
  const [empresaId, setEmpresaId] = useState<number>();
  const [editEmpresaId, setEditEmpresaId] = useState<number>();
  const [verificaResponse, setVerificaResponse] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState<Uploaded[]>([]);
  const [download, setDownload] = useState('');
  const [count, setCount] = useState(0);
  const [registerType, setRegisterType] = useState('individual');
  const formRefIndividual = useRef<FormHandles>(null);
  const formRefEmLote = useRef<FormHandles>(null);
  const [empresaIdLote, setEmpresaIdLote] = useState<number>();

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  const getSetors = useCallback(() => {
    setLoadingSet(true);
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
    const filialId = user.companyId;

    const aux = isCoordenador
      ? `filialId=${filialId}`
      : `companyId=${companyId}`;
    api
      .get(`/setor?${aux}`)
      .then(data => {
        setLoadingSet(false);
        const respSetor: Setor[] = data.data;

        setResponse(
          respSetor
            .filter(i => !i.deletedAt)
            .map(i => ({ ...i, checked: false })),
        );
      })
      .catch(() => {
        setLoadingSet(false);

        addToast({
          type: 'error',
          title: 'Erro',
          description:
            'Erro ao carregar os setores, por favor atualize a página',
        });
      });
  }, [addToast, empresaPrincipal, isCoordenador, user.companyId]);

  const getFiliais = useCallback(() => {
    setLoading(true);
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
    api
      .get(`empresas/filiais?companyId=${companyId}`)
      .then(resp => {
        const emp: EmpresasInterface[] = [];
        resp.data.forEach((filial: EmpresasInterface) => {
          if (filial.ATIVO === true) {
            emp.push(filial);
          }
        });
        setEmpresas(emp);
        setVerificaResponse(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);

        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, por favor atualize a página',
        });
      });
  }, [addToast, empresaPrincipal]);

  useEffect(() => {
    getSetors();
    getFiliais();
  }, [getFiliais, getSetors]);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRefIndividual.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome do setor é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let verificaSetorDuplicado = false;
        response.forEach(elementTemp => {
          if (
            elementTemp.name.toLowerCase() === data.nome.toLowerCase() &&
            elementTemp.companyId === empresaId
          )
            verificaSetorDuplicado = true;
        });

        if (!verificaSetorDuplicado) {
          await api
            .post('setor/', {
              name: data.nome,
              companyId: empresaId,
            })
            .then(() => {
              setLoading(false);

              addToast({
                type: 'success',
                title: 'Sucesso',
                description: 'Setor cadastrado com sucesso',
              });

              setTimeout(() => {
                history.go(0);
              }, 1000);
            })
            .catch(() => {
              setLoading(false);

              addToast({
                type: 'error',
                title: 'Erro',
                description: 'Erro ao cadastrar setor',
              });
            });
        } else {
          setLoading(false);

          addToast({
            type: 'info',
            title: 'Setor duplicado',
            description:
              'Já existe um setor cadastrado com o mesmo nome e empresa',
          });
        }
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRefIndividual.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Campos em branco',
            description: 'Existem campos obrigatórios não preenchidos.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao cadastrar o dados',
          });
        }
      }
    },
    [addToast, empresaId, history, response],
  );

  const handleSubmitEditar = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          setor: Yup.string().required('Nome do setor é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let verificaSetorDuplicado = false;
        response.forEach(elementTemp => {
          if (
            elementTemp.name.toLowerCase() === data.setor.toLowerCase() &&
            elementTemp.companyId === editEmpresaId &&
            elementTemp.id !== setor?.id
          )
            verificaSetorDuplicado = true;
        });

        if (!verificaSetorDuplicado) {
          await api
            .put(`setor/${setor?.id}`, {
              name: data.setor,
              empresa: editEmpresaId,
            })
            .then(() => {
              setLoading(false);

              addToast({
                type: 'success',
                title: 'Sucesso',
                description: 'Setor editado com sucesso',
              });

              setTimeout(() => {
                history.go(0);
              }, 1000);
            })
            .catch(() => {
              setLoading(false);

              addToast({
                type: 'error',
                title: 'Erro',
                description: 'Erro ao editar setor',
              });
            });
        } else {
          setLoading(false);

          addToast({
            type: 'error',
            title: 'Setor duplicado',
            description:
              'Já existe um setor cadastrado com o mesmo nome e empresa',
          });
        }
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Campos em branco',
            description: 'Existem campos obrigatórios não preenchidos.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao editar o dados',
          });
        }
      }
    },
    [addToast, editEmpresaId, history, response, setor],
  );

  const handleDelete = useCallback(
    async (dados: Setor) => {
      try {
        setLoading(true);

        await api
          .delete('/setor', { data: dados })
          .then(() => {
            setLoading(false);

            addToast({
              type: 'success',
              title: 'Sucesso',
              description: 'Setor deletado com sucesso',
            });

            setTimeout(() => {
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            setLoading(false);

            addToast({
              type: 'error',
              title: 'Erro',
              description: 'Erro ao deletar setor',
            });
          });
      } catch (err) {
        setLoading(false);

        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao deletar setor',
        });
      }
    },
    [addToast, history],
  );

  //* ********************************************************************************************* */

  const handleUpload = useCallback(
    files => {
      const uploadedFilesHandleUpload: Uploaded[] = files.map((file: File) => ({
        file,
        id: uuid(),
        name: file.name,
        readableSize: filesize(file.size),
        progress: 0,
        uploaded: false,
        error: false,
        type: '.csv, application/vnd.ms-excel, text/csv',
      }));

      setUploadedFiles(uploadedFiles.concat(uploadedFilesHandleUpload));
    },
    [uploadedFiles],
  );

  const updateFile = useCallback((id, data) => {
    setUploadedFiles(estadoAnterior =>
      estadoAnterior.map(file =>
        id === file.id ? { ...file, ...data } : file,
      ),
    );
  }, []);

  const processUpload = useCallback(
    (uploadedFile: Uploaded) => {
      const data = new FormData();

      if (uploadedFile.file && empresaIdLote) {
        data.append('csvSetores', uploadedFile.file);
        data.append('idEmpresaFilial', empresaIdLote.toString());
      }

      api
        .post('cadastroViaCSV/setor', data, {
          onUploadProgress: e => {
            const progress = Math.round((e.loaded * 100) / e.total);

            updateFile(uploadedFile.id, {
              progress,
            });
          },
        })
        .then(() => {
          updateFile(uploadedFile.id, {
            uploaded: true,
          });

          setTimeout(() => {
            history.go(0);
          }, 1500);
        })
        .catch(() => {
          updateFile(uploadedFile.id, {
            error: true,
          });
        });
    },
    [empresaIdLote, history, updateFile],
  );

  const clickHandleUpload = useCallback(() => {
    if (!uploadedFiles.length) {
      addToast({
        type: 'error',
        title: 'Erro ao cadastrar lista de setores!',
        description:
          'É necessário inserir um arquivo .CSV válido. Favor baixar o arquivo modelo pré-formatado para o preenchimento correto.',
      });
    } else {
      uploadedFiles.forEach(processUpload);
    }
  }, [addToast, processUpload, uploadedFiles]);

  const handleDelete2 = useCallback(
    async id => {
      uploadedFiles.splice(
        uploadedFiles.findIndex(element => element.id === id),
        1,
      );
      setUploadedFiles([...uploadedFiles]);
    },
    [uploadedFiles],
  );

  //* ********************************************************************************************* */

  const handleAlertMaster = useCallback(() => {
    if (verificaResponse) {
      setCadastrar(false);
      setDownload('');
      setSearch('');
    }
  }, [verificaResponse]);

  const onDuplicSetor = useCallback(
    (company: number, setories: ItemBase[]) => {
      setLoading(true);
      Promise.all(
        setories.map(set =>
          api.post('setor/', {
            name: set.name,
            companyId: company,
          }),
        ),
      )
        .then(() => {
          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Setores duplicados com sucesso',
          });

          setTimeout(() => {
            history.go(0);
          }, 1000);
        })
        .catch(err => {
          console.log('err', err.message);
          console.log('err', err.response.data);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao duplicar os setores',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [addToast, history],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  const responseData = useMemo(() => {
    let computedResponses: SetorCheck[] = [];
    computedResponses = response.length ? response : [];

    if (search) {
      computedResponses = computedResponses.filter(res =>
        [res.companyId, res.name].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        ),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (typeof a[sorting.field] === 'object' && a[sorting.field] != null) {
          return (
            reversed *
            a[sorting.field]
              .join(', ')
              .localeCompare(b[sorting.field].join(', '))
          );
        }
        if (typeof a[sorting.field] === 'string') {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        }

        const aTemp = a[sorting.field] != null ? a[sorting.field] : '';
        const bTemp = b[sorting.field] != null ? b[sorting.field] : '';
        return reversed * aTemp.toString().localeCompare(bTemp.toString());
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    search,
    sorting.field,
    sorting.order,
    ITEMS_PER_PAGE,
    currentPage,
  ]);

  const headers = [
    {
      name:
        responseData.filter(i => !i.checked).length !== 0
          ? 'Marcar Todos'
          : 'Desmarcar Todos',
      field: 'marked',
      sortable: false,
      onClick: () => {
        const isMarkedAll = responseData.filter(i => !i.checked).length === 0;
        if (isMarkedAll) {
          setResponse(
            response.map(res => {
              const find = responseData.find(resData => resData.id === res.id);
              if (find) {
                return { ...res, checked: false };
              }
              return res;
            }),
          );
        } else {
          setResponse(
            response.map(res => {
              const find = responseData.find(resData => resData.id === res.id);
              if (find) {
                return { ...res, checked: true };
              }
              return res;
            }),
          );
        }
      },
    },
    { name: 'Empresa', field: 'companyId', sortable: true },
    { name: 'Setor', field: 'name', sortable: true },
    { name: 'Editar', field: 'editar', sortable: false },
    { name: 'Remover', field: 'remover', sortable: false },
  ];

  const empresaSel = useMemo(() => {
    if (empresaId === undefined && empresas.length > 0) {
      const id = isCoordenador ? user.companyId : empresas[0].ID;
      let aux = '';
      empresas.forEach(item => {
        if (item.ID === id) {
          aux = `${item.ID} - ${item.NOME}`;
          setEmpresaId(item.ID);
        }
      });
      return aux;
    }
    let aux = '';
    empresas.forEach(item => {
      if (item.ID === empresaId) {
        aux = `${item.ID} - ${item.NOME}`;
      }
    });
    return aux;
  }, [empresaId, empresas, isCoordenador, user.companyId]);

  const empresaLoteSel = useMemo(() => {
    if (empresaIdLote === undefined && empresas.length > 0) {
      const id = isCoordenador ? user.companyId : empresas[0].ID;
      let aux = '';
      empresas.forEach(item => {
        if (item.ID === id) {
          aux = `${item.ID} - ${item.NOME}`;
          setEmpresaIdLote(item.ID);
        }
      });
      return aux;
    }
    let aux = '';
    empresas.forEach(item => {
      if (item.ID === empresaIdLote) {
        aux = `${item.ID} - ${item.NOME}`;
      }
    });
    return aux;
  }, [empresaIdLote, empresas, isCoordenador, user.companyId]);

  const loadingMemo = useMemo(() => {
    return loading || loadingSet;
  }, [loading, loadingSet]);

  return (
    <>
      <ModalConexao />

      <ModalDuplicar
        isVisible={showModalDupl}
        listAll={response.map(i => ({
          color: '',
          companyId: i.companyId,
          id: i.id,
          name: i.name,
        }))}
        list={response
          .filter(i => i.checked)
          .map(i => ({
            color: '',
            companyId: i.companyId,
            id: i.id,
            name: i.name,
          }))}
        filiais={empresas}
        onClose={() => setShowModalDupl(false)}
        onDuplic={onDuplicSetor}
        title="Duplicar Setores"
        isAtributeExtra={false}
      />

      <Container>
        {empresaPrincipal.length > 0 ? (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button type="button" onClick={() => setCadastrar(true)}>
                Listar setores
              </button>
            </ContainerAba>
            <ContainerAba className="aba1" cor={!cadastrar}>
              <button type="button" onClick={handleAlertMaster}>
                Cadastrar setor
              </button>
            </ContainerAba>

            <Tooltip
              title="Nessa página você tem acesso a visualização, cadastro e edição de todos os setores que os ambientes cadastrados se encontram"
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        ) : (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button type="button">Listar setores</button>
            </ContainerAba>

            <Tooltip
              title="Nessa página você tem acesso a visualização de todos os setores que os ambientes cadastrados se encontram"
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        )}

        {cadastrar && !editar && (
          <Search
            onSearch={(value: string) => {
              setSearch(value);
            }}
            nomePlaceHolder="Buscar"
          />
        )}

        {!cadastrar && (
          <ConainerCadastroOption>
            <RadioBox
              type="button"
              onClick={() => setRegisterType('individual')}
            >
              Cadastro individual
            </RadioBox>
            <RadioBox
              type="button"
              onClick={() => {
                setDownload('');
                setRegisterType('lote');
              }}
            >
              Cadastro em lote
            </RadioBox>
          </ConainerCadastroOption>
        )}

        {!cadastrar &&
          (registerType === 'individual' ? (
            <AnimationContainer>
              <Form ref={formRefIndividual} onSubmit={handleSubmit}>
                <p>Empresa</p>
                <SelectCustom
                  name="Empresa"
                  defaultValue="Empresa"
                  optionsDataCompany={empresas}
                  value={empresaSel}
                  disabled={isCoordenador}
                  onValue={e => setEmpresaId(parseInt(e.split('-')[0], 10))}
                />

                <p>Nome</p>
                <InputOverview name="nome" placeholder="Nome do setor" />
                <Button type="submit" widthProps="100%">
                  Cadastrar
                </Button>
              </Form>
            </AnimationContainer>
          ) : (
            <AnimationContainer>
              <Form ref={formRefEmLote} onSubmit={clickHandleUpload}>
                <p>Empresa</p>
                <SelectCustom
                  name="Empresa"
                  defaultValue="Empresa"
                  optionsDataCompany={empresas}
                  value={empresaLoteSel}
                  disabled={isCoordenador}
                  onValue={e => setEmpresaIdLote(parseInt(e.split('-')[0], 10))}
                />
                <Content>
                  <Upload onUpload={(e: any) => handleUpload(e)} />
                  {!!uploadedFiles.length && (
                    <FileList
                      files={uploadedFiles}
                      onDelete={(e: string) => handleDelete2(e)}
                    />
                  )}
                </Content>
                <ContainerButton>
                  <Button type="submit" widthProps="100%">
                    Cadastrar
                  </Button>
                  <Button
                    type="button"
                    widthProps="100%"
                    className="divButtonDownload"
                    onClick={() => {
                      setDownload(arquivoModeloSetoresUrlAws);
                      setCount(old => old + 1);
                    }}
                  >
                    <img src={IconFileUploadBtn} alt="iconFileUploadBtn" />
                    Baixar Modelo
                  </Button>
                  {download && (
                    <iframe
                      title="downloadModeloCsv"
                      src={`${download}?c=${count}`}
                      hidden
                    />
                  )}
                </ContainerButton>
              </Form>
            </AnimationContainer>
          ))}

        {cadastrar && !response.length && (
          <ContainerSemResultados>
            <h2>Não existem setores cadastrados ainda.</h2>
          </ContainerSemResultados>
        )}

        {cadastrar && !editar && !!response.length && (
          <>
            <ContainerTable>
              <table>
                <HeaderTable
                  headers={
                    // eslint-disable-next-line no-nested-ternary
                    empresaPrincipal.length > 0
                      ? !isCoordenador
                        ? headers
                        : headersCoord
                      : headersViewer
                  }
                  onSorting={(field: string, order: string) => {
                    setSorting({ field, order });
                  }}
                />
                <tbody>
                  {responseData.map(res => (
                    <tr key={res.id}>
                      {!isCoordenador && (
                        <td>
                          <input
                            type="checkbox"
                            checked={res.checked}
                            onChange={() => {
                              setResponse(
                                response.map(i =>
                                  i.id === res.id
                                    ? { ...i, checked: !i.checked }
                                    : i,
                                ),
                              );
                            }}
                          />
                        </td>
                      )}
                      <td>{res.companyId}</td>
                      <td>{res.name}</td>
                      {empresaPrincipal.length > 0 && (
                        <>
                          <td style={{ width: 'auto' }}>
                            <button
                              type="button"
                              onClick={() => {
                                setEditar(true);
                                setSetorNomeEdit(res.name);
                                setEditEmpresaId(res.companyId);
                                setSetor(res);
                              }}
                            >
                              <img src={IconEdit} alt="iconEdit" />
                            </button>
                          </td>
                          <td style={{ width: 'auto' }}>
                            <button
                              type="button"
                              onClick={() => {
                                Swal.fire({
                                  title: 'Tem certeza que deseja remover?',
                                  icon: 'warning',
                                  showCancelButton: true,
                                  cancelButtonText: 'Não',
                                  confirmButtonColor: colors.greenPrimary,
                                  cancelButtonColor: colors.redPrimary,
                                  confirmButtonText: 'Sim',
                                }).then(result => {
                                  if (result.isConfirmed) {
                                    handleDelete(res);
                                  }
                                });
                              }}
                            >
                              <img src={IconRemove} alt="iconRemove" />
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>

              <table id="tableListagemSetores" style={{ display: 'none' }}>
                <HeaderTable
                  headers={
                    empresaPrincipal.length > 0 ? headers : headersViewer
                  }
                  onSorting={(field: string, order: string) => {
                    setSorting({ field, order });
                  }}
                />
                <tbody>
                  {responseData.map(res => (
                    <tr key={res.id}>
                      <td>{res.companyId}</td>
                      <td>{res.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ContainerTable>

            <ContainerPagination>
              {response.length > 0 && (
                <>
                  <div className="divPaginacao">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page: number) => setCurrentPage(page)}
                    />
                    <DropDownPagination
                      onChangeItems={(value: string) => {
                        setCurrentPage(1);
                        if (value === 'Todos') {
                          setITEMS_PER_PAGE(1);
                        } else {
                          setITEMS_PER_PAGE(Number(value));
                        }
                      }}
                      objetoEnum={dropDown}
                      minWidth={30}
                    />
                  </div>
                  <div style={{ display: 'flex', columnGap: 10 }}>
                    <ReactHTMLTableToExcel
                      id="export-excel"
                      className="btn"
                      table="tableListagemSetores"
                      filename={`listagem-setores-${moment().format(
                        'DD-MM-YYYY',
                      )}`}
                      sheet="tablexls"
                      buttonText="Exportar Excel"
                    />
                    {responseData.filter(i => i.checked).length > 0 &&
                      !isCoordenador && (
                        <ButtonDupl
                          type="button"
                          onClick={() => setShowModalDupl(true)}
                        >
                          Duplicar procedimentos
                        </ButtonDupl>
                      )}
                  </div>
                </>
              )}
            </ContainerPagination>
          </>
        )}

        {cadastrar && editar && (
          <AnimationContainer>
            <header>
              <h1>Editar setor</h1>
            </header>

            <Form ref={formRef} onSubmit={handleSubmitEditar}>
              <p>Nome</p>
              <InputOverview
                name="setor"
                placeholder="Nome do Setor"
                value={setorNomeEdit}
                onValue={value => setSetorNomeEdit(value)}
              />

              <ContainerButton>
                <Button
                  className="divButtonCancelar"
                  type="button"
                  widthProps="100%"
                  onClick={() => setEditar(false)}
                >
                  Cancelar
                </Button>
                <Button type="submit" widthProps="100%">
                  Salvar
                </Button>
              </ContainerButton>
            </Form>
          </AnimationContainer>
        )}
      </Container>

      {loadingMemo && <Loading />}
    </>
  );
};
