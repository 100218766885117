import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 120px);
  overflow: auto;
  overflow-x: hidden;

  header {
    padding-bottom: 30px;
    border-bottom: 0.6px solid ${props => props.theme.colors.grayBlack};
    margin-bottom: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 45px;
      color: ${props => props.theme.colors.textBlack};
    }
  }

  p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: ${props => props.theme.colors.textBlack};
    margin-bottom: 8px;
    margin-top: 16px;
  }

  select {
    background: ${props => props.theme.colors.whitePrimary};
    border: 1px solid ${props => props.theme.colors.grayWhite};
    box-sizing: border-box;
    border-radius: 8px;
    padding: 15px 16px;
    width: 100%;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;

    flex: 1;
    display: flex;
    align-items: center;

    color: ${props => props.theme.colors.textBlack};

    &::placeholder {
      color: ${props => props.theme.colors.grayBlack};
    }

    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }

  .calendar {
    display: flex;
    column-gap: 16px;
    button {
      height: 48px;
      width: 48px;
      border-radius: 24px;
      background-color: ${props => props.theme.colors.bluePrimary};
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s;

      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: ${props => props.theme.colors.whitePrimary};
    }
    .selected {
      background-color: ${props => props.theme.colors.bluePrimary};
      color: ${props => props.theme.colors.whitePrimary};
      &:hover {
        background-color: ${props =>
          shade(0.2, props.theme.colors.bluePrimary)};
      }
    }
    .notSelected {
      background-color: ${props => props.theme.colors.whitePrimary};
      border: 1px solid ${props => props.theme.colors.grayWhite};
      color: ${props => props.theme.colors.textBlack};
      &:hover {
        background-color: ${props =>
          shade(0.08, props.theme.colors.whitePrimary)};
      }
    }
  }

  input {
    background: ${props => props.theme.colors.whitePrimary};
    border: 1px solid ${props => props.theme.colors.grayWhite};
    box-sizing: border-box;
    border-radius: 8px;
    padding: 15px 16px;
    width: 100%;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;

    flex: 1;
    display: flex;
    align-items: center;

    color: ${props => props.theme.colors.textBlack};

    &::placeholder {
      color: ${props => props.theme.colors.grayBlack};
    }
  }

  span {
    font-weight: 500;
    font-size: 12px;
    color: ${props => props.theme.colors.grayBlack};
  }
  .horario {
    display: flex;
    column-gap: 24px;
  }
`;

export const CheckLabel = styled.div`
  display: flex;
  margin-top: 20px;
  column-gap: 0.6rem;
  align-items: center;
  justify-content: flex-start;

  input {
    height: 1.2rem;
    width: 1.2rem;
    max-width: 30px;
    padding: 0px;
    margin: 0px;
  }

  p {
    margin: 0px !important;
  }
`;

interface PropsActive {
  active: boolean;
}

export const DivActive = styled.div<PropsActive>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    margin-right: 20px;

    button {
      background: none;
      border: none;
      svg {
        height: 2rem;
        width: 2rem;
      }
    }
    .accept {
      svg {
        height: 2.2rem;
        width: 2.2rem;
        color: ${props =>
          props.active
            ? props.theme.colors.greenPrimary
            : props.theme.colors.grayBlack};
      }
    }
    .reject {
      svg {
        color: ${props =>
          !props.active
            ? props.theme.colors.redPrimary
            : props.theme.colors.grayBlack};
      }
    }
  }
`;

export const ButtonRed = styled.button`
  background: ${props =>
    props.disabled
      ? shade(0.2, props.theme.colors.redPrimary)
      : props.theme.colors.redPrimary};
  border-radius: 16px;

  height: 50px;
  width: 345px;
  left: 0px;
  top: 0px;
  border-radius: 16px;
  border: none;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;

  color: ${props => props.theme.colors.whitePrimary};

  transition: background 0.2s;

  &:hover {
    background: ${props => shade(0.2, props.theme.colors.redPrimary)};
  }

  @media screen and (max-width: 992px) {
    width: 90%;
  }
`;
