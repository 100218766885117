import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (max-width: 700px) {
    width: 90vw;
  }
  display: flex;
  flex-direction: column;
  width: 600px;
  max-height: 70vh;
  overflow: auto;
  padding-right: 26px;
  padding-left: 26px;

  header {
    padding-bottom: 21px;
    margin-top: 0.4rem;
    border-bottom: 0.8px solid ${props => props.theme.colors.grayBlack};
    margin-bottom: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 16px;
      color: ${props => props.theme.colors.textBlack};
    }
  }
`;

export const DivAtiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .action {
    display: flex;
    column-gap: 1rem;

    button {
      height: 30px;
      width: 30px;
      background-color: ${props => props.theme.colors.whitePrimary};
      border: none;

      svg {
        font-size: 30px;
      }
    }
  }
`;

export const TableDiv = styled.table`
  width: 100%;
  background: ${props => props.theme.colors.grayWhite};
  border-collapse: collapse;
  margin-bottom: 2rem;

  thead {
    background: ${props => props.theme.colors.grayWhite};

    th {
      padding: 20px;
      text-align: left;
      white-space: nowrap;

      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 17px;
      color: ${props => props.theme.colors.textBlack};
    }

    th:nth-last-child(-n + 1) {
      text-align: center;
    }
  }

  tbody {
    tr {
      td {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: ${props => props.theme.colors.textBlack};
        padding: 15px;
        white-space: nowrap;

        button {
          border: none;
          background: transparent;
          margin: 0 auto;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      td.action {
        display: flex;

        button {
          height: 30px;
          width: 30px;

          svg {
            font-size: 30px;
          }
        }
      }

      &:nth-child(odd) {
        background-color: ${props => props.theme.colors.whitePrimary};
      }
    }
  }
`;
