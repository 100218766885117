import styled, { keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  padding: 30px 40px 20px 40px;

  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 33px;
      color: ${props => props.theme.colors.textBlack};
    }

    span {
      margin-left: 20px;

      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 20px;
        height: 20px;
        color: ${props => props.theme.colors.bluePrimary};
      }
    }
  }

  section {
    margin-top: 24px;
    max-height: calc(100vh - 300px);
    overflow: auto;
    padding: 0 5px 5px 0;
  }
`;

export const ContainerCard = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 30px;
  animation: ${appearFromLeft} 1s;

  & + div {
    margin-top: 16px;
  }

  h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: ${props => props.theme.colors.textBlack};
  }

  p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
    color: ${props => props.theme.colors.textBlack};

    a {
      text-decoration: underline;
    }
  }

  footer {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: ${props => props.theme.colors.grayBlack};
    margin-top: 12px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    button {
      background: ${props => props.theme.colors.whitePrimary};
      box-shadow: 0px 10px 16px -2px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      border: none;
      padding: 10px 24px;
      margin-left: 20px;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
        height: 20px;
      }

      & + button {
        margin: 0 0 0 16px;
      }
    }
  }
`;

export const ContainerPagination = styled.div`
  width: 100%;
  padding: 40px 60px 10px 60px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
