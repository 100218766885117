import styled from 'styled-components';

export const Container = styled.div`
  margin: 24px -10px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  button {
    height: 45px;
    width: 45px;
    border: none;
    border-radius: 50%;
    background: ${props => props.theme.colors.grayWhite};
    padding: 10px;
    margin: 10px;
    transition: 0.2s;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: ${props => props.theme.colors.grayBlack};

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      filter: brightness(0.9);
    }
  }

  button:first-child {
    width: 350px;
    padding: 12px;
    background: ${props => props.theme.colors.bluePrimary};
    border-radius: 25px;
    margin-right: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 12px;
    }

    p {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: ${props => props.theme.colors.whitePrimary};
      margin: 0;
    }
  }
`;
