import axios from 'axios';

export const baseUrlWeb = 'https://gestaoqrcode.com/info/ambiente/';

// const baseUrlTest = 'http://127.0.0.1:3001/';
// const baseUrlProducaoAux = 'https://api.appgestaoqrcode.com/';
const baseUrlProducaoNew = 'https://prod.appgestaoqrcode.com/';
// const baseUrlAux = 'http://159.65.218.57';
// const baseUrlProdTest = 'https://teste.appgestaoqrcode.com';
// const baseUrlNgrokAux = 'https://72d87f3bfdb8.ngrok.io';

const api = axios.create({
  // baseURL: baseUrlTest, // API de produção
  // baseURL: baseUrlProducaoAux, // API de produção
  baseURL: baseUrlProducaoNew, // API de produção
  // baseURL: baseUrlProdTest, // API de desenvolvimento
  // baseURL: baseUrlProdTest,
  // baseURL: baseUrlNgrokAux, // API de teste
});

export default api;
