import { Categoria, EmptyCategoria } from './Categoria';
import { Procedure, ProcedureMandatory } from './Procedures';
import { EmptySetor, Setor } from './Setor';

export interface AmbienteGet {
  ID: number;
  AMBIENTE_NOME: string;
  CATEGORIA: string;
  SETOR: string;
  EMPRESA: string;
  // procedimento_obrigatorio: null;
  // QTD: null;
  ANDAR: string;
  HORA_INICIAL_PRINCIPAL_AMBIENTE: string;
  HORA_FINAL_PRINCIPAL_AMBIENTE: string;
  HORA_REVISAO_INICIAL: string;
  HORA_REVISAO_FINAL: string;
  companyId: number;
  empresaMae_id: number;
  REVISAO_ID: number;
  PROCEDIMENTOS: {
    name: string;
    mandatory: boolean;
  }[];
  RISCOS: string[];
  REVISAO_INICIAL: string[];
  REVISAO_FINAL: string[];
  ID_REVISAO: number[];
  ID_USER: number[];
  CREATEDAT: string[];
}

export interface Ambiente {
  id: number;
  name: string;
  categoriaId: number;
  companyId: number;
  setorId: number;
  // diasLimpezaId: null;
  qtdLimpezaDia: number;
  andar: string;
  createdAt: string;
  deletedAt: string;
  updatedAt: string;
  // Revisao: null;
  HORA_INICIAL: string;
  HORA_FINAL: string;
  IMAGEM: string;
  Setor: Setor;
  Categoria: Categoria;
  Procedures: Procedure[];
  proceduresOptions: ProcedureMandatory[];
}

export const EmptyAmbiente: Ambiente = {
  andar: '',
  categoriaId: -1,
  companyId: -1,
  createdAt: '',
  HORA_FINAL: '',
  HORA_INICIAL: '',
  id: -1,
  IMAGEM: '',
  name: '',
  qtdLimpezaDia: 0,
  setorId: -1,
  updatedAt: '',
  Setor: EmptySetor,
  Categoria: EmptyCategoria,
  Procedures: [],
  proceduresOptions: [],
  deletedAt: '',
};
