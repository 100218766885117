import { Ambiente, EmptyAmbiente } from './Ambiente';
import { Empresa, empresaEmpty } from './Empresa';
import { EmptyPeriodicidade, Periodicidade } from './Periodicidade';
import { UserGet } from './User';

export interface Agendav2 {
  ID: number;
  Id_Environments: number;
  Environment: Ambiente;
  Id_Periodicidade: number;
  Periodicidade: Periodicidade;
  Id_Users: number;
  Id_EmpresasFilial: number;
  FINALIZADO: null;
  DATA_FINALIZACAO: null;
  ATIVO: boolean;
  revisionPhotos: boolean;
  vigIni: string;
  vigFim: string;
  plus?: boolean;
  isRevisao?: boolean;
  Empresa: Empresa;
  deletedAt: string | null;
}

export interface AgendaRem {
  ID: number;
  Id_Environments: number;
  Id_Periodicidade: number;
  Id_Users: number;
  Id_EmpresasFilial: number;
  DATA_CRIACAO: string;
  FINALIZADO: string | null;
  DATA_FINALIZACAO: string | null;
  ATIVO: boolean;
  revisionPhotos: boolean;
  vigIni: string;
  vigFim: string;
  deletedAt: string | null;
}

export const EmptyAgendav2: Agendav2 = {
  ATIVO: false,
  DATA_FINALIZACAO: null,
  Environment: EmptyAmbiente,
  FINALIZADO: null,
  ID: -1,
  Id_EmpresasFilial: -1,
  Id_Environments: -1,
  Id_Periodicidade: -1,
  Id_Users: -1,
  Periodicidade: EmptyPeriodicidade,
  revisionPhotos: false,
  vigFim: '',
  vigIni: '',
  Empresa: empresaEmpty,
  deletedAt: null,
};

export interface AgendaGet {
  ID: number;
  Id_Environments: number;
  Environment: Ambiente;
  Id_Periodicidade: number;
  Periodicidade: Periodicidade;
  Id_Users: number;
  Id_EmpresasFilial: number;
  FINALIZADO: null;
  DATA_FINALIZACAO: null;
  ATIVO: boolean;
  revisionPhotos: boolean;
  vigIni: string;
  vigFim: string;
  plus?: boolean;
  isRevisao?: boolean;
  deletedAt: string | null;
  DATA_CRIACAO: string;
  User: UserGet;
}
