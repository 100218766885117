import React, { useState, useContext, useEffect } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { AiOutlineClockCircle } from 'react-icons/ai';

import { ThemeContext } from 'styled-components';
import { FiTrash } from 'react-icons/fi';
import IconEditWhite from '../../assets/IconEditWhite.svg';

import {
  HeaderMonth,
  HeaderWeek,
  Content,
  ButtonWeek,
  ButtonCard,
  ContainerSemResultados,
} from './styles';

import * as S from './styles';

import { Agendamentos } from '../../models/Agendamentos';
import {
  addMonthsInDate,
  capitalizeFirstLetterOfWords,
  formatDate,
  getAllDaysInMonth,
  getStartAndEndOfMonth,
} from '../../utils';

export interface DiaSemana {
  dia: number;
  diaDaSemana: number;
  dataFormat: Date;
}

interface ShedulesType extends Agendamentos {
  isRevision?: boolean;
}

export interface DataRange {
  dayEnd: number;
  mouth: number;
  year: number;
  dateCurrent: string;
  dateChoose: string;
}

interface CalendarioProps {
  data: ShedulesType[];
  type?: 'leitura' | 'escrita' | 'remove';
  dataSearch?: string;
  setRange: React.Dispatch<
    React.SetStateAction<{
      firstDayOfMonth: Date;
      lastDayOfMonth: Date;
    }>
  >;
  onClickCardEdit?: (x: ShedulesType, y: string) => void;
  isVisibleRemoveAgenda?: boolean;
  onClickRemoveAgenda?: (idAgendmt: number) => void;
}

interface DataFormatterd {
  hour: string;
  data: ShedulesType[];
}

export const filterSchedulesData = (
  tasks: any[],
  searchQuery: string,
): ShedulesType[] =>
  tasks.filter(task => {
    const searchString = searchQuery.toLowerCase();

    const matchProperties = [
      'horaFim',
      'horaIni',
      'User.email',
      'User.nome',
      'Agenda.Environment.name',
      'Agenda.Environment.Setor.name',
    ];

    return matchProperties.some(prop => {
      const propValue = prop.split('.').reduce((obj, key) => obj?.[key], task);
      if (propValue && propValue.toLowerCase().includes(searchString)) {
        return true;
      }
      return false;
    });
  });

export const Calendario: React.FC<CalendarioProps> = ({
  data,
  type = 'escrita',
  dataSearch = '',
  onClickCardEdit = (x: ShedulesType, y: string) => {
    return [x, y];
  },
  setRange,
  isVisibleRemoveAgenda = false,
  onClickRemoveAgenda = () => console.log('Function'),
}) => {
  const { colors } = useContext(ThemeContext);
  const [schedulesCurrents, setSchedulesCurrents] = useState<
    DataFormatterd[] | []
  >([]);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const handleChangeDate = (numberMonths: number) => {
    const newDate = addMonthsInDate(currentDate, numberMonths);

    setRange({
      firstDayOfMonth: newDate,
      lastDayOfMonth: newDate,
    });
    setCurrentDate(newDate);
  };

  const categorizeByHour = (array: ShedulesType[]): DataFormatterd[] => {
    const categorizedData: any = array.reduce((categorized: any, obj: any) => {
      const horaIni = obj.horaIni.split(':')[0];

      if (!categorized[horaIni]) {
        categorized[horaIni] = [];
      }

      categorized[horaIni].push(obj);
      return categorized;
    }, {});

    const resultFormatted = Object.keys(categorizedData).map(hora => ({
      hour: hora,
      data: categorizedData[hora],
    }));

    const sortByHourAscending = (a: any, b: any) => {
      return a.hour.localeCompare(b.hour);
    };

    return resultFormatted.sort(sortByHourAscending);
  };

  const compareTimes = (time1: string, time2: string): number => {
    const date1 = new Date(`1970-01-01T${time1}`);
    const date2 = new Date(`1970-01-01T${time2}`);

    if (date1 < date2) {
      return -1;
    } else if (date1 > date2) {
      return 1;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const currentDayFormatted = formatDate(currentDate, 'yyyy-MM-dd');
    const filterSchedules = filterSchedulesData(data, dataSearch);

    const listCurrentSchedules = filterSchedules.filter(schedule => {
      return (
        formatDate(
          new Date(schedule.dataAgend.toString().replace('Z', '')),
          'yyyy-MM-dd',
        ) === currentDayFormatted
      );
    });

    const allRevision = listCurrentSchedules.reduce((acumulator, schedules) => {
      const revisoes = schedules.Agenda?.Periodicidade?.Revisoes || [];
      const revisoesFormatted = revisoes.map(revisao => ({
        ...revisao,
        ...schedules,
        horaFim: revisao.HORA_FINAL,
        horaIni: revisao.HORA_INICIAL,
        isRevision: true,
      }));
      return acumulator.concat(revisoesFormatted as any);
    }, []);

    setSchedulesCurrents(
      categorizeByHour([...allRevision, ...listCurrentSchedules]),
    );
  }, [currentDate, data, dataSearch]);

  return (
    <>
      <HeaderMonth>
        <div>
          <h2>
            {capitalizeFirstLetterOfWords(
              formatDate(currentDate, 'MMMM, yyyy'),
            )}
          </h2>

          <button
            type="button"
            onClick={() => {
              handleChangeDate(-1);
            }}
          >
            <IoIosArrowBack />
          </button>

          <button
            type="button"
            onClick={() => {
              handleChangeDate(1);
            }}
          >
            <IoIosArrowForward />
          </button>
        </div>
      </HeaderMonth>

      <Content typeProps={type}>
        <div style={{ overflow: 'auto' }}>
          <HeaderWeek>
            {getAllDaysInMonth(currentDate).map(date => (
              <ButtonWeek
                key={date.getDate().toString()}
                type="button"
                onClick={() => {
                  setCurrentDate(date);
                  setRange({
                    firstDayOfMonth: date,
                    lastDayOfMonth: date,
                  });
                }}
                isChecked={
                  formatDate(date, 'yyyy-MM-dd') ===
                  formatDate(currentDate, 'yyyy-MM-dd')
                }
              >
                <p>
                  {date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}
                </p>
              </ButtonWeek>
            ))}
          </HeaderWeek>
        </div>
        {!schedulesCurrents.length && (
          <ContainerSemResultados>
            <h2>Não existem agendamentos cadastrados ainda.</h2>
          </ContainerSemResultados>
        )}
        {/* <BodyMonth> */}
        <S.SheduleWrapper>
          {/*@ts-ignore*/}
          {schedulesCurrents.map((item, index) => (
            <S.SheduleLineItem>
              <S.HoursContainer>
                <AiOutlineClockCircle />
                <p>{item.hour}h</p>
              </S.HoursContainer>
              <S.SheduleContainer
                key={index}
                className={index === schedulesCurrents.length - 1 ? 'last' : ''}
              >
                {item.data
                  .sort((a: any, b: any) => compareTimes(a.horaIni, b.horaFim))
                  .map((schedules: ShedulesType, index: number) => (
                    <ButtonCard
                      key={index}
                      backgroundProps={colors.bluePrimary}
                      typeProps={type}
                      onClick={() => {
                        if (type === 'remove') {
                          onClickRemoveAgenda(schedules.id);
                        } else {
                          onClickCardEdit(
                            schedules,
                            formatDate(
                              new Date(schedules.dataAgend),
                              'yyyy-MM-dd',
                            ),
                          );
                        }
                      }}
                    >
                      <ul>
                        <li>{`Nome: ${schedules.User.nome}`}</li>
                        <li>{`Ambiente: ${schedules.Agenda.Environment.name}`}</li>
                        <li>{`Horário: De ${schedules.horaIni}h até ${schedules.horaFim}h`}</li>
                        <li>{`Tipo: ${
                          !!schedules?.isRevision ? 'Revisão' : 'Principal'
                        }`}</li>
                        {type === 'escrita' && (
                          <img src={IconEditWhite} alt="IconEditWhite" />
                        )}
                        {type === 'remove' && <FiTrash color="#FFFFFF" />}
                      </ul>
                    </ButtonCard>
                  ))}
              </S.SheduleContainer>
            </S.SheduleLineItem>
          ))}
        </S.SheduleWrapper>
      </Content>
    </>
  );
};
