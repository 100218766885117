/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useCallback, useEffect, useState, useContext } from 'react';

import { ThemeContext } from 'styled-components';
import IconCalendar from '../../../../assets/IconCalendar.svg';

import { Container } from './styles';

interface CalendarioProps {
  day: string;
  status: boolean;
}

interface CalendarioPropsReturn {
  dadosEdit?: string;
  onWeekSelect: (e: string) => void;
}

const week = [
  { day: 'Domingo', dayAbrev: 'D' },
  { day: 'Segunda', dayAbrev: 'S' },
  { day: 'Terça', dayAbrev: 'T' },
  { day: 'Quarta', dayAbrev: 'Q' },
  { day: 'Quinta', dayAbrev: 'Q' },
  { day: 'Sexta', dayAbrev: 'S' },
  { day: 'Sábado', dayAbrev: 'S' },
];

export const Calendario: React.FC<CalendarioPropsReturn> = ({
  dadosEdit,
  onWeekSelect = (e: string) => {
    return e;
  },
}) => {
  const { colors } = useContext(ThemeContext);
  const [buttonsCheck, setButtonsCheck] = useState<CalendarioProps[]>([]);
  const [weekSelect, setWeekSelect] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);

  useEffect(() => {
    const buttonsCheckAux: CalendarioProps[] = [];
    week.forEach(day => buttonsCheckAux.push({ day: day.day, status: false }));

    if (dadosEdit && dadosEdit !== '') {
      const dadosEditAux = dadosEdit.replace(/\s+/g, '').split('-');

      dadosEditAux.forEach(dayCheck => {
        const findIndexButton = buttonsCheckAux.findIndex(
          button => button.day === dayCheck,
        );

        if (findIndexButton !== -1) {
          buttonsCheckAux[findIndexButton] = { day: dayCheck, status: true };
        }
      });
    }

    setButtonsCheck([...buttonsCheckAux]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let onWeekSelectAux = '';

    buttonsCheck.forEach(button => {
      if (button.status && onWeekSelectAux === '') {
        onWeekSelectAux = button.day;
      } else if (button.status) {
        onWeekSelectAux = `${onWeekSelectAux} - ${button.day}`;
      }
    });

    onWeekSelectAux.split('-').length === 7
      ? setWeekSelect(true)
      : setWeekSelect(false);

    onWeekSelect(onWeekSelectAux);
  }, [buttonsCheck, onWeekSelect]);

  const findButtonCheck = useCallback(
    (indexButton: number, dayButton: string) => {
      const buttonsCheckAux: CalendarioProps[] = [...buttonsCheck];

      buttonsCheckAux.forEach((button, index) => {
        if (index === indexButton) {
          button.status
            ? (buttonsCheckAux[index] = { day: dayButton, status: false })
            : (buttonsCheckAux[index] = { day: dayButton, status: true });
        }
      });

      setButtonsCheck([...buttonsCheckAux]);
    },
    [buttonsCheck],
  );

  return (
    <Container>
      <button
        type="button"
        onClick={() => {
          if (!weekSelect) {
            setButtonsCheck([
              { day: 'Domingo', status: true },
              { day: 'Segunda', status: true },
              { day: 'Terça', status: true },
              { day: 'Quarta', status: true },
              { day: 'Quinta', status: true },
              { day: 'Sexta', status: true },
              { day: 'Sábado', status: true },
            ]);
            onWeekSelect(
              'Domingo - Segunda - Terça - Quarta - Quinta - Sexta - Sábado',
            );
            setWeekSelect(!weekSelect);
          } else {
            setButtonsCheck([
              { day: 'Domingo', status: false },
              { day: 'Segunda', status: false },
              { day: 'Terça', status: false },
              { day: 'Quarta', status: false },
              { day: 'Quinta', status: false },
              { day: 'Sexta', status: false },
              { day: 'Sábado', status: false },
            ]);
            onWeekSelect('');
            setWeekSelect(!weekSelect);
          }
        }}
        onMouseOver={() => setButtonHover(true)}
        onMouseLeave={() => setButtonHover(false)}
      >
        {!buttonHover ? (
          <>
            <img src={IconCalendar} alt="iconCalendar" /> <p>Calendário</p>
          </>
        ) : !weekSelect ? (
          <p>Marcar todos</p>
        ) : (
          <p>Desmarcar todos</p>
        )}
      </button>

      {buttonsCheck.length === week.length &&
        week.map((day, index) => (
          <button
            key={day.day}
            type="button"
            onClick={() => findButtonCheck(index, day.day)}
            style={{
              color: buttonsCheck[index].status ? colors.whitePrimary : '',
              backgroundColor: buttonsCheck[index].status
                ? colors.bluePrimary
                : '',
            }}
          >
            {day.dayAbrev}
          </button>
        ))}
    </Container>
  );
};
