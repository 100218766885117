/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import jsPDF from 'jspdf';
import moment from 'moment';
import { uuid } from 'uuidv4';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Zoom from 'react-img-zoom';

import { AiOutlineException } from 'react-icons/ai';
import { VscFilePdf } from 'react-icons/vsc';
import { RiFileExcel2Line } from 'react-icons/ri';
import { FiAlertCircle, FiAlertTriangle } from 'react-icons/fi';

import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
// import { Loading } from '../Loading';
// import api from '../../services/api';
// import { useAuth } from '../../hooks/auth';
import Button from '../Button';
import HeaderTable from '../HeaderTable';
import Search from '../Search';
import { maskCpf } from '../InputOverview/mask';

import {
  ContainerModal,
  ContainerHeader,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
  ContainerMap,
  ContainerMapDetails,
  ContainerButtonDetalhes,
  ContainerButtonsPdfExcel,
  ContainerSearch,
  ContainerTableMacon,
  IconeAtrasado,
  ButtonDetalhes,
  ContainerListCards,
  ContainerMapObservacao,
  ZoomImg,
  ButtonImgNull,
  ButtonImg,
  HistoricoVazio,
} from './styles';
import { Ambiente } from '../../models/Ambiente';
import { HistoryProcedimentoSlim } from '../../models/Historic';

Modal.setAppElement('#root');

export interface HistoricTaskAmb {
  id: number;
  Ambiente: {
    id: number;
    nome: string;
  };
  nameUser: string;
  dateAgend: string;
  horaFinalAmbiente: string;
  horaInicialAmbiente: string;
  revisions: {
    horaIni: string;
    horaFim: string;
  }[];
  createdAt: string;
  finishAt: string;
  imagem: string;
  idTask: number;
  nameTask: string;
  observacao: string;
  description: string;
  isRevision: boolean;
  status: string;
  userId: number;
}

export interface HistoryAmbienteDate {
  ambiente: Ambiente;
  periodicidade: {
    horaIni: string;
    horaEnd: string;
    revisoes: {
      id: number;
      horaIni: string;
      horaEnd: string;
    }[];
  };
  historys: DateHistory[];
}

export interface UserModalProps {
  name: string;
  cpf: string;
  email: string;
  telefone: string;
}

export interface DateHistory {
  dataAgend: string;
  historys: HistoryProcedimentoSlim[];
}

interface ModalDetalhesAmbientes {
  isOpen: boolean;
  user: UserModalProps;
  dados: HistoricTaskAmb[];
  dadosHistoricoDatas: string;
  onRequestClose: () => void;
}

const headers = [
  { name: 'Colaborador', field: 'nomeColaborador' },
  { name: 'CPF', field: 'cpf' },
  { name: 'E-mail', field: 'email' },
  { name: 'Telefone', field: 'telefone' },
  { name: 'Ambiente', field: 'nomeAmbiente' },
  {
    name: 'Horário base (agendado)',
    field: 'horarioMarcadoPrimeiraLimpeza',
  },
  { name: 'Data e hora inicial (realizado)', field: 'dataInicial' },
  { name: 'Data e hora final (realizado)', field: 'dataFinal' },
  { name: 'Observação', field: 'observacao' },
];

const headersRevisions = [
  { name: 'Colaborador', field: 'nomeColaborador' },
  { name: 'Nome', field: 'nameUser' },
  { name: 'CPF', field: 'cpf' },
  { name: 'E-mail', field: 'email' },
  { name: 'Telefone', field: 'telefone' },
  { name: 'Ambiente', field: 'nomeAmbiente' },
  { name: 'Status', field: 'status' },
  {
    name: 'Horário base (agendado)',
    field: 'horarioMarcadoPrimeiraLimpeza',
  },
  { name: 'Horário da revisão (agendado)', field: 'horarioMarcadoRevisao' },
  { name: 'Data e hora inicial (realizado)', field: 'dataInicial' },
  { name: 'Data e hora final (realizado)', field: 'dataFinal' },
  { name: 'Procedimento', field: 'procedimento' },
  { name: 'Observação', field: 'observacao' },
  { name: 'Descrição', field: 'description' },
  {
    name: 'Horários das revisões (realizado)',
    field: 'horarioRealizadoRevisao',
  },
  { name: 'Foto', field: 'foto' },
];

export const ModalHistoricoColaboradorV2: React.FC<ModalDetalhesAmbientes> = ({
  isOpen,
  dados,
  user,
  dadosHistoricoDatas,
  onRequestClose,
}) => {
  const [search, setSearch] = useState('');
  const [searchDetails, setSearchDetails] = useState('');
  // const [responseTasks, setResponseTasks] = useState<ResponseGetTasks[]>([]);
  // const [
  //   dataHistoryButtom,
  //   setDataHistoryButtom,
  // ] = useState<HistoricTaskAmb>();

  const [dadosHistoricoExcelPdf, setDadosHistoricoExcelPdf] = useState<
    HistoricTaskAmb[]
  >([]);
  const [detalhesHistoricoExcelPdf, setDetalhesHistoricoExcelPdf] = useState<
    HistoricTaskAmb[]
  >([]);
  const [newDetalhesHistorico, setNewDetalhesHistorico] = useState<
    HistoricTaskAmb[]
  >([]);
  const [exibirDetalhes, setExibirDetalhes] = useState(false);
  // const [dadosCopia, setDadosCopia] = useState<HistoricTaskAmb[]>([]);
  const [ambienteToHistory, setAmbienteToHistory] = useState<HistoricTaskAmb[]>(
    [],
  );
  const [ambienteIdChoose, setAmbienteIdChoose] = useState(-1);
  const [dateChoose, setDateChoose] = useState('');
  const [zoomImg, setZoomImg] = useState('');
  const [img, setImg] = useState<string | HistoricTaskAmb[]>();

  useEffect(() => {
    setAmbienteToHistory(
      dados.sort((a, b) => {
        const aNumber = Number(a.dateAgend.split('-').join(''));
        const bNumber = Number(b.dateAgend.split('-').join(''));
        if (aNumber > bNumber) return 1;
        return aNumber < bNumber ? -1 : 0;
      }),
    );
  }, [dados]);

  const filterSchedulesReviewsDone = useCallback(
    (res: HistoricTaskAmb, numberHist: number) => {
      const result: string[] = [];
      res.revisions.forEach((i, index) => {
        if (index < numberHist - 1) {
          result.push(`${i.horaIni} - ${i.horaFim}`);
        }
      });

      return result.length ? result.join(', ') : '-';
    },
    [],
  );

  const handleDownloadPDF = useCallback(
    (dadosHistoricoParam: HistoricTaskAmb[], type: string) => {
      const doc = new jsPDF(); // eslint-disable-line
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      doc.setFontType('bold');
      doc.text('Histórico do Colaborador', 103, 20, 'center');
      doc.setFontSize(14);
      // tamanho 207 tela a4
      let posY: number;
      posY = 30;

      doc.text(`${user.name}`, 103, posY, 'center');

      // inicio bloco de dados pessoais
      dadosHistoricoParam.forEach((res, index) => {
        index === 0 ? (posY += 28) : (posY += 20);

        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('Ambiente:', 10, posY, 'left');
        let quebraLinha = doc.splitTextToSize(`${res.Ambiente.nome}`, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 33, posY, { align: 'left', maxWidth: 175 });

        if (type === 'detalhes') {
          posY += 6;
          doc.setFontType('bold');
          doc.setFontSize(12);
          doc.text('Nome Colaborador:', 10, posY, 'left');
          quebraLinha = doc.splitTextToSize(`${res.nameUser}`, 70);
          doc.setFontType('normal');
          doc.text(quebraLinha, 51, posY, { align: 'left', maxWidth: 175 });
        }

        if (type === 'detalhes') {
          posY += 6;
          doc.setFontType('bold');
          doc.setFontSize(12);
          doc.text('Status:', 10, posY, 'left');
          quebraLinha = doc.splitTextToSize(`${res.status}`, 70);
          doc.setFontType('normal');
          doc.text(quebraLinha, 26, posY, { align: 'left', maxWidth: 175 });
        }

        posY += 6;
        doc.setFontType('bold');
        doc.text('Horário base (agendado):', 10, posY, 'left');
        quebraLinha = doc.splitTextToSize(
          res.horaInicialAmbiente && res.horaFinalAmbiente
            ? `${res.horaInicialAmbiente} - ${res.horaFinalAmbiente}`
            : '-',
          70,
        );
        doc.setFontType('normal');
        doc.text(quebraLinha, 63, posY, { align: 'left', maxWidth: 175 });

        const filterScheduledReviewsAux = res.revisions
          .map(i => `${i.horaIni} - ${i.horaFim}`)
          .join(', ');
        const filterScheduledReviewsAuxLength = filterScheduledReviewsAux.split(
          '-',
        ).length;

        posY += 6;
        doc.setFontType('bold');
        doc.text('Horário da revisão (agendado):', 10, posY, 'left');
        quebraLinha = doc.splitTextToSize(filterScheduledReviewsAux, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 74, posY, { align: 'left', maxWidth: 130 });
        let posYAux;

        if (Math.ceil(filterScheduledReviewsAuxLength / 9) > 1) {
          posYAux = (filterScheduledReviewsAuxLength / 9) * 11;
        } else {
          posYAux = 6;
        }

        posY += posYAux;
        doc.setFontType('bold');
        doc.text('Data e hora inicial (realizado):', 10, posY, 'left');
        quebraLinha = doc.splitTextToSize(
          !res.createdAt
            ? '-'
            : `${moment(new Date(res.createdAt)).format(
                'DD/MM/YYYY HH:mm:ss',
              )}`,
          70,
        );
        doc.setFontType('normal');
        doc.text(quebraLinha, 73, posY, { align: 'left', maxWidth: 175 });

        posY += 6;
        doc.setFontType('bold');
        doc.text('Data e hora final (realizado):', 10, posY, 'left');
        quebraLinha = doc.splitTextToSize(
          !res.finishAt
            ? '-'
            : `${moment(new Date(res.finishAt)).format('DD/MM/YYYY HH:mm:ss')}`,
          70,
        );
        doc.setFontType('normal');
        doc.text(quebraLinha, 70, posY, { align: 'left', maxWidth: 175 });

        if (type === 'detalhes') {
          posY += 6;
          doc.setFontType('bold');
          doc.text('Procedimento:', 10, posY, 'left');
          quebraLinha = doc.splitTextToSize(
            `${!res.nameTask ? '-' : res.nameTask}`,
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 41, posY, { align: 'left', maxWidth: 175 });
        }

        posY += 6;
        doc.setFontType('bold');
        doc.text('Observação:', 10, posY, 'left');
        quebraLinha = doc.splitTextToSize(res.observacao, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 39, posY, { align: 'left', maxWidth: 175 });

        if (type === 'detalhes') {
          posY += 6;
          doc.setFontType('bold');
          doc.text('Descrição:', 10, posY, 'left');
          quebraLinha = doc.splitTextToSize(
            `${!res.description ? '-' : res.description}`,
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 35, posY, { align: 'left', maxWidth: 175 });
        }

        posY += 6;
        doc.setFontType('bold');
        doc.text('Horário das revisões (realizado):', 10, posY, 'left');
        quebraLinha = doc.splitTextToSize(
          `${filterSchedulesReviewsDone(res, dadosHistoricoParam.length)}`,
          70,
        );
        doc.setFontType('normal');
        doc.text(quebraLinha, 78, posY, { align: 'left', maxWidth: 120 });

        if (posY > 207) {
          doc.addPage();
          posY = 0;
        }
      });

      doc.autoPrint();
      doc.output('dataurlnewwindow');
    },
    [user.name, filterSchedulesReviewsDone],
  );

  const responseData = useMemo(() => {
    let computedResponses = ambienteToHistory;

    computedResponses = [
      ...new Map(
        computedResponses.map(item => [
          `${item.Ambiente.id}-${item.dateAgend}`,
          item,
        ]),
      ).values(),
    ];

    if (search) {
      computedResponses = computedResponses.filter((res: HistoricTaskAmb) => {
        // const dataInicio = moment(new Date(res.createdAt)).format(
        //   'DD/MM/YYYY HH:mm:ss',
        // );
        // const dataFim = moment(new Date(res.finishAt)).format(
        //   'DD/MM/YYYY HH:mm:ss',
        // );

        return [res.Ambiente.nome, res.dateAgend].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        );
      });
    }

    setDadosHistoricoExcelPdf(computedResponses);
    return computedResponses;
  }, [ambienteToHistory, search]);

  const responseDataDetalhes = useMemo(() => {
    let computedResponses: HistoricTaskAmb[] = ambienteToHistory;
    // const computedResponsesExcelPdf: HistoricTaskAmb[] = ambienteToHistory;

    computedResponses = computedResponses.filter(
      i => i.Ambiente.id === ambienteIdChoose && i.dateAgend === dateChoose,
    );

    if (searchDetails) {
      computedResponses = computedResponses.filter((res: HistoricTaskAmb) =>
        res.nameTask
          .toString()
          .toLowerCase()
          .includes(searchDetails.toLowerCase()),
      );
    }

    setDetalhesHistoricoExcelPdf(computedResponses);
    setNewDetalhesHistorico(computedResponses.filter(i => i.isRevision));
    return computedResponses;
  }, [ambienteIdChoose, ambienteToHistory, dateChoose, searchDetails]);

  const handleZoomImg = useCallback((imgString: string) => {
    setZoomImg('principal');
    setImg(imgString);
  }, []);

  const handleZoomImgRevisions = useCallback(
    (imgObjects: HistoricTaskAmb[]) => {
      setZoomImg('revisoes');
      setImg(imgObjects);
    },
    [],
  );

  // const checkOutOfHours = useCallback(
  //   (
  //     horarioMarcadoInit,
  //     horarioRealizadoInit,
  //     horarioMarcadoFinal,
  //     horarioRealizadoFinal,
  //   ) => {
  //     if (
  //       horarioRealizadoInit < horarioMarcadoInit ||
  //       horarioRealizadoInit > horarioMarcadoFinal ||
  //       horarioRealizadoFinal < horarioMarcadoInit ||
  //       horarioRealizadoFinal > horarioMarcadoFinal
  //     ) {
  //       return true;
  //     }

  //     return false;
  //   },
  //   [],
  // );

  const onClose = useCallback(() => {
    setSearch('');
    setSearchDetails('');
    setZoomImg('');
    setNewDetalhesHistorico([]);
    setExibirDetalhes(false);
    onRequestClose();
  }, [onRequestClose]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ContainerModal>
        {!exibirDetalhes && (
          <>
            <ContainerHeaderDados>
              Histórico do Colaborador
            </ContainerHeaderDados>

            <ContainerSearch>
              <Search
                onSearch={(value: string) => {
                  setSearch(value);
                }}
                nomePlaceHolder="Buscar"
              />

              <strong>{dadosHistoricoDatas}</strong>
            </ContainerSearch>
          </>
        )}
        {exibirDetalhes && zoomImg === '' && (
          <>
            <ContainerHeaderDados>Detalhes do Histórico</ContainerHeaderDados>

            <ContainerSearch>
              <Search
                onSearch={(value: string) => {
                  setSearchDetails(value);
                }}
                nomePlaceHolder="Buscar procedimento"
              />

              <strong>{dadosHistoricoDatas}</strong>
            </ContainerSearch>
          </>
        )}

        {!exibirDetalhes && (
          <ContainerHeader>
            {responseData.length !== 0 ? (
              <ContainerListCards>
                {responseData.map(res => (
                  <ContainerMap key={`${res.id}-${res.Ambiente.id}`}>
                    <ul>
                      <li>
                        <strong>Ambiente</strong>
                        <span>
                          {!res.Ambiente.nome ? '-' : res.Ambiente.nome}
                        </span>
                      </li>
                      <li>
                        <strong>Data (expectativa)</strong>
                        <span>
                          {!res.dateAgend
                            ? '-'
                            : moment(res.dateAgend).format('DD/MM/YYYY')}
                        </span>
                      </li>
                      {/* <li>
                        <strong>Data e hora final (realizado)</strong>
                        <span>
                          {res.finishAt
                            ? '-'
                            : moment(res.finishAt).format(
                                'DD/MM/YYYY HH:mm:ss',
                              )}
                        </span>
                      </li> */}
                    </ul>

                    <article>
                      {/* <IconeAtrasado>
                            {findNoteDate(res) && (
                              <FiAlertCircle
                                onClick={() => onButtonClick(res)}
                              />
                            )}
                          </IconeAtrasado> */}

                      <ButtonDetalhes
                        type="button"
                        onClick={() => [
                          setAmbienteIdChoose(res.Ambiente.id),
                          setDateChoose(res.dateAgend),
                          setExibirDetalhes(true),
                        ]}
                      >
                        <IoIosArrowForward />
                      </ButtonDetalhes>
                    </article>

                    {/* {res.escondido === true && (
                      <ContainerMapObservacao>
                        <ul>
                          <li>
                            <strong>Tarefa não realizada</strong>
                            <span>{findTaskNode(res)}</span>
                          </li>
                          <li>
                            <strong>Observação</strong>
                            <span>{findNoteDateUnic(res)}</span>
                          </li>
                        </ul>

                        <ButtonDetalhes
                          type="button"
                          onClick={() => onButtonClick(res)}
                        >
                          <IoIosArrowBack />
                        </ButtonDetalhes>
                      </ContainerMapObservacao>
                    )} */}
                  </ContainerMap>
                ))}
              </ContainerListCards>
            ) : (
              <HistoricoVazio>
                <p>Dados não encontrados para o colaborador atual</p>
              </HistoricoVazio>
            )}
          </ContainerHeader>
        )}

        {exibirDetalhes && zoomImg === '' && (
          <ContainerHeader>
            <ContainerListCards>
              {responseDataDetalhes
                .filter(i => !i.isRevision)
                .map(res => (
                  <ContainerMapDetails key={`${res.id}-${res.idTask}`}>
                    {!res.imagem || res.imagem.split('/').pop() === 'null' ? (
                      <ButtonImgNull type="button">
                        <AiOutlineException size={80} />
                      </ButtonImgNull>
                    ) : (
                      <ButtonImg
                        type="button"
                        onClick={() => handleZoomImg(res.imagem)}
                      >
                        <img
                          src={res.imagem}
                          alt={!res.Ambiente.nome ? '-' : res.Ambiente.nome}
                        />
                      </ButtonImg>
                    )}

                    <ul>
                      <li>
                        <strong>Ambiente: </strong>
                        <span>
                          {!res.Ambiente.nome ? '-' : res.Ambiente.nome}
                        </span>
                      </li>
                      <li>
                        <strong>Horário base (agendado): </strong>
                        <span>
                          {res.horaInicialAmbiente && res.horaFinalAmbiente
                            ? `${res.horaInicialAmbiente} - ${res.horaFinalAmbiente}`
                            : '-'}
                        </span>
                      </li>
                      <li>
                        <strong>Status: </strong>
                        <span>{res.status}</span>
                      </li>
                      <li>
                        <strong>Data e hora inicial (realizado): </strong>
                        <span>
                          {!res.createdAt
                            ? '-'
                            : moment(new Date(res.createdAt)).format(
                                'DD/MM/YYYY HH:mm:ss',
                              )}
                        </span>
                      </li>
                      <li>
                        <strong>Data e hora final (realizado): </strong>
                        <span>
                          {!res.finishAt
                            ? '-'
                            : moment(new Date(res.finishAt)).format(
                                'DD/MM/YYYY HH:mm:ss',
                              )}
                        </span>
                      </li>
                      <li>
                        <strong>Procedimento: </strong>
                        <span>{!res.nameTask ? '-' : res.nameTask}</span>
                      </li>
                      <li>
                        <strong>Observação: </strong>
                        <span>{res.observacao}</span>
                      </li>
                      <li>
                        <strong>Descrição: </strong>
                        <span>{res.description}</span>
                      </li>
                      <li>
                        <strong>Revisões (realizado): </strong>
                        <Link
                          to="#"
                          onClick={() => {
                            handleZoomImgRevisions(
                              responseDataDetalhes.filter(i => i.isRevision),
                            );
                          }}
                        >
                          Mostrar revisões
                        </Link>
                      </li>
                    </ul>
                  </ContainerMapDetails>
                ))}
            </ContainerListCards>
          </ContainerHeader>
        )}

        {zoomImg === 'principal' && (
          <ZoomImg>
            <Zoom img={img} zoomScale={1} width="100%" height={380} />
          </ZoomImg>
        )}
        {zoomImg === 'revisoes' && (
          <ZoomImg>
            {img &&
              typeof img === 'object' &&
              img.map(imgRevision => (
                <section key={imgRevision.imagem}>
                  <span style={{ fontWeight: 500 }}>
                    {imgRevision.nameTask}
                  </span>
                  <article style={{ marginTop: -30, marginBottom: -23 }}>
                    <span>
                      {imgRevision.horaInicialAmbiente} ás{' '}
                      {imgRevision.horaFinalAmbiente}
                    </span>
                    {/* {checkOutOfHours(
                      imgRevision.HORA_REVISAO_INICIAL,
                      moment(imgRevision.DATA_CRIACAO).format('HH:mm'),
                      imgRevision.HORA_REVISAO_FINAL,
                      moment(imgRevision.DATA_FINALIZACAO).format('HH:mm'),
                    ) && (
                      <IconeAtrasado>
                        <FiAlertTriangle style={{ cursor: 'default' }} />
                      </IconeAtrasado>
                    )} */}
                  </article>

                  <span>{`Observação: ${imgRevision.observacao}`}</span>

                  {imgRevision.imagem ? (
                    <Zoom
                      img={imgRevision.imagem}
                      zoomScale={1}
                      width="100%"
                      height={380}
                    />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <AiOutlineException size={80} />
                    </div>
                  )}
                </section>
              ))}

            {img && !img.length && <h1>Histórico de revisões vazio</h1>}
          </ZoomImg>
        )}

        {!exibirDetalhes && (
          <>
            <ContainerButtonAtualizar>
              <Button
                type="button"
                onClick={() => {
                  setSearch('');
                  setSearchDetails('');
                  setZoomImg('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  onRequestClose();
                }}
              >
                Ok
              </Button>
            </ContainerButtonAtualizar>

            <ContainerButtonsPdfExcel>
              <button
                type="button"
                id="pdf"
                onClick={() => handleDownloadPDF(responseData, 'dados')}
              >
                <VscFilePdf size={22} />
              </button>

              <ReactHTMLTableToExcel
                type="button"
                id="excel"
                table="tableListagemDadosColaborador"
                filename={`relatorio-colaborador-${moment().format(
                  'DD-MM-YYYY',
                )}`}
                sheet="tablexls"
                buttonText={<RiFileExcel2Line size={22} />}
              />
            </ContainerButtonsPdfExcel>
          </>
        )}
        {exibirDetalhes && zoomImg === '' && (
          <>
            <ContainerButtonDetalhes>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setSearch('');
                  setSearchDetails('');
                  setZoomImg('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  onRequestClose();
                }}
              >
                Ok
              </Button>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setExibirDetalhes(false);
                  setSearch('');
                  setSearchDetails('');
                  setZoomImg('');
                }}
              >
                Voltar
              </Button>
            </ContainerButtonDetalhes>

            <ContainerButtonsPdfExcel>
              <button
                type="button"
                id="pdf"
                onClick={() => {
                  handleDownloadPDF(responseDataDetalhes, 'detalhes');
                }}
              >
                <VscFilePdf size={22} />
              </button>

              <ReactHTMLTableToExcel
                type="button"
                id="excel"
                table="tableListagemDetalhesColaborador"
                filename={`relatorio-colaborador-${moment().format(
                  'DD-MM-YYYY',
                )}`}
                sheet="tablexls"
                buttonText={<RiFileExcel2Line size={22} />}
              />
            </ContainerButtonsPdfExcel>
          </>
        )}

        {zoomImg !== '' && (
          <>
            <ContainerButtonDetalhes>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setSearch('');
                  setSearchDetails('');
                  setZoomImg('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  onRequestClose();
                }}
              >
                Ok
              </Button>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setZoomImg('');
                }}
              >
                Voltar
              </Button>
            </ContainerButtonDetalhes>

            {zoomImg === 'revisoes' && (
              <ContainerButtonsPdfExcel>
                <ReactHTMLTableToExcel
                  type="button"
                  id="excel"
                  table="tableListagemDetalhesRevisoesColaborador"
                  filename={`relatorio-revisoes-colaborador-${moment().format(
                    'DD-MM-YYYY',
                  )}`}
                  sheet="tablexls"
                  buttonText={<RiFileExcel2Line size={22} />}
                />
              </ContainerButtonsPdfExcel>
            )}
          </>
        )}
      </ContainerModal>

      <ContainerTableMacon>
        <table id="tableListagemDadosColaborador">
          <HeaderTable headers={headers} />
          <tbody>
            {dadosHistoricoExcelPdf.map(res => (
              <tr key={uuid()}>
                <td>{!user.name ? '-' : user.name}</td>
                <td>{!user.cpf ? '-' : maskCpf(user.cpf)}</td>
                <td>{!user.email ? '-' : user.email}</td>
                <td>{!user.telefone ? '-' : user.telefone}</td>
                <td>{!res.Ambiente.nome ? '-' : res.Ambiente.nome}</td>
                <td>
                  {!res.horaInicialAmbiente && !res.horaFinalAmbiente
                    ? '-'
                    : `${res.horaInicialAmbiente} - ${res.horaFinalAmbiente}`}
                </td>
                <td>
                  {!res.createdAt
                    ? '-'
                    : moment(new Date(res.createdAt)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                </td>
                <td>
                  {!res.finishAt
                    ? '-'
                    : moment(new Date(res.finishAt)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                </td>
                <td>{res.observacao}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTableMacon>

      <ContainerTableMacon>
        <table id="tableListagemDetalhesColaborador">
          <HeaderTable headers={headersRevisions} />
          <tbody>
            {detalhesHistoricoExcelPdf.map(res => (
              <tr key={uuid()}>
                <td>{!user.name ? '-' : user.name}</td>
                <td>{!res.nameUser ? '-' : res.nameUser}</td>
                <td>{!user.cpf ? '-' : maskCpf(user.cpf)}</td>
                <td>{!user.email ? '-' : user.email}</td>
                <td>{!user.telefone ? '-' : user.telefone}</td>
                <td>{!res.Ambiente.nome ? '-' : res.Ambiente.nome}</td>
                <td>{!res.status ? '-' : res.status}</td>
                <td>
                  {!res.horaInicialAmbiente && !res.horaFinalAmbiente
                    ? '-'
                    : `${res.horaInicialAmbiente} - ${res.horaFinalAmbiente}`}
                </td>
                <td>
                  {res.revisions
                    .map(i => `${i.horaIni} - ${i.horaFim}`)
                    .join(', ')}
                </td>
                <td>
                  {!res.createdAt
                    ? '-'
                    : moment(new Date(res.createdAt)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                </td>
                <td>
                  {!res.finishAt
                    ? '-'
                    : moment(new Date(res.finishAt)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                </td>
                <td>{!res.nameTask ? '-' : res.nameTask}</td>
                <td>{res.observacao}</td>
                <td>{res.description}</td>
                <td>
                  {filterSchedulesReviewsDone(
                    res,
                    detalhesHistoricoExcelPdf.length,
                  )}
                </td>
                <td>
                  {!res.imagem || res.imagem.split('/').pop() === 'null' ? (
                    'Sem Foto'
                  ) : (
                    <a href={!res.imagem ? '-' : res.imagem}>Ir para Foto</a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTableMacon>

      <ContainerTableMacon>
        <table id="tableListagemDetalhesRevisoesColaborador">
          <HeaderTable headers={headersRevisions} />
          <tbody>
            {newDetalhesHistorico.map(res => (
              <tr key={uuid()}>
                <td>{!user.name ? '-' : user.name}</td>
                <td>{!res.nameUser ? '-' : res.nameUser}</td>
                <td>{!user.cpf ? '-' : maskCpf(user.cpf)}</td>
                <td>{!user.email ? '-' : user.email}</td>
                <td>{!user.telefone ? '-' : user.telefone}</td>
                <td>{!res.Ambiente.nome ? '-' : res.Ambiente.nome}</td>
                <td>{!res.status ? '-' : res.status}</td>
                <td>
                  {!res.horaInicialAmbiente && !res.horaFinalAmbiente
                    ? '-'
                    : `${res.horaInicialAmbiente} - ${res.horaFinalAmbiente}`}
                </td>
                <td>
                  {res.revisions
                    .map(i => `${i.horaIni} - ${i.horaFim}`)
                    .join(', ')}
                </td>
                <td>
                  {!res.createdAt
                    ? '-'
                    : moment(new Date(res.createdAt)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                </td>
                <td>
                  {!res.finishAt
                    ? '-'
                    : moment(new Date(res.finishAt)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                </td>
                <td>{!res.nameTask ? '-' : res.nameTask}</td>
                <td>{res.observacao}</td>
                <td>{res.description}</td>

                <td>
                  {filterSchedulesReviewsDone(res, newDetalhesHistorico.length)}
                </td>
                <td>
                  {!res.imagem || res.imagem.split('/').pop() === 'null' ? (
                    'Sem Foto'
                  ) : (
                    <a href={res.imagem}>Ir para Foto</a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTableMacon>
    </Modal>
  );
};
