import { shade } from 'polished';
import styled from 'styled-components';

interface ConteinerProp {
  option: 'ultimas' | 'proximas' | 'todas';
}

export const Conteiner = styled.div<ConteinerProp>`
  body {
    max-width: 100%;
  }

  button,
  input {
    border: 0;
  }

  button:focus,
  input:focus {
    border: 0;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  main {
    display: flex;
    flex-direction: column;
  }

  .titulo,
  .limp {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    font-weight: 500;
    justify-content: space-between;
  }

  .titulo {
    background: ${props => props.theme.colors.grayWhite};
    border-radius: 2rem;
  }

  .controleLimpeza {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }

  .inforFuncionario {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    padding: 3rem;
    border: solid 1px #828286;
    width: 35rem;
    height: 37rem;
    border-radius: 3rem;
    margin-top: 2rem;
  }

  .inforFuncionario:hover {
    box-shadow: 0px 0px 15px #bdbec2;
  }
  .nome,
  .horario,
  .data {
    font-family: 'Poppins', sans-serif;
    margin: 1rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: ${props => props.theme.colors.textBlack};
  }

  .nomeFuncionario {
    font-size: 1.8rem;
    font-family: 'Poppins', sans-serif;
    margin: 1rem;
    font-weight: 600;
  }

  .avatar {
    border-radius: 100%;
    border: solid;
    height: 12rem;
    border-color: ${props => props.theme.colors.grayWhite};
    width: 15rem;
    height: 15rem;
  }

  .controleHorario {
    display: flex;
    gap: 2rem;
  }

  #horAgendado {
    color: ${props => props.theme.colors.bluePrimary};
    align-items: center;
  }

  #horRealizado {
    display: block;
  }

  .datatime {
    border: none;
    background-color: ${props => props.theme.colors.grayWhite};
    border-radius: 1rem;
    font-size: 1.6rem;
    width: 10rem;
    padding: 1rem;
  }
  .horario,
  .datatime {
    color: ${props => shade(0.4, props.theme.colors.grayBlack)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
  }

  #timeAgen {
    background-color: ${props => props.theme.colors.bluePrimary};
    color: ${props => props.theme.colors.textBlack};
    align-items: center;
  }

  #ultimasLimpezas {
    display: block;
  }

  #btnultimasLimpezas {
    background-color: ${props =>
      props.option === 'ultimas'
        ? props.theme.colors.bluePrimary
        : props.theme.colors.grayWhite};
  }
  #btnproximasLimpezas {
    background-color: ${props =>
      props.option === 'proximas'
        ? props.theme.colors.bluePrimary
        : props.theme.colors.grayWhite};
  }
  #btnTodasLimpezas {
    background-color: ${props =>
      props.option === 'todas'
        ? props.theme.colors.bluePrimary
        : props.theme.colors.grayWhite};
  }
  #proximasLimpezas {
    display: block;
  }

  .btn {
    width: auto;
    height: 4rem;
    border-radius: 2rem;
    margin-top: 2rem;
    font-family: 'Poppins', sans-serif;
    font-size: 1.4rem;
    padding: 1rem;
    cursor: pointer;
    color: ${props => props.theme.colors.textBlack};
  }

  .btn:hover {
    box-shadow: 0px 0px 10px #6e6e70;
    border-color: #0a0a0a;
    font-weight: 500;
    cursor: pointer;
  }

  .conteinerBtn {
    margin: 0 auto;
    align-items: center;
    display: flex;
    gap: 2rem;
  }

  @media (max-width: 480px) {
    .conteinerBtn {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;
    }
    .btn {
      margin-top: 1rem;
      font-size: 1.2rem;
    }

    .controleLimpeza {
      display: grid;
      grid-template-columns: auto;
      margin: 0 auto;
    }
  }
`;
