import styled from 'styled-components';

interface PreviewProps {
  src?: string;
}

export const Container = styled.ul`
  margin-top: 20px;

  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 20px;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${props => props.theme.colors.grayBlack};

    div.divIcons {
      display: flex;
      align-items: center;

      a {
        display: flex;
      }
    }

    & + li {
      margin-top: 15px;
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    strong {
      color: ${props => props.theme.colors.textBlack};
      padding-right: 5px;
    }

    span {
      font-size: 12px;
      color: ${props => props.theme.colors.grayBlack};

      button {
        border: 0;
        background: transparent;
        color: ${props => props.theme.colors.redPrimary};
        margin-left: 10px;
        margin-top: 0px;
        cursor: pointer;
      }
    }
  }
`;

export const Preview = styled.div<PreviewProps>`
  margin-right: 10px;

  svg {
    margin-top: 5px;
  }
`;
