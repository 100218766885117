import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';

import {
  AiFillLike,
  AiFillDislike,
  AiOutlineQuestionCircle,
} from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { ThemeContext } from 'styled-components';
import Pagination from '../../../components/Pagination';

import { Container, ContainerCard, ContainerPagination } from './styles';

interface Mensage {
  id: number;
  title: string;
  description: string;
  linkGooglePlayStore?: JSX.Element;
}

const response: Mensage[] = [
  {
    id: 0,
    title: 'Como fazer cadastro do funcionário?',
    description:
      "Primeiramente deve ser feito o pré-cadastro do funcionário, adicionando seu CPF à lista de CPF's habilitados para o cadastro no aplicativo. " +
      'Nesse momento ele já vai ter acesso somente ao aplicativo como ASG, caso queira mudar seu acesso isso pode ser feito no menu Listagem.',
  },
  {
    id: 1,
    title: 'Como fazer cadastro de coordenador?',
    description:
      "Primeiramente deve ser feito o pré-cadastro do funcionário, adicionando seu CPF à lista de CPF's habilitados para o cadastro no aplicativo. " +
      'Nesse momento ele já vai ter acesso somente ao aplicativo como ASG, para mudar seu acesso para Coordenador isso deve ser feito no menu Listagem, clicando no botão com um ícone de cadeado.',
  },
  {
    id: 2,
    title: 'Qual o passo a passo para começar a usar o TAPP?',
    description:
      `O primeiro passo é já ter seu CPF pré-cadastrado no sistema, realizado isso basta baixar o aplicativo na LINK ou acessar a plataforma web e ` +
      'fazer o seu cadastro de acesso pessoal. Pronto! Agora é só logar e começar a usar (caso seja ASG via mobile, caso seja Coordenador via mobile ou web).',
    linkGooglePlayStore: (
      <a
        href="https://play.google.com/store/apps/details?id=com.gestaoqr"
        target="_blank"
        rel="noopener noreferrer"
      >
        Google Play Store
      </a>
    ),
  },
  {
    id: 3,
    title: 'Por onde começo os cadastros?',
    description:
      'Caso seja um usuário administrador, você deve inicialmente cadastrar sua(s) empresa(s) no menu Filiais, e posteriormente pré-cadastrar os usuários Coordenadores. ' +
      "Caso seja Coordenador, você deve primeiramente pré-cadastrar os usuários ASG's e posteriormente cadastrar os procedimentos, riscos, setores e categorias em seus " +
      "menus específicos, para que seja liberado o cadastro dos ambientes (onde é gerado o QR Code). Finalizando todos esses cadastros fundamentais, já pode ser feito o cadastro das periodicidades e dos agendamentos das limpezas para os ASG's.",
  },
  {
    id: 4,
    title: 'Qual o tipo de arquivo necessario para importação de dados?',
    description:
      'O tipo de arquivo deve ser único e exclusivamente no formato .CSV separado por vírgulas.',
  },
  {
    id: 5,
    title: 'Onde encontro o modelo da planilha para importação?',
    description:
      'O arquivo modelo é disponibilizado nas páginas de cadastro em lote.',
  },
  {
    id: 6,
    title:
      'Quais as colunas que devem ser preenchidas para importação de dados?',
    description:
      'Todas as colunas que estiverem com título no arquivo modelo devem ser preenchidas corretamente.',
  },
  {
    id: 7,
    title: 'O que fazer após importar os dados de cadastro?',
    description:
      'Após importar os dados, se no processo não ocorrer nenhum problema isso quer dizer que todos os registros contidos no arquivo foram cadastrados corretamente e ' +
      'estão prontos para utilização no sistema.',
  },
  {
    id: 8,
    title: 'Qual as diferenças entre setor, categoria e ambiente?',
    description: `O setor é referente a uma divisão ou subdivisão da empresa ou de um estabelecimento qualquer (Ex.: Administrativo).
      A categoria é referente a um elemento utilizado principalmente para classificar e facilitar a ordem dos grupos de locais que apresentam similaridades (Ex.: Sala).
      E o ambiente é referente ao conjunto dos pontos anteriores juntamente com uma classificação de procedimentos e riscos, apresentando um maior detalhamento do local (Ex.: Sala secreta do presidente).`,
  },
];

export const FAQ: React.FC = () => {
  const { colors } = useContext(ThemeContext);
  const [statusButtonCard, setStatusButtonCard] = useState<boolean[]>([]);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE] = useState(20);

  const responseData = useMemo(() => {
    let computedResponses: Mensage[] = [];
    computedResponses = response;

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [ITEMS_PER_PAGE, currentPage]);

  useEffect(() => {
    const statusTemp: boolean[] = [];
    responseData.forEach(() => {
      statusTemp.push(true);
    });

    setStatusButtonCard([...statusTemp]);
  }, [responseData]);

  const descriptionFormat1 = useCallback((dataCard: Mensage) => {
    const cardSplit = dataCard.description.split('LINK');

    return cardSplit[0];
  }, []);

  const descriptionLink = useCallback((dataCard: Mensage) => {
    return dataCard.linkGooglePlayStore;
  }, []);

  const descriptionFormat2 = useCallback((dataCard: Mensage) => {
    const cardSplit = dataCard.description.split('LINK');

    return cardSplit[1];
  }, []);

  return (
    <Container>
      <header>
        <h1>FAQ</h1>

        <Tooltip
          title="Nessa página você pode tirar suas dúvidas com várias perguntas e respostas sobre a utilização geral do sistema web do TAPP."
          arrow
          TransitionComponent={Zoom}
        >
          <span>
            <AiOutlineQuestionCircle />
          </span>
        </Tooltip>
      </header>

      <section>
        {responseData.map((card, index) => (
          <ContainerCard key={card.id}>
            <h2>{card.title}</h2>

            {card.linkGooglePlayStore ? (
              <p>
                {descriptionFormat1(card)}
                {descriptionLink(card)}
                {descriptionFormat2(card)}
              </p>
            ) : (
              <p>{card.description}</p>
            )}

            <footer>
              Este FAQ foi útil?
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonCard];

                  statusTemp[index] = true;

                  setStatusButtonCard([...statusTemp]);
                }}
              >
                <AiFillLike
                  color={statusButtonCard[index] ? colors.greenPrimary : ''}
                />
              </button>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonCard];

                  statusTemp[index] = false;

                  setStatusButtonCard([...statusTemp]);
                }}
              >
                <AiFillDislike
                  color={!statusButtonCard[index] ? colors.redPrimary : ''}
                />
              </button>
            </footer>
          </ContainerCard>
        ))}
      </section>

      <ContainerPagination>
        {response.length > 0 && (
          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        )}
      </ContainerPagination>
    </Container>
  );
};
