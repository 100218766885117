import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  padding: 25px;
  overflow: hidden;
  z-index: 3;

  @media screen and (max-width: 1200px) {
    right: 30px;
    top: 30px;
    padding: 0;
  }
`;
