import React from 'react';
import { FiUser } from 'react-icons/fi';
import { TbTimeDurationOff } from 'react-icons/tb';
import { WiTime4 } from 'react-icons/wi';

import * as S from './styles';
import { Agendamentos } from '../../../../../models/Agendamentos';
import { capitalizeFirstLetterOfWords } from '../../../../../utils';

interface NewCardAgendaProps {
  schedule: Agendamentos & { isRevision?: boolean };
  onPress(): void;
  colorStatus?: string;
}

export const NewCardAgenda = ({
  schedule,
  onPress,
  colorStatus = '#dcdcdc',
}: NewCardAgendaProps) => {
  return (
    <S.Wrapper onClick={onPress}>
      <S.StatusLine style={{ backgroundColor: colorStatus }} />
      <S.ContainerContent>
        <S.ScheduleNumber>{schedule.Agenda.ID}</S.ScheduleNumber>
        <S.EnvironmentName>
          {capitalizeFirstLetterOfWords(schedule.Agenda.Environment.name)}
        </S.EnvironmentName>
        <S.ContributorName>
          <S.ContainerFiUser>
            <FiUser />
          </S.ContainerFiUser>
          {capitalizeFirstLetterOfWords(schedule.User.nome)}
        </S.ContributorName>
        <S.FooterCard>
          <S.SectorName>
            {capitalizeFirstLetterOfWords(
              schedule.Agenda.Environment.Setor.name,
            )}
          </S.SectorName>
          <S.Hours className="first-child">
            <WiTime4 /> {schedule.horaIni}
          </S.Hours>
          <S.Hours>
            <TbTimeDurationOff className="time-duration-off" />{' '}
            {schedule.horaFim}
          </S.Hours>
        </S.FooterCard>
      </S.ContainerContent>
    </S.Wrapper>
  );
};
