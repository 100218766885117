import styled, { css } from 'styled-components';
import { shade } from 'polished';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: ${props => props.theme.colors.whitePrimary};
  border: 1px solid ${props => props.theme.colors.grayWhite};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 16px;

  display: flex;
  align-items: center;

  & + p {
    margin-top: 18px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: ${props.theme.colors.redPrimary};
    `}

  ${props =>
    props.isFocused &&
    css`
      border: 1px solid ${props.theme.colors.bluePrimary};
    `}

  input {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    border: none;

    flex: 1;
    display: flex;
    align-items: center;

    color: ${props => props.theme.colors.textBlack};

    &::placeholder {
      color: ${props => props.theme.colors.grayBlack};
    }

    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px ${props => props.theme.colors.whitePrimary}
      inset;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: ${props =>
      props.theme.colors.grayBlack} !important;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    background-color: transparent;
    border: none;

    #iconEye {
      margin-right: 1px;
      color: ${props => props.theme.colors.grayBlack};

      transition: color 0.2s;

      &:hover {
        color: ${props => shade(0.2, props.theme.colors.grayBlack)};
      }
    }
  }
`;

export const Error = styled(Tooltip)`
  width: 20px;
  height: 20px;
  margin-left: 16px;
  color: ${props => props.theme.colors.redPrimary};

  display: flex;
  align-items: center;
  justify-content: center;
`;
