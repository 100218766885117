import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';

interface DrawerState {
  isActive: boolean;
  isActiveAux: boolean;
}

interface DrawerContextData {
  isActive: boolean;
  isActiveAux: boolean;
  updateDrawer(state: boolean): void;
}

const DrawerContext = createContext<DrawerContextData>({} as DrawerContextData);

const DrawerProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<DrawerState>(() => {
    const drawer = localStorage.getItem('@Tapp:drawer');

    if (drawer) {
      return {
        isActive: JSON.parse(drawer),
        isActiveAux: false,
      };
    }

    return {} as DrawerState;
  });

  useEffect(() => {
    if (window.innerWidth > 1152) {
      setData({
        isActive: true,
        isActiveAux: true,
      });
    } else {
      setData({
        isActive: false,
        isActiveAux: false,
      });
    }
  }, []);

  const updateDrawer = useCallback((isActive: boolean) => {
    localStorage.setItem('@Tapp:drawer', JSON.stringify(isActive));

    setData({
      isActive,
    } as DrawerState);
  }, []);

  return (
    <DrawerContext.Provider
      value={{
        isActive: data.isActive,
        isActiveAux: data.isActiveAux,
        updateDrawer,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

function useDrawer(): DrawerContextData {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error('useAuth must be used within an DrawerProvider');
  }

  return context;
}

export { DrawerProvider, useDrawer };
