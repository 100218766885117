import styled from 'styled-components';

export const Container = styled.div`
  height: 90px;
  left: 0px;
  top: 0px;
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    z-index: 999;
    left: 20px;
    background: transparent;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 30px;
      height: 30px;
      color: ${props => props.theme.colors.textBlack};
    }
  }

  #btnClose {
    position: fixed;
  }

  #btnMenu {
    position: absolute;
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;

  > strong {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.colors.textBlack};
    margin-right: 20px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;
