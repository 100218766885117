import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { ThemeContext } from 'styled-components';
import { FiEdit, FiPlus } from 'react-icons/fi';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { UserGet } from '../../../models/User';
import { Filial } from '../../../models/Filial';
import Search from '../../../components/Search';
import ModalConexao from '../../../components/ModalConexao';
import Pagination from '../../../components/Pagination';
import HeaderTable from '../../../components/HeaderTable';
import { Loading } from '../../../components/Loading';
import { maskCpf, maskTel } from '../../../components/InputOverview/mask';
import { ModalEditUser } from '../../../components/ModalEditUser';
import { Cadastro } from './cadastro';
import { ModalAddNameUser } from '../../../components/ModalAddNameUser';

import {
  Container,
  ContainerPagination,
  ContainerTable,
  ContainerSemResultados,
  Aba,
  ContainerAba,
  DivName,
} from './styles';

const headers = [
  { name: 'Empresa', field: 'companyId', sortable: true },
  { name: 'Nome P', field: 'nome', sortable: true },
  { name: 'CPF', field: 'cpf', sortable: true },
  { name: 'E-mail', field: 'email', sortable: true },
  { name: 'Telefone', field: 'telefone', sortable: true },
  { name: 'Data de Nascimento', field: 'dataNascimento', sortable: true },
  { name: 'Nível', field: 'status', sortable: true },
  { name: 'Editar', field: 'edit', sortable: true },
];

const headersCoordenador = [
  { name: 'Empresa', field: 'companyId', sortable: true },
  { name: 'Nome P', field: 'nome', sortable: true },
  { name: 'CPF', field: 'cpf', sortable: true },
  { name: 'E-mail', field: 'email', sortable: true },
  { name: 'Telefone', field: 'telefone', sortable: true },
  { name: 'Data de Nascimento', field: 'dataNascimento', sortable: true },
  { name: 'Editar', field: 'edit', sortable: true },
];

export const Usuarios: React.FC = () => {
  const { user, empresaPrincipal, cpfUserMaster } = useAuth();
  const { addToast } = useToast();
  const { colors } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [loadingEmp, setLoadingEmp] = useState(false);
  const [response, setResponse] = useState<UserGet[]>([]);
  const [empresas, setEmpresas] = useState<Filial[]>([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [userEdit, setUserEdit] = useState<UserGet | null>(null);
  const [isCadastro, setIsCadastro] = useState(false);
  const [showModalName, setShowModalName] = useState(false);
  const [userAddName, setUserAddName] = useState<UserGet | null>(null);

  const [search, setSearch] = useState('');

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE] = useState(20);
  const [sorting, setSorting] = useState({ field: '', order: '' });

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  const getEmpresas = useCallback(() => {
    const companyId = empresaPrincipal.length > 0 ? empresaPrincipal[0].ID : '';
    setLoadingEmp(true);
    api
      .get(`empresas/filiais?companyId=${companyId}`)
      .then(resp => {
        setLoadingEmp(false);
        const emp: Filial[] = [];
        resp.data.forEach((filial: Filial) => {
          if (filial.ATIVO === true) {
            emp.push(filial);
          }
        });
        setEmpresas(emp);
      })
      .catch(err => {
        setLoadingEmp(false);
        console.log(err.message);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, por favor atualize a página',
        });
      });
  }, [addToast, empresaPrincipal]);

  const getUsers = useCallback(() => {
    const companyId = empresaPrincipal.length > 0 ? empresaPrincipal[0].ID : '';
    setLoading(true);
    api
      .get(`/allUsers/${companyId}`)
      .then(resp => {
        setLoading(false);
        const users: UserGet[] = resp.data;
        if (isCoordenador) {
          setResponse(users.filter(i => user.companyId === i.companyId));
        } else {
          setResponse(users);
        }
      })
      .catch(err => {
        setLoading(false);
        console.log(err.message);

        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, por favor atualize a página',
        });
      });
  }, [addToast, empresaPrincipal, isCoordenador, user.companyId]);

  useEffect(() => {
    getEmpresas();
    getUsers();
  }, [getEmpresas, getUsers]);

  const handleCheckCompany = useCallback(
    (companyId: number): string => {
      let companyName = '';
      empresas.forEach(empresa => {
        if (empresa.ID === companyId) companyName = empresa.NOME;
      });
      return companyName;
    },
    [empresas],
  );

  const responseData = useMemo(() => {
    let computedResponses: UserGet[] = [];
    computedResponses = response.length > 0 ? response : [];

    if (search) {
      computedResponses = computedResponses.filter((res: UserGet) =>
        [res.cpf, res.nome, res.email, res.companyId].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        ),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (typeof a[sorting.field] === 'object' && a[sorting.field] != null) {
          return (
            reversed *
            a[sorting.field]
              .join(', ')
              .localeCompare(b[sorting.field].join(', '))
          );
        }
        if (typeof a[sorting.field] === 'string') {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        }

        const aTemp = a[sorting.field] != null ? a[sorting.field] : '';
        const bTemp = b[sorting.field] != null ? b[sorting.field] : '';
        return reversed * aTemp.toString().localeCompare(bTemp.toString());
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    search,
    sorting.field,
    sorting.order,
    ITEMS_PER_PAGE,
    currentPage,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  const handleEditUser = useCallback((userGet: UserGet) => {
    setUserEdit(userGet);
    setShowModalEdit(true);
  }, []);

  const getNivel = useCallback(
    (nivel: number | null, isCoord: boolean): string => {
      switch (nivel) {
        case 1:
          return 'Administrador (P)';
        case 2:
          return 'Administrador';
        case 3:
          return 'Coordenador';
        case 4:
          return 'Auditor';
        case 5:
          return 'ASG';
        default:
          return isCoord ? 'Coordenador Antigo' : 'ASG Antigo';
      }
    },
    [],
  );

  const disableNivel = useCallback(
    (id: 1 | 2 | 3 | 4 | 5 | null): boolean => {
      if (user.roleId === null || user.roleId === 3) {
        return id === 1 || id === 2 || id === 4;
      }
      if (user.roleId === 2) {
        return id === 1;
      }
      return user.roleId !== 1;
    },
    [user.roleId],
  );

  const isLoading = useMemo(() => {
    return loadingEmp || loading;
  }, [loading, loadingEmp]);

  return (
    <>
      <ModalConexao />

      <Container>
        <Aba>
          <ContainerAba className="aba" cor={!isCadastro}>
            <button type="button" onClick={() => setIsCadastro(false)}>
              Listar usuários
            </button>
          </ContainerAba>
          <ContainerAba className="aba1" cor={isCadastro}>
            <button type="button" onClick={() => setIsCadastro(true)}>
              Cadastrar usuário
            </button>
          </ContainerAba>

          <Tooltip
            title="Nessa página você tem acesso a visualização, cadastro e edição de todos os usuário que fazem parte do seu grupo empresarial"
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </Aba>

        {isCadastro ? (
          <Cadastro empresas={empresas} />
        ) : (
          <>
            <Search
              onSearch={(value: string) => {
                setSearch(value);
              }}
              nomePlaceHolder="Buscar"
            />

            {!response.length && (
              <ContainerSemResultados>
                <h2>Não existem usuários cadastrados.</h2>
              </ContainerSemResultados>
            )}

            {!!response.length && (
              <>
                <ContainerTable>
                  <table id="tableListagemUsuarios">
                    <HeaderTable
                      headers={
                        cpfUserMaster.length ? headers : headersCoordenador
                      }
                      onSorting={(field: string, order: string) => {
                        setSorting({ field, order });
                      }}
                    />
                    <tbody>
                      {responseData.map(res => (
                        <tr key={`${res.id}-${res.cpf}-${res.createdAt}`}>
                          <td>{handleCheckCompany(res.companyId)}</td>
                          <td>
                            <DivName>
                              {res.nome}
                              <button
                                type="button"
                                onClick={() => {
                                  setUserAddName(res);
                                  setShowModalName(true);
                                }}
                              >
                                <FiPlus />
                              </button>
                            </DivName>
                          </td>
                          <td>{res.cpf ? maskCpf(res.cpf) : '-'}</td>
                          <td>{res.email}</td>
                          <td>{res.telefone ? maskTel(res.telefone) : '-'}</td>
                          <td>{res.dataNascimento}</td>
                          <td>{getNivel(res.roleId, res.coordenador === 1)}</td>
                          <td>
                            <button
                              type="button"
                              disabled={disableNivel(res.roleId)}
                              onClick={() => {
                                handleEditUser(res);
                              }}
                            >
                              {disableNivel(res.roleId) ? (
                                <FiEdit size={20} color={colors.grayBlack} />
                              ) : (
                                <FiEdit size={20} color={colors.bluePrimary} />
                              )}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ContainerTable>

                <ContainerPagination>
                  {response.length > 0 && (
                    <>
                      <Pagination
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={(page: number) => setCurrentPage(page)}
                      />

                      <ReactHTMLTableToExcel
                        id="export-excel"
                        className="btn"
                        table="tableListagemUsuarios"
                        filename={`listagem-usuarios-${moment().format(
                          'DD-MM-YYYY',
                        )}`}
                        sheet="tablexls"
                        buttonText="Exportar Excel"
                      />
                    </>
                  )}
                </ContainerPagination>
              </>
            )}
          </>
        )}
      </Container>

      {userEdit && (
        <ModalEditUser
          isOpen={showModalEdit}
          onRequestClose={() => setShowModalEdit(false)}
          userEdit={userEdit}
          filiais={empresas}
        />
      )}
      {userAddName && (
        <ModalAddNameUser
          isOpen={showModalName}
          userId={userAddName.id}
          onClose={() => setShowModalName(false)}
        />
      )}

      {isLoading && <Loading />}
    </>
  );
};
