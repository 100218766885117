import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  padding: 30px 40px;
  animation: ${appearFromLeft} 1s;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 24px;
    color: ${props => props.theme.colors.textBlack};
  }
`;

export const Content = styled.div`
  padding: 32px 50px;
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  max-height: calc(100vh - 207px);
  overflow: auto;

  display: flex;
  flex-direction: column;

  > section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 36px;

    > img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      object-fit: cover;
    }

    article {
      flex: 1;
      background: ${props => props.theme.colors.grayWhite};
      border-radius: 8px;
      padding: 20px;
      margin-left: 28px;

      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 992px) {
        margin: 0;
      }

      transition: background 0.2s;

      &:hover {
        background: ${props => shade(0.1, props.theme.colors.grayWhite)};
      }
    }

    @media screen and (max-width: 992px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
  }

  form {
    section {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
      gap: 30px;

      @media screen and (max-width: 992px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      article {
        p {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 12px;
          margin-bottom: 10px;
          color: ${props => props.theme.colors.textBlack};
        }

        @media screen and (max-width: 992px) {
          width: 100%;
        }
      }

      & + section {
        margin-top: 50px;
      }
    }

    aside {
      margin-top: 30px;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media screen and (max-width: 992px) {
        justify-content: center;
      }
    }
  }
`;
