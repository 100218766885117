import styled from 'styled-components';

interface ContainerProps {
  typeProps: 'drawer' | 'header';
}

export const Container = styled.div<ContainerProps>`
  ul {
    position: absolute;
    top: ${props => (props.typeProps === 'drawer' ? '' : '70px')};
    right: ${props => (props.typeProps === 'drawer' ? '' : '10px')};
    margin: ${props => (props.typeProps === 'drawer' ? '-35px 0 0 40px' : '')};
    padding: 22px 15px;
    background: ${props => props.theme.colors.whitePrimary};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    visibility: hidden;
    opacity: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    transition: 0.3s;

    li {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      list-style-type: none;

      a {
        color: ${props => props.theme.colors.grayBlack};
        transition: color 0.2s;

        justify-content: flex-start;

        &:hover {
          color: ${props => props.theme.colors.bluePrimary};
        }
      }

      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        max-width: 300px;
        color: ${props => props.theme.colors.textBlack};

        strong {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: ${props => props.theme.colors.textBlack};
        }
        margin: 0;
      }

      & + li {
        margin-top: 14px;
      }
    }

    .linkProfile {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: ${props => props.theme.colors.textBlack};
    }

    @media screen and (max-width: 1400px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;

      li {
        margin-top: 0 !important;
      }
    }

    @media screen and (max-width: 1152px) {
      display: none;
    }
  }

  &:hover ul {
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }
`;
