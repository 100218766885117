export default {
  title: 'colors',

  colors: {
    bluePrimary: '#00884A',
    // bluePrimary: '#6591CE',
    greenPrimary: '#219653',
    redPrimary: '#EB5757',
    whitePrimary: '#FFFFFF',
    backgroundWhite: '#FCFCFC',
    grayWhite: '#EAEAEA',
    grayBlack: '#B0B0B0',
    orangePrimary: '#ff8000',
    textBlack: '#363636',
  },

  fonts: {
    text600: 'Poppins-SemiBold',
    text500: 'Poppins-Medium',
    text400: 'Poppins-Regular',
  },
};
