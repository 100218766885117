import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 720px;
  height: 100vh;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1152px) {
    max-width: 100%;
    align-items: flex-start;
  }
`;

export const AnimationContainer = styled.div`
  width: 100%;
  padding: 0;
  animation: ${appearFromLeft} 1s;

  @media screen and (max-width: 1152px) {
    padding: 30px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    width: 388px;

    @media screen and (max-width: 1152px) {
      width: 90%;
    }

    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 40px;

      color: ${props => props.theme.colors.bluePrimary};
    }

    p {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 16px;
      margin-bottom: 8px;

      display: flex;
      align-items: center;

      color: ${props => props.theme.colors.textBlack};
    }

    article {
      margin-top: 24px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      a {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 16px;
        margin-top: 30px;

        display: flex;
        align-items: center;

        color: ${props => props.theme.colors.grayBlack};

        &:hover {
          color: ${props => shade(0.2, props.theme.colors.grayBlack)};
        }
      }

      hr {
        margin-top: 26px;
        border: 0.1px solid ${props => props.theme.colors.grayBlack};
        width: 100%;
      }
    }
  }

  > a {
    border: 1px solid ${props => props.theme.colors.bluePrimary};
    box-sizing: border-box;
    border-radius: 20px;
    background: transparent;
    padding: 15px 16px;
    width: 345px;
    margin-top: 20px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: ${props => props.theme.colors.bluePrimary};

    transition: color 0.2s;

    &:hover {
      color: ${props => shade(0.2, props.theme.colors.bluePrimary)};
    }

    @media screen and (max-width: 1152px) {
      width: 90%;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: ${props => props.theme.colors.bluePrimary};
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    position: absolute;
    width: 400px;
    height: 400px;
    object-fit: contain;
  }

  @media screen and (max-width: 1152px) {
    display: none;
  }
`;
