import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (max-width: 700px) {
    width: 90vw;
  }
  display: flex;
  flex-direction: column;
  width: 600px;
  max-height: 70vh;
  overflow: auto;
  padding-right: 26px;
  padding-left: 26px;

  header {
    padding-bottom: 21px;
    margin-top: 0.4rem;
    border-bottom: 0.8px solid ${props => props.theme.colors.grayBlack};
    margin-bottom: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 16px;
      color: ${props => props.theme.colors.textBlack};
    }
  }
`;

export const ContainerTable = styled.div`
  margin-top: 10px;
  padding: 0 2px 15px 2px;
  max-height: calc(100vh - 401px);
  overflow: auto;

  @media screen and (max-width: 1152px) {
    max-width: 100vw;
    max-height: calc(100vh - 384px);
  }

  table {
    width: 100%;
    background: ${props => props.theme.colors.whitePrimary};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    border-collapse: collapse;

    thead {
      th {
        padding: 10px 10px;
        text-align: left;
        white-space: nowrap;
      }

      th:nth-last-child(-n + 2) {
        text-align: center;
      }
    }

    tbody {
      tr {
        border-radius: 20px !important;

        td {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: ${props => props.theme.colors.textBlack};
          padding: 20px 30px;
          white-space: nowrap;

          button {
            border: none;
            background: transparent;
            margin: 0 auto;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &:nth-child(odd) {
          background-color: ${props => props.theme.colors.grayWhite};
        }
      }
    }
  }
`;

export const ContainerSemResultados = styled.div`
  background: ${props => props.theme.colors.grayWhite};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: ${props => props.theme.colors.textBlack};
  }
`;
