import styled from 'styled-components';

interface ContainerProps {
  totalPages: number;
}

export const ContainerItem = styled.div<ContainerProps>`
  border: 1px solid ${props => props.theme.colors.grayWhite};
  box-sizing: border-box;

  ul {
    list-style-type: none;

    display: flex;
    align-items: center;
    justify-content: center;

    .active {
      background: ${props => props.theme.colors.grayWhite};
      padding: 10px;
    }

    li {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.005em;
      color: ${props => props.theme.colors.textBlack};

      &:active {
        background: ${props => props.theme.colors.grayWhite};
      }

      /**ocultar link e mudar a cor*/
      a {
        padding: 10px;
        color: ${props => props.theme.colors.textBlack};

        /** mudar cor na hora do click */
        &:active {
          color: ${props => props.theme.colors.textBlack};
        }
      }

      /** span class= sr-only -- para ocultar o texto -> current */
      .sr-only {
        overflow: hidden;
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        clip: rect(0, 0, 0, 0);
        border: 0;
        color: ${props => props.theme.colors.whitePrimary};
      }
    }
  }
`;
