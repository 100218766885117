import styled, { keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  display: flex;

  @media screen and (max-width: 1152px) {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }
`;

export const Navigator = styled.div`
  height: 100vh;
  padding: 20px;
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  > a {
    img {
      width: 220px;
      height: 220px;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 1152px) {
    height: auto;
    padding: 40px 26px;

    > a {
      margin: 0 auto;

      img {
        width: 160px;
        height: 160px;
        object-fit: contain;
      }
    }
  }
`;

export const List = styled.ul`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  list-style-type: none;
  width: 100%;

  li {
    color: ${props => props.theme.colors.grayBlack};

    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      text-decoration: none;
      color: ${props => props.theme.colors.grayBlack};

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 10px;

        path {
          fill: ${props => props.theme.colors.grayBlack} !important;
        }
      }

      transition: color 0.2s;

      &:hover {
        color: ${props => props.theme.colors.bluePrimary};

        svg {
          path {
            transition: fill 0.2s;

            fill: ${props => props.theme.colors.bluePrimary} !important;
          }
        }
      }
    }

    & + li {
      margin-top: 30px;
    }

    @media screen and (max-width: 1400px) {
      & + li {
        margin-top: 15px;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

interface ButtonProps {
  isHover: boolean;
}

export const LiList = styled.li<ButtonProps>`
  color: ${props =>
    props.isHover
      ? props.theme.colors.bluePrimary
      : props.theme.colors.grayBlack} !important;

  @media screen and (max-width: 1152px) {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
  }

  transition: color 0.2s;

  span {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    article {
      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        margin-right: 10px;

        > path {
          transition: fill 0.2s;

          fill: ${props =>
            props.isHover
              ? props.theme.colors.bluePrimary
              : props.theme.colors.grayBlack} !important;
        }
      }
    }

    > div {
      margin-left: 12px;
    }
  }

  @media screen and (max-width: 1152px) {
    &:hover {
      ul {
        visibility: visible;
        position: relative;
        margin-top: 5px;
        padding: 10px 15px;
        max-height: 90px;
        overflow: auto;

        animation: ${appearFromLeft} 1s;
      }
    }
  }
`;

export const ListMobile = styled.ul`
  visibility: hidden;
  position: absolute;

  li {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    list-style-type: none;
  }
`;

export const ButtonIconArrow = styled.button<ButtonProps>`
  border: none;
  background: ${props => props.theme.colors.whitePrimary};

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${props =>
      props.isHover
        ? props.theme.colors.bluePrimary
        : props.theme.colors.grayBlack};
    width: 28px;
    height: 28px;

    transition: color 0.2s;
  }

  &:hover {
    svg {
      color: ${props => props.theme.colors.bluePrimary};
    }
  }
`;

export const ButtonSignOut = styled.button`
  border: none;
  background: transparent;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: ${props => props.theme.colors.grayBlack};

  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    margin-right: 10px;

    path {
      fill: ${props => props.theme.colors.grayBlack} !important;
    }
  }

  transition: color 0.2s;

  &:hover {
    color: ${props => props.theme.colors.bluePrimary};

    svg {
      path {
        transition: fill 0.2s;

        fill: ${props => props.theme.colors.bluePrimary} !important;
      }
    }
  }
`;
