export interface Setor {
  id: number;
  name: string;
  companyId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export const EmptySetor: Setor = {
  companyId: -1,
  createdAt: '',
  id: -1,
  name: '',
  updatedAt: '',
  deletedAt: null,
};
