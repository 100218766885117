import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import moment from 'moment';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import { Calendario } from '../../../components/Calendario';
import IconAddBlue from '../../../assets/IconAddBlue.svg';
import Search from '../../../components/Search';
import ModalConexao from '../../../components/ModalConexao';

import { Container, ContainerTitle } from './styles';
import { Agendamentos } from '../../../models/Agendamentos';
import { UserGet } from '../../../models/User';
import { Filial } from '../../../models/Filial';
import { formatDate, getStartAndEndOfMonth } from '../../../utils';

export interface ResponseGetEmpresas {
  ATIVO: boolean;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  ID: number;
  Id_Empresas: number;
  NOME: string;
  NUMERO: string;
}

export interface ResponseGetUsuario {
  ativo: number;
  avatar: string;
  avatar_url: string;
  companyId: number;
  coordenador: number;
  cpf: string;
  createdAt: string;
  dataNascimento: string;
  email: string;
  id: number;
  nome: string;
  password: string;
  telefone: string;
  tipo: string;
  updatedAt: string;
}

export interface ResponseGetAmbiente {
  AMBIENTE_NOME: string;
  CATEGORIA: string;
  DESC_LIMPEZA: string;
  companyId: number;
  EMPRESA: string;
  ID: number;
  PROCEDIMENTOS: [];
  RISCOS: [];
  SETOR: string;
  ANDAR: string;
}

interface ResponseRevisao {
  idTableRevisao: number[];
  horaInicial: string[];
  horaFinal: string[];
}

export interface ResponseGetPeriodicidade {
  ID: number;
  NOME: string;
  DIAS_SEMANA: string;
  QTD_DIAS: number;
  HORA_BASE_INICIAL: string;
  HORA_BASE_FINAL: string;
  Id_EmpresasFilial: number;
  revisaoHoras: ResponseRevisao;
}

export const DashboardAgendaV3: React.FC = () => {
  const { empresaPrincipal, user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [loadingAgenda, setLoadingAgenda] = useState(false);
  const [responseAgendamentos, setResponseAgendamentos] = useState<
    Agendamentos[]
  >([]);
  const [rangeDateGetSchedule, setRangeDateGetSchedule] = useState<{
    firstDayOfMonth: Date;
    lastDayOfMonth: Date;
  }>(getStartAndEndOfMonth(new Date()));
  const [search, setSearch] = useState('');

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  const getItens = useCallback(async () => {
    setLoading(true);

    try {
      const companyId =
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';

      const dataUsers = await api.get(`/allUsers/${companyId}`);

      const usersAux: UserGet[] = [];
      dataUsers.data.forEach((us: UserGet) => {
        us.coordenador === 0 && usersAux.push(us);
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao carregar dados, favor recarregue a página.',
      });
    }
  }, [addToast, empresaPrincipal]);

  const getAgendaData = useCallback(
    async (dateIni: string, dateEnd: string) => {
      setLoadingAgenda(true);

      try {
        const companyId =
          empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
        const filialId = user.companyId;
        const aux = isCoordenador
          ? `filialId=${filialId}`
          : `companyId=${companyId}`;

        const dataAgenda = await api.get(
          `agendas/v2/Agendamentos/company?dataIni=${dateIni}&dataFim=${dateEnd}&${aux}`,
        );

        setResponseAgendamentos(dataAgenda.data);

        setLoadingAgenda(false);
      } catch (err) {
        setLoadingAgenda(false);

        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, favor recarregue a página.',
        });
      }
    },
    [addToast, empresaPrincipal, isCoordenador, user.companyId],
  );

  useEffect(() => {
    getItens();
  }, [getItens]);

  useEffect(() => {
    if (
      rangeDateGetSchedule?.firstDayOfMonth &&
      rangeDateGetSchedule?.lastDayOfMonth
    ) {
      const initDate = formatDate(
        rangeDateGetSchedule.firstDayOfMonth,
        'yyyy-MM-dd',
      );
      const endDate = formatDate(
        rangeDateGetSchedule.lastDayOfMonth,
        'yyyy-MM-dd',
      );

      getAgendaData(initDate, endDate);
    }
  }, [rangeDateGetSchedule]);

  return (
    <>
      <ModalConexao />

      <Container>
        <ContainerTitle>
          <h1>Agenda</h1>

          <Tooltip
            title="Nessa página você tem acesso a visualização de todos os agendamentos para as limpezas e tarefas diárias dos ASGs"
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </ContainerTitle>

        <Search
          onSearch={(value: string) => {
            setSearch(value);
          }}
          nomePlaceHolder="Buscar"
        />

        <Link to="/agendamentos">
          <img src={IconAddBlue} alt="IconAdd" /> Criar
        </Link>

        <Calendario
          data={responseAgendamentos}
          type="leitura"
          setRange={setRangeDateGetSchedule}
          dataSearch={search}
        />
        {(loading || loadingAgenda) && <Loading />}
      </Container>
    </>
  );
};
