import styled from 'styled-components';

interface ContainerProps {
  cor: boolean;
}

export const Container = styled.div`
  width: 100%;
  padding: 30px 40px 20px 40px;

  @media screen and (max-width: 1152px) {
    padding: 10px 20px;
  }
`;

export const Aba = styled.div`
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 1152px) {
    flex-direction: column;
  }

  span {
    margin-left: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1152px) {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: ${props => props.theme.colors.bluePrimary};
    }
  }
`;

export const ContainerAba = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    border: none;
    background: transparent;

    color: ${props =>
      props.cor ? props.theme.colors.textBlack : props.theme.colors.grayBlack};
    border-bottom: ${props =>
      props.cor
        ? `1px solid ${props.theme.colors.textBlack}`
        : '1px solid transparent'};

    &:hover {
      filter: brightness(1.1);
    }
  }

  & + div {
    margin-left: 30px;

    @media screen and (max-width: 1152px) {
      margin-left: 0;
    }
  }
`;

export const ContainerFilter = styled.div`
  margin-bottom: 48px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 12px;
    }
  }

  form {
    div {
      width: 173px;
    }
  }
`;
