import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  /* :root {
    --white: #ffffff;

    --gray-100: #e1e1e6;
    --gray-300: #a8a8b3;
    --gray-600: #5f5f6d;
    --gray-700: #312E38;
    --gray-800: #29292e;
    --gray-850: #1f2729;
    --gray-900: #121214;

    --cyan-500: #61dafb;
    --yellow-500: #eba417;
  } */

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.5%;
    }
  }

  body {
    background: ${props => props.theme.colors.backgroundWhite};
    -webkit-font-smooothing: antialiased;
    font-family: 'Poppins', sans-serif;
  }

  border-style, input, button {
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
    color:  ${props => props.theme.colors.grayWhite};
    margin: 0;
  }

  input, textarea {
    color: ${props => props.theme.colors.textBlack};
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.grayWhite};
    border-radius: 16px;
  }

  &::-webkit-scrollbar {
    height: 16px;
    width: 16px;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background: ${props => props.theme.colors.grayBlack};
  }

  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-modal-content {
    background: ${props => props.theme.colors.backgroundWhite};
    padding: 40px 50px;
    position: relative;
    border-radius: 20px;
    overflow: auto;

    @media screen and (max-width: 1152px) {
      padding: 30px 40px;
    }
  }
  .react-modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background: transparent;
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
  }
`;
