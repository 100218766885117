import styled, { css } from 'styled-components';

interface DropContainerProps {
  isDragActive?: boolean;
  isDragReject?: boolean;
}

interface UploadMessageProps {
  type?: string;
}

const dragActive = css`
  border-color: ${props => props.theme.colors.greenPrimary};
`;

const dragReject = css`
  border-color: ${props => props.theme.colors.redPrimary};
`;

export const DropContainer = styled.div<DropContainerProps>`
  background: ${props => props.theme.colors.backgroundWhite};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;

  transition: 0.2s ease;

  ${props => props.isDragActive && dragActive};
  ${props => props.isDragReject && dragReject};

  &:hover {
    filter: brightness(0.95);
  }
`;

const messageColors: any = {
  default: '#B0B0B0',
  error: '#EB5757',
  success: '#219653',
};

export const UploadMessage = styled.p<UploadMessageProps>`
  color: ${props => messageColors[props.type || 'default']};
  padding: 19px;
  margin: 0 !important;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 24px !important;
    height: 24px !important;
    margin-right: 16px;
  }
`;
