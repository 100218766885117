import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

import Route from './Route';

import SignIn from '../pages/Inicio/SignIn';
import SignUp from '../pages/Inicio/Signup';
import ForgotPassword from '../pages/Inicio/ForgotPassword';
import ResetPassword from '../pages/Inicio/ResetPassword';
import Profile from '../pages/Perfil/Profile';
// import Dashboard from '../pages/Inicio/Dashboard';
import { DashboardHistAgenda } from '../pages/Inicio/DashboardHistAgenda';
// import DashboardColaboradores from '../pages/Relatorios/DashboardColaboradores';
// import DashboardAmbientes from '../pages/Relatorios/DashboardAmbientes';
// import { DashboardAgenda } from '../pages/Relatorios/DashboardAgenda';
import { Ambientes } from '../pages/Cadastros/Ambientes';
import { Setores } from '../pages/Cadastros/Setores';
import { Riscos } from '../pages/Cadastros/Riscos';
import { Procedimentos } from '../pages/Cadastros/Procedimentos';
import { Categorias } from '../pages/Cadastros/Categorias';
// import { Periodicidade } from '../pages/Cadastros/Periodicidade';
// import DashboardProcedimentos from '../pages/Relatorios/DashboardProcedimentos';
import { PreCadastroUsuarios } from '../pages/Cadastros/PreCadastroUsuarios';
import { Usuarios } from '../pages/Listagem/Usuarios';
import { Filiais } from '../pages/Cadastros/Filiais';
import { AgendamentosPage } from '../pages/Cadastros/Agendamentos';
import { FAQ } from '../pages/Relatorios/FAQ';
import { DashboardColaboradoresV2 } from '../pages/RelatoriosV2/DashboardColaboradores';
import { DashboardAmbientesV2 } from '../pages/RelatoriosV2/DashboardAmbientesTeste';
// import { DashboardAmbientesV2 } from '../pages/RelatoriosV2/DashboardAmbientes';
// import { DashboardProcedimentosV2 } from '../pages/RelatoriosV2/DashboardProcedimentos';
import { DashboardAgendaV2 } from '../pages/RelatoriosV2/DashboardAgenda';
import { Auditoria } from '../pages/Auditoria';
import { EmpresaPrincipal } from '../pages/Cadastros/EmpresaPrincipal';
// import { DadosLimpeza } from '../pages/Inicio/DadosLimpeza';
import { AgendaPage } from '../pages/Cadastros/Agenda';
import { DashboardTarefasIncompletas } from '../pages/RelatoriosV2/DashboardTarefasIncompletas';
import { ControlePublica } from '../pages/Inicio/ControlePublico';
import { DashboardAgendaV3 } from '../pages/RelatoriosV2/DashboardAgendaV3';

const Routes: React.FC = () => {
  const { user } = useAuth();
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/info/ambiente/:id" component={ControlePublica} />

      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/dashboard" component={DashboardHistAgenda} isPrivate />
      <Route path="/auditoria" component={Auditoria} isPrivate />
      <Route
        path="/historico-colaboradores"
        component={DashboardColaboradoresV2}
        isPrivate
      />
      <Route
        path="/historico-ambientes"
        component={DashboardAmbientesV2}
        isPrivate
      />
      <Route
        path="/historico-tarefas-incompletas"
        component={DashboardTarefasIncompletas}
        isPrivate
      />
      <Route path="/historico-agenda" component={DashboardAgendaV3} isPrivate />
      {user && (
        <>
          {(user.roleId === 1 ||
            user.roleId === 2 ||
            user.roleId === 3 ||
            !user.roleId) && (
            <>
              <Route
                path="/agendamentos"
                component={AgendamentosPage}
                isPrivate
              />
              {/* <Route path="/agendas" component={AgendaPage} isPrivate /> */}
              <Route path="/ambientes" component={Ambientes} isPrivate />
              <Route path="/setores" component={Setores} isPrivate />
              <Route path="/riscos" component={Riscos} isPrivate />
              <Route path="/categorias" component={Categorias} isPrivate />
              <Route
                path="/procedimentos"
                component={Procedimentos}
                isPrivate
              />

              <Route path="/faq" component={FAQ} isPrivate />
              <Route
                path="/pre-cadastro-usuarios"
                component={PreCadastroUsuarios}
                isPrivate
              />
              <Route path="/listagem-usuarios" component={Usuarios} isPrivate />
            </>
          )}
          {(user.roleId === 1 || user.roleId === 2) && (
            <Route path="/filiais" component={Filiais} isPrivate />
          )}
          {user.roleId === 1 && (
            <Route
              path="/empresa-principal"
              component={EmpresaPrincipal}
              isPrivate
            />
          )}
        </>
      )}
      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
