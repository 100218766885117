import styled from 'styled-components';
import { shade } from 'polished';

import ImagemDeFundoLimpeza from '../../assets/backgroundPdf.png';

interface IContainerModal {
  widthModal?: string;
  heigthModal?: string;
  imageStatus: boolean;
}

export const Container = styled.div<IContainerModal>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContainerHeaderDados = styled.div<IContainerModal>`
  margin: 30px 0;

  display: ${props => (props.imageStatus ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;

  p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: ${props => props.theme.colors.textBlack};
  }
`;

export const ContainerButton = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 120px;

    & + button {
      margin-left: 14px;
    }
  }

  .divButtonBack {
    background: transparent;
    border: 1px solid ${props => props.theme.colors.textBlack};
    box-sizing: border-box;
    border-radius: 16px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    color: ${props => props.theme.colors.textBlack};

    &:hover {
      background: ${props => shade(0.1, props.theme.colors.whitePrimary)};
    }
  }
`;

export const ContainerHtmlToImage = styled.div<IContainerModal>`
  width: 100%;
  height: 500px;
  margin-bottom: 40px;
  overflow: auto !important;

  @media screen and (max-width: 1400px) {
    height: 300px;
  }

  display: ${props => (props.imageStatus ? 'flex' : 'none')};
  justify-content: center;

  div {
    width: 595px;
    height: 842px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('${ImagemDeFundoLimpeza}') no-repeat center;
    background-size: 100% 100%;

    p {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      max-width: 55%;
      text-align: center;
      color: ${props => props.theme.colors.textBlack};
    }

    canvas {
      margin-bottom: 15px;
      width: 300px !important;
      height: 300px !important;
    }
  }
`;

export const ContainerCanvas = styled.div<IContainerModal>`
  canvas {
    width: 160px !important;
    height: 160px !important;
  }

  display: ${props => (props.imageStatus ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
`;
