import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import { IoIosArrowForward } from 'react-icons/io';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { FiSearch } from 'react-icons/fi';
import { Loading } from '../../../components/Loading';
import Search from '../../../components/Search';
import ModalConexao from '../../../components/ModalConexao';
import { ModalFilter, StatusButton } from '../../../components/ModalFilter';
import IconFilter from '../../../assets/IconFilter.svg';
import Pagination from '../../../components/Pagination';
import HeaderTable from '../../../components/HeaderTable';
import DropDownPagination from '../../../components/DropDownPagination';
import {
  HistoricAmbUser,
  ModalHistoricoAmbienteV2,
} from '../../../components/ModalHistoricoAmbienteV2';
import { HistoryProcedimentoSlim } from '../../../models/Historic';
import { User } from '../../../models/User';
import { Filial } from '../../../models/Filial';
import { HistoricAgendament } from '../../../models/HistoricAgend';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

import {
  Container,
  ContainerTitle,
  ContainerItem,
  ContainerSearch,
  ContainerSemResultados,
  ContainerListItens,
  ContainerPagination,
  DivCheck,
} from './styles';
import { ContainerSelect } from '../DashboardColaboradores/styles';

interface ResponseGetAgendamento {
  horaRevFinal: string[];
  horaRevInicial: string[];
  revisoes: number[];
}

interface ResponseGetProcedimentos {
  idProc: number[];
  procedimentoNome: string[];
}

export interface ResponseAgenda {
  agendaAtiva: boolean;
  agendamento: ResponseGetAgendamento;
  andar: string;
  avatar: string;
  // avatar_url: string;
  cpfUser: string;
  diasSemana: string;
  horaBaseFinal: string;
  horaBaseInicial: string;
  idAgenda: number;
  idAmbientes: number;
  idAmbienteEmAberto: boolean;
  idCategoria: number;
  idEmpresaFilial: number;
  idEmpresaPrincipal: number;
  idPeriodicidade: number;
  idSetor: number;
  idUsers: number;
  nomeAmbiente: string;
  nomeCategoria: string;
  nomePeriodicidade: string;
  nomeSetor: string;
  nomeUser: string;
  procedimentos: ResponseGetProcedimentos;
  qtdLimpezas: number;
  // isRevisao?: boolean;
}

export interface HistoryUserDate {
  User: User;
  historys: DateHistory[];
}

export interface DateHistory {
  dataAgend: string;
  historys: HistoryProcedimentoSlim[];
}

interface HourRev {
  id: number;
  horaIni: string;
  horaFim: string;
}

interface StatusObs {
  status: 'Completo' | 'Não Realizado';
  obj: string;
}

interface ResponseGet {
  AMBIENTE_NOME: string;
  ANDAR: string;
  CATEGORIA: string;
  EMPRESA: string;
  HORA_FINAL_PRINCIPAL_AMBIENTE: string;
  HORA_INICIAL_PRINCIPAL_AMBIENTE: string;
  HORA_REVISAO_FINAL: string;
  HORA_REVISAO_INICIAL: string;
  ID: number;
  PROCEDIMENTOS: string[];
  QTD: number;
  REVISAO_FINAL: string[];
  REVISAO_ID: number;
  REVISAO_INICIAL: string[];
  RISCOS: string[];
  SETOR: string;
  DATA_CRIACAO_HISTORICO: string;
  DATA_CRIACAO_REVISAO: string;
  nome_users: string;
  observacao: string;
  revisao_observacao: string;
  userId: number;
  userIdRevisao: number;
  OBSERVACAO: string[];
  DATA_FINALIZACAO_HISTORICO: string;

  companyId: number;
  empresaMae_id: number;
  DATA_FINALIZACAO_REVISAO: string;
  ID_REVISAO: number[];
  ID_USER: number[];
}

interface ResponseGetHistoric {
  id_historico: number;
  id_usuario: number;
  nome_usuario: string;
  cpf: string;
  email_usuario: string;
  avatar: string;
  telefone: string;
  ambiente_nome: string;
  procedimento: string;
  setor_id: number;
  setor_nome: string;
  categoria_id: number;
  categoria_nome: string;
  andar: string;
  imagem: string;
  data_inicio: string;
  data_fim: string;
  Revisao: null;
  HORA_INICIAL_PRINCIPAL_AMBIENTE: string;
  HORA_FINAL_PRINCIPAL_AMBIENTE: string;
  HORA_REVISAO_INICIAL: string;
  HORA_REVISAO_FINAL: string;
  DATA_CRIACAO: null;
  DATA_FINALIZACAO: null;
  revisao_id_table_revisao: null;
  imagem_revisao: null;
  OBSERVACAO: null;
}

interface ResponseGetHistoricObs {
  id: number;
  observacao: string;
  environmentId: number;
  userId: number;
  createdAt: string;
  updatedAt: string;
  TaskId: number;
}

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '40', id: '40' },
  { valor: '80', id: '80' },
  { valor: '160', id: '160' },
  { valor: 'Todos', id: '1' },
];

const headers = [
  { name: 'ID', field: 'REVISAO_ID', sortable: false },
  { name: 'Empresa', field: 'EMPRESA', sortable: false },
  { name: 'Ambiente', field: 'AMBIENTE_NOME', sortable: false },
  { name: 'Categoria', field: 'CATEGORIA', sortable: false },
  { name: 'Setor', field: 'SETOR', sortable: false },
  { name: 'Andar', field: 'ANDAR', sortable: false },
  { name: 'Usuario', field: 'nome_users', sortable: false },
  { name: 'Nome', field: 'name', sortable: false },
  { name: 'Tipo da Limpeza', field: 'type_limpeza', sortable: false },
  {
    name: 'Dia da Limpeza (Marcado)',
    field: 'DIA_PRINCIPAL_AMBIENTE',
    sortable: false,
  },
  {
    name: 'Horário inicial da Limpeza (Marcado)',
    field: 'HORARIO_INICIAL_LIMPEZA_AMBIENTE',
    sortable: false,
  },
  {
    name: 'Horário final da Limpeza (Marcado)',
    field: 'HORARIO_FINAL_LIMPEZA_AMBIENTE',
    sortable: false,
  },
  {
    name: 'Tempo médio da Limpeza (Marcado)',
    field: 'tempo_limpeza_marcado',
    sortable: false,
  },
  {
    name: 'horário inicial da Limpeza (Realizado)',
    field: 'DATA_CRIACAO_HISTORICO',
    sortable: false,
  },
  {
    name: 'horário final da Limpeza (Realizado)',
    field: 'DATA_FINAL_CRIACAO_HISTORICO',
    sortable: false,
  },
  {
    name: 'Tempo da Limpeza',
    field: 'tempo_limpeza',
    sortable: false,
  },
  {
    name: 'Tempo resultante',
    field: 'tempo_resultante',
    sortable: false,
  },
  { name: 'Observação', field: 'OBSERVACAO', sortable: false },
  { name: 'Descrição', field: 'DESCRIÇÃO', sortable: false },
  { name: 'Status', field: 'STATUS', sortable: false },
];

export const DashboardAmbientesV2: React.FC = () => {
  const { empresaPrincipal, user } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [filialId, setFilialId] = useState(-1);
  const [filiais, setFiliais] = useState<Filial[]>([]);
  const [response, setResponse] = useState<HistoricAmbUser[]>([]);
  const [modalDetalhesAmbiente, setModalDetalhesAmbiente] = useState(false);
  const [dadosModal, setDadosModal] = useState<HistoricAmbUser[]>([]);
  // const [dadosHistoricoModal, setDadosHistoricoModal] = useState<
  //   HistoryUserDate[]
  // >([]);
  const [dadosHistoricoDatasModal, setDadosHistoricoDatasModal] = useState('');
  const [dataFilterModal, setDataFilterModal] = useState<StatusButton[]>([
    {
      status: true,
      name: 'Ambiente',
    },
    {
      status: false,
      name: 'Categoria',
    },
    {
      status: false,
      name: 'Setor',
    },
    {
      status: false,
      name: 'Andar',
    },
    // {
    //   status: false,
    //   name: 'Intervalo de datas',
    // },
  ]);

  const [search, setSearch] = useState('');
  const [searchDataInicial, setSearchDataInicial] = useState('');
  const [searchDataFinal, setSearchDataFinal] = useState('');
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [isShowDadosOld, setIsShowDadosOld] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);

  // const dataInicio = moment().format('YYYY-MM-01');
  // const dataFim = moment().format('YYYY-MM-DD');
  const [dataInicioModal, setDataInicioModal] = useState('');
  const [dataFinalModal, setDataFinalModal] = useState('');

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  const getFiliais = useCallback(() => {
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';

    api
      .get(`empresas/filiais?companyId=${companyId}`)
      .then(resp => {
        const filAll: Filial[] = resp.data;
        setFiliais(filAll);
      })
      .catch(err => {
        console.log(err.mesage);
      });
  }, [empresaPrincipal]);

  const findStatus = useCallback((statObj: StatusObs[], dataAgend: string):
    | 'Completo'
    | 'Incompleto'
    | 'A iniciar'
    | 'Não Realizado'
    | 'Cancelado' => {
    const status = statObj.map(i => i.status);
    const obj = statObj.map(i => (!i.obj ? '' : i.obj)).join('');
    if (!status.includes('Não Realizado')) {
      if (obj === '') {
        return 'Completo';
      }
      return 'Cancelado';
    }
    if (!status.includes('Completo')) {
      if (dataAgend === moment().format('YYYY-MM-DD')) {
        return 'A iniciar';
      }
      return 'Não Realizado';
    }
    return 'Incompleto';
  }, []);

  const getItensOld = useCallback(
    async (
      companyId: string,
      dataStart: string,
      dataEnd: string,
    ): Promise<HistoricAmbUser[]> => {
      return api
        .get(
          `/environments/dashboard?dataI=${dataStart}&dataF=${dataEnd}&idEmpresa=${companyId}`,
        )
        .then(data => {
          // console.log(data.data);
          const hist: ResponseGet[] = data.data.historico;
          const dadosOld: HistoricAmbUser[] = [];
          hist.forEach(item => {
            const obj: HistoricAmbUser = {
              Ambiente: {
                andar: item.ANDAR,
                categoria: item.CATEGORIA,
                horaFim: item.HORA_FINAL_PRINCIPAL_AMBIENTE,
                horaIni: item.HORA_INICIAL_PRINCIPAL_AMBIENTE,
                id: item.ID,
                name: item.AMBIENTE_NOME,
                procedAll: item.PROCEDIMENTOS.map(proc => ({
                  id: -1,
                  name: proc,
                })),
                revisoes: item.REVISAO_INICIAL.map((val, index) => ({
                  horaEnd: item.REVISAO_FINAL[index],
                  horaIni: val,
                })),
                setor: item.SETOR,
              },
              companyId: item.companyId,
              dataAgend: item.DATA_CRIACAO_HISTORICO.slice(0, 10),
              dataFimAgend: item.HORA_FINAL_PRINCIPAL_AMBIENTE,
              dataFimDone: !item.DATA_FINALIZACAO_HISTORICO
                ? ''
                : moment(item.DATA_FINALIZACAO_HISTORICO).format(
                    'YYYY-MM-DD HH:mm:ss',
                  ),
              dataIniAgend: item.HORA_INICIAL_PRINCIPAL_AMBIENTE,
              dataIniDone: !item.DATA_CRIACAO_HISTORICO
                ? ''
                : moment(item.DATA_CRIACAO_HISTORICO).format(
                    'YYYY-MM-DD HH:mm:ss',
                  ),
              empresa: item.EMPRESA,
              idAgenda: -1,
              idAgendment: -1,
              imagem: '',
              observacao: item.observacao,
              description: '',
              status: item.observacao ? 'Incompleto' : 'Completo',
              Task: {
                id: -1,
                name: '',
              },
              timeLimpeza: '',
              type: '',
              User: {
                cpf: '',
                email: '',
                id: item.userId,
                name: item.nome_users,
                nameUser: '',
                telefone: '',
              },
            };
            dadosOld.push(obj);
          });
          return dadosOld;
        })
        .catch(err => {
          console.log('ERR OLD', err.message);
          return [];
        });
    },
    [],
  );

  const getItens = useCallback(
    async (dateStart: string, dateEnd: string) => {
      setLoading(true);
      const companyId =
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
      const limit = 400;
      let page = 1;
      const filId = user.companyId;

      const aux = isCoordenador
        ? `filialId=${filId}`
        : `companyId=${companyId}`;
      // console.log(companyId, dateStart, dateEnd);
      api
        .get(
          `/agendas/v2/Agendamentos/company?dataIni=${dateStart}&dataFim=${dateEnd}&${aux}&limit=${limit}&page=${page}&report=true`,
        )
        .then(async resp => {
          // console.log(resp.data);
          // const histAgendt: HistoricAgendament[] = resp.data;
          // const { pages, limit } = resp.headers;
          // console.log('RESP', resp.data);
          let histAgendt: HistoricAgendament[] = resp.data;
          let histResp: HistoricAgendament[] = resp.data;
          while (histResp.length === limit) {
            page += 1;
            const respW = await api.get(
              `/agendas/v2/Agendamentos/company?dataIni=${dateStart}&dataFim=${dateEnd}&${aux}&limit=${limit}&page=${page}&report=true`,
            );
            histResp = respW.data;
            histAgendt = [...histAgendt, ...histResp];
          }
          // const pages = Number(resp.headers.pages);
          // console.log(pages);
          // for (let i = 1; i < pages; i += 1) {
          //   const respAll = await api.get(
          //     `/agendas/v2/Agendamentos/company?companyId=${companyId}&dataIni=${dateStart}&dataFim=${dateEnd}&page=${
          //       i + 1
          //     }&limit=${limit}`,
          //   );
          //   const aux: HistoricAgendament[] = respAll.data;
          //   // console.log('NEW RESP', i + 1, respAll.data);
          //   histAgendt = [...histAgendt, ...aux];
          // }

          const histTask: HistoricAmbUser[] = [];
          histAgendt.forEach(element => {
            const hist = element.Historic || [];
            const taskAll = element.Agenda.Environment.Procedures;
            const allHour: HourRev[] = [
              {
                horaIni: element.Agenda.Periodicidade.HORA_BASE_INICIAL,
                horaFim: element.Agenda.Periodicidade.HORA_BASE_FINAL,
                id: -1,
              },
              ...element.Agenda.Periodicidade.Revisoes.map(i => ({
                horaIni: i.HORA_INICIAL,
                horaFim: i.HORA_FINAL,
                id: i.ID,
              })),
            ];
            const histIdPegos: number[] = [];
            allHour.forEach((hour, indexHour) => {
              const hists: HistoricAmbUser[] = [];
              taskAll.forEach(task => {
                const filterHist = hist
                  .filter(i => !histIdPegos.includes(i.id))
                  .filter(
                    i =>
                      i.tasksId === task.id &&
                      (i.revisionId === hour.id ||
                        (!i.revisionId && hour.id === -1)),
                  );
                if (filterHist.length > 0) {
                  const find = filterHist[0];
                  const obj: HistoricAmbUser = {
                    Ambiente: {
                      andar: element.Agenda.Environment.andar,
                      categoria: element.Agenda.Environment.Categoria.name,
                      id: element.Agenda.Environment.id,
                      name: element.Agenda.Environment.name,
                      setor: element.Agenda.Environment.Setor.name,
                      horaFim: element.Agenda.Periodicidade.HORA_BASE_FINAL,
                      horaIni: element.Agenda.Periodicidade.HORA_BASE_INICIAL,
                      procedAll: element.Agenda.Environment.Procedures.map(
                        i => ({ id: i.id, name: i.name }),
                      ),
                      revisoes: element.Agenda.Periodicidade.Revisoes.map(
                        i => ({
                          horaEnd: i.HORA_FINAL,
                          horaIni: i.HORA_INICIAL,
                        }),
                      ),
                    },
                    companyId: element.companyId,
                    dataAgend: element.dataAgend,
                    dataFimAgend: hour.horaFim,
                    dataFimDone: moment(
                      find.updatedAt.slice(0, 19).split('T').join(' '),
                    ).format('YYYY-MM-DD HH:mm:ss'),
                    dataIniAgend: hour.horaIni,
                    dataIniDone: moment(
                      find.createdAt.slice(0, 19).split('T').join(' '),
                    ).format('YYYY-MM-DD HH:mm:ss'),
                    empresa: '',
                    idAgenda: element.agendaId,
                    idAgendment: element.id,
                    imagem: !find.image
                      ? find.image
                      : `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${find.image}`,
                    observacao: find.observacao,
                    description: !find.ocorrencia ? '' : find.ocorrencia,
                    status: 'Completo',
                    Task: {
                      id: task.id,
                      name: task.name,
                    },
                    timeLimpeza: '',
                    type: indexHour === 0 ? 'Principal' : 'Revisão',
                    User: {
                      email: element.User.email,
                      id: element.userId,
                      name: element.User.nome,
                      nameUser:
                        find?.nameUser !== null ? find.nameUser?.name : '',
                      telefone: element.User.telefone,
                      cpf: element.User.cpf,
                    },
                  };
                  histIdPegos.push(find.id);
                  hists.push(obj);
                } else {
                  const obj: HistoricAmbUser = {
                    Ambiente: {
                      andar: element.Agenda.Environment.andar,
                      categoria: element.Agenda.Environment.Categoria.name,
                      id: element.Agenda.Environment.id,
                      name: element.Agenda.Environment.name,
                      setor: element.Agenda.Environment.Setor.name,
                      horaFim: element.Agenda.Periodicidade.HORA_BASE_FINAL,
                      horaIni: element.Agenda.Periodicidade.HORA_BASE_INICIAL,
                      procedAll: element.Agenda.Environment.Procedures.map(
                        i => ({ id: i.id, name: i.name }),
                      ),
                      revisoes: element.Agenda.Periodicidade.Revisoes.map(
                        i => ({
                          horaEnd: i.HORA_FINAL,
                          horaIni: i.HORA_INICIAL,
                        }),
                      ),
                    },
                    companyId: element.companyId,
                    dataAgend: element.dataAgend,
                    dataFimAgend: hour.horaFim,
                    dataFimDone: '',
                    dataIniAgend: hour.horaIni,
                    dataIniDone: '',
                    empresa: '',
                    idAgenda: element.agendaId,
                    idAgendment: element.id,
                    imagem: '',
                    observacao: '',
                    description: '',
                    status: 'Não Realizado',
                    Task: {
                      id: task.id,
                      name: task.name,
                    },
                    timeLimpeza: '',
                    type: indexHour === 0 ? 'Principal' : 'Revisão',
                    User: {
                      email: element.User.email,
                      id: element.userId,
                      name: element.User.nome,
                      nameUser: '',
                      telefone: element.User.telefone,
                      cpf: element.User.cpf,
                    },
                  };
                  hists.push(obj);
                }
              });
              const status = findStatus(
                hists.map(i => ({
                  obj: i.observacao,
                  status:
                    i.status === 'Completo' ? 'Completo' : 'Não Realizado',
                })),
                element.dataAgend,
              );
              hists.forEach(item => {
                histTask.push({
                  ...item,
                  status: status === 'Incompleto' ? 'Completo' : status,
                });
              });
            });

            const histRest = hist.filter(i => !histIdPegos.includes(i.id));
            histRest.forEach(histRes => {
              const obj: HistoricAmbUser = {
                Ambiente: {
                  andar: element.Agenda.Environment.andar,
                  categoria: element.Agenda.Environment.Categoria.name,
                  id: element.Agenda.Environment.id,
                  name: element.Agenda.Environment.name,
                  setor: element.Agenda.Environment.Setor.name,
                  horaFim: element.Agenda.Periodicidade.HORA_BASE_FINAL,
                  horaIni: element.Agenda.Periodicidade.HORA_BASE_INICIAL,
                  procedAll: element.Agenda.Environment.Procedures.map(i => ({
                    id: i.id,
                    name: i.name,
                  })),
                  revisoes: element.Agenda.Periodicidade.Revisoes.map(i => ({
                    horaEnd: i.HORA_FINAL,
                    horaIni: i.HORA_INICIAL,
                  })),
                },
                companyId: element.companyId,
                dataAgend: element.dataAgend,
                dataFimAgend: '',
                dataFimDone: moment(
                  histRes.updatedAt.slice(0, 19).split('T').join(' '),
                ).format('YYYY-MM-DD HH:mm:ss'),
                dataIniAgend: '',
                dataIniDone: moment(
                  histRes.createdAt.slice(0, 19).split('T').join(' '),
                ).format('YYYY-MM-DD HH:mm:ss'),
                empresa: '',
                idAgenda: element.agendaId,
                idAgendment: element.id,
                imagem: !histRes.image
                  ? histRes.image
                  : `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${histRes.image}`,
                observacao: histRes.observacao,
                description: !histRes.ocorrencia ? '' : histRes.ocorrencia,
                status: 'Completo',
                Task: {
                  id: histRes.tasksId,
                  name: taskAll.find(i => histRes.tasksId === i.id)?.name || '',
                },
                timeLimpeza: '',
                type: 'Extra',
                User: {
                  email: element.User.email,
                  id: element.userId,
                  name: element.User.nome,
                  nameUser:
                    histRes?.nameUser !== null ? histRes?.nameUser?.name : '',
                  telefone: element.User.telefone,
                  cpf: element.User.cpf,
                },
              };
              histTask.push(obj);
            });
          });

          if (isShowDadosOld) {
            const dadosOld = await getItensOld(
              String(companyId),
              dateStart,
              dateEnd,
            );
            dadosOld.forEach(item => {
              const find = histTask.find(
                i => i.Ambiente.id === item.Ambiente.id,
              );
              if (!find) {
                histTask.push(item);
              }
            });
          }
          setResponse(
            histTask.sort((a, b) => {
              const timeA = a.dataAgend.split('-').join('');
              const timeB = b.dataAgend.split('-').join('');
              if (timeA > timeB) return 1;
              if (timeA === timeB) return 0;
              return -1;
            }),
          );
        })
        .catch(err => {
          console.log(err);
          console.log(err.message);
          // console.log(err.response.data);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao carregar dados, por favor atualize a página',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      addToast,
      empresaPrincipal,
      findStatus,
      getItensOld,
      isCoordenador,
      isShowDadosOld,
      user.companyId,
    ],
  );

  const loadingItens = useCallback(() => {
    const mesAtual = moment().format('YYYY-MM-DD');
    if (!searchDataInicial || !searchDataFinal) {
      return;
    }
    if (searchDataInicial <= mesAtual && searchDataFinal <= mesAtual) {
      getItens(searchDataInicial, searchDataFinal);
    } else {
      addToast({
        type: 'info',
        title: 'Filtro inválido',
        description: 'Intervalo de datas inválidos, favor verificar',
      });
    }
  }, [addToast, getItens, searchDataFinal, searchDataInicial]);

  const handleMaskDate = useCallback(data => {
    const dataTemp = data.split('-').reverse().join('/');

    return dataTemp;
  }, []);

  const requestHistoricoAmbienteOld = useCallback(
    async (
      ambienteId: number,
      dataIni: string,
      dataFim: string,
    ): Promise<HistoricAmbUser[]> => {
      return api
        .get(
          `/info/all/environments?environmentId=${ambienteId}&dataInicio=${dataIni}&dataFim=${dataFim}`,
        )
        .then(resp => {
          const dados: ResponseGetHistoric[] = resp.data.data;
          const obsAll: ResponseGetHistoricObs[] = resp.data.obs;
          const dadosOld: HistoricAmbUser[] = [];

          dados.forEach(item => {
            const find = dadosOld.find(
              i =>
                i.User.id === item.id_usuario &&
                i.dataAgend === item.data_inicio.slice(0, 10),
            );
            const firstCreated = !find ? '' : find.dataIniDone;
            const observ = obsAll.find(
              i =>
                i.createdAt.slice(0, 19) === item.data_inicio.slice(0, 19) &&
                i.environmentId === ambienteId &&
                i.userId === item.id_usuario,
            );

            const obj: HistoricAmbUser = {
              Ambiente: {
                andar: item.andar,
                categoria: item.categoria_nome,
                horaFim: item.HORA_FINAL_PRINCIPAL_AMBIENTE,
                horaIni: item.HORA_INICIAL_PRINCIPAL_AMBIENTE,
                id: ambienteId,
                name: item.ambiente_nome,
                procedAll: [],
                revisoes:
                  !item.HORA_REVISAO_INICIAL || !item.HORA_REVISAO_FINAL
                    ? []
                    : [
                        {
                          horaEnd: item.HORA_REVISAO_FINAL,
                          horaIni: item.HORA_REVISAO_INICIAL,
                        },
                      ],
                setor: item.setor_nome,
              },
              companyId: -1,
              dataAgend: item.data_inicio.slice(0, 10),
              dataFimAgend: item.HORA_FINAL_PRINCIPAL_AMBIENTE,
              dataFimDone: !item.data_fim
                ? ''
                : moment(item.data_fim).format('YYYY-MM-DD HH:mm:ss'),
              dataIniAgend: item.HORA_INICIAL_PRINCIPAL_AMBIENTE,
              dataIniDone: !item.data_inicio
                ? ''
                : moment(item.data_inicio).format('YYYY-MM-DD HH:mm:ss'),
              empresa: '',
              idAgenda: -1,
              idAgendment: -1,
              imagem: !item.imagem
                ? ''
                : `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${item.imagem}`,
              observacao: !observ ? '' : observ.observacao,
              description: '',
              status: !observ ? 'Completo' : 'Incompleto',
              Task: {
                id: -1,
                name: item.procedimento,
              },
              timeLimpeza: '',
              type:
                firstCreated !== '' && firstCreated !== item.DATA_CRIACAO
                  ? 'Revisão'
                  : 'Principal',
              User: {
                cpf: item.cpf,
                email: item.email_usuario,
                id: item.id_usuario,
                name: item.nome_usuario,
                nameUser: '',
                telefone: item.telefone,
              },
            };
            dadosOld.push(obj);
          });

          return dadosOld;
        })
        .catch(err => {
          console.log('err', err.message);
          return [];
        });
    },
    [],
  );

  const requestHistoricoAmbiente = useCallback(
    async (res: HistoricAmbUser) => {
      const dataInicio = moment().format('YYYY-MM-01');
      const dataFim = moment().format('YYYY-MM-DD');
      const mesDataInicial = moment(searchDataInicial).format('YYYY-MM-DD');
      const mesDataFinal = moment(searchDataFinal).format('YYYY-MM-DD');
      const dateStart =
        searchDataInicial.length === 10 &&
        searchDataFinal.length === 10 &&
        searchDataInicial.substr(0, 1) !== '0' &&
        searchDataFinal.substr(0, 1) !== '0'
          ? mesDataInicial
          : dataInicio;
      const dateEnd =
        searchDataInicial.length === 10 &&
        searchDataFinal.length === 10 &&
        searchDataInicial.substr(0, 1) !== '0' &&
        searchDataFinal.substr(0, 1) !== '0'
          ? mesDataFinal
          : dataFim;

      const histTask: HistoricAmbUser[] = response.filter(
        i => i.Ambiente.id === res.Ambiente.id && i.type !== '',
      );

      if (isShowDadosOld) {
        const dadosOld = await requestHistoricoAmbienteOld(
          res.Ambiente.id,
          dateStart,
          dateEnd,
        );

        dadosOld.forEach(item => {
          const find = histTask.find(
            i =>
              i.Ambiente.id === item.Ambiente.id &&
              i.dataAgend === item.dataAgend &&
              i.Task.name === item.Task.name &&
              i.dataIniDone === item.dataIniAgend,
          );
          if (!find) {
            histTask.push(item);
          }
        });
      }

      setDadosModal(histTask);
      setDadosHistoricoDatasModal(
        `De ${handleMaskDate(dateStart)} até ${handleMaskDate(dateEnd)}`,
      );
      setModalDetalhesAmbiente(true);
      setDataInicioModal(dateStart);
      setDataFinalModal(dateEnd);
      setLoading(false);
    },
    [
      handleMaskDate,
      isShowDadosOld,
      requestHistoricoAmbienteOld,
      response,
      searchDataFinal,
      searchDataInicial,
    ],
  );

  const tempoLimpeza = useCallback((dado: string, dado2: string): string => {
    if (dado && dado2) {
      const diff = moment(new Date(dado), 'DD/MM/YYYY HH:mm:ss').diff(
        moment(new Date(dado2), 'DD/MM/YYYY HH:mm:ss'),
      );

      const horas =
        moment.duration(diff).hours().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).hours().toString().replace('-', '')}`
          : moment.duration(diff).hours().toString().replace('-', '');
      const minutos =
        moment.duration(diff).minutes().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).minutes().toString().replace('-', '')}`
          : moment.duration(diff).minutes().toString().replace('-', '');
      const segundos =
        moment.duration(diff).seconds().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).seconds().toString().replace('-', '')}`
          : moment.duration(diff).seconds().toString().replace('-', '');
      const horario = `${horas}:${minutos}:${segundos}`;
      return horario;
    }
    return ' - ';
  }, []);

  const timeResult = useCallback((timeHMS: string, timeHMS2: string) => {
    function segToHMS(segs: number): string {
      const hours = Math.trunc(segs / 3600);
      const minute = Math.trunc((segs - 3600 * hours) / 60);
      const seconds = segs - (3600 * hours + 60 * minute);
      // console.log(data, data2, hours, minute, seconds);
      return `${hours < 10 ? `0${hours}` : hours}:${
        minute < 10 ? `0${minute}` : minute
      }:${seconds < 10 ? `0${seconds}` : seconds}`;
    }
    if (timeHMS !== ' - ' && timeHMS2 !== ' - ') {
      const [hour1, min1, seg1] = timeHMS.split(':');
      const [hour2, min2, seg2] = timeHMS2.split(':');
      const time1 = Number(seg1) + 60 * (Number(min1) + 60 * Number(hour1));
      const time2 = Number(seg2) + 60 * (Number(min2) + 60 * Number(hour2));
      const diff = time1 - time2;
      if (diff < 0) {
        const diff2 = time2 - time1;
        return `- ${segToHMS(diff2)}`;
      }
      return `+ ${segToHMS(diff)}`;
    }
    return ' - ';
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [search, searchDataInicial, searchDataFinal]);

  useEffect(() => {
    getFiliais();
  }, [getFiliais]);

  const responseData = useMemo(() => {
    let computedResponses = [
      ...new Map(response.map(item => [item.Ambiente.id, item])).values(),
    ];

    computedResponses = computedResponses.map(i => {
      const findEmpresa = filiais.find(emp => emp.ID === i.companyId);
      return { ...i, empresa: findEmpresa ? findEmpresa.NOME : '' };
    });

    if (filialId !== -1) {
      computedResponses = computedResponses.filter(
        i => filialId === i.companyId,
      );
    }

    if (search) {
      computedResponses = computedResponses.filter(res => {
        const dataFilter: any = [];

        dataFilterModal.forEach(filtro => {
          if (filtro.status) {
            switch (filtro.name) {
              case 'Ambiente':
                dataFilter.push(res.Ambiente.name);
                break;
              case 'Categoria':
                dataFilter.push(res.Ambiente.categoria);
                break;
              case 'Setor':
                dataFilter.push(res.Ambiente.setor);
                break;
              case 'Andar':
                dataFilter.push(res.Ambiente.andar);
                break;
              default:
                break;
            }
          }
        });

        return dataFilter.some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        );
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    ITEMS_PER_PAGE,
    currentPage,
    dataFilterModal,
    filiais,
    filialId,
    response,
    search,
  ]);

  const responseDataExcel = useMemo(() => {
    let computedResponses: HistoricAmbUser[] = [];

    computedResponses = [
      ...new Map(
        response.map(item => [
          `${item.idAgenda}-${item.Ambiente.id}-${item.dataAgend}-${item.dataIniAgend}-${item.User.id}-${item.dataIniDone}`,
          item,
        ]),
      ).values(),
    ].filter(i => !(i.status === 'Completo' && !i.dataIniDone));

    if (filialId !== -1) {
      computedResponses = computedResponses.filter(
        i => filialId === i.companyId,
      );
    }

    computedResponses = computedResponses.map(i => {
      const findEmpresa = filiais.find(emp => emp.ID === i.companyId);
      return { ...i, empresa: findEmpresa ? findEmpresa.NOME : '' };
    });

    if (search) {
      computedResponses = computedResponses.filter(res => {
        const dataFilter: any = [];

        dataFilterModal.forEach(filtro => {
          if (filtro.status) {
            switch (filtro.name) {
              case 'Ambiente':
                dataFilter.push(res.Ambiente.name);
                break;
              case 'Categoria':
                dataFilter.push(res.Ambiente.categoria);
                break;
              case 'Setor':
                dataFilter.push(res.Ambiente.setor);
                break;
              case 'Andar':
                dataFilter.push(res.Ambiente.andar);
                break;
              default:
                break;
            }
          }
        });

        return dataFilter.some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        );
      });
    }
    return computedResponses;
  }, [dataFilterModal, filiais, filialId, response, search]);

  // function DataDaLimpezaRealizado(
  //   dataRevisao: string,
  //   dataHistorico: string,
  // ): string {
  //   if (dataRevisao) {
  //     return moment(dataRevisao).format('DD/MM/YYYY');
  //   }
  //   if (dataHistorico) {
  //     return moment(dataHistorico).format('DD/MM/YYYY');
  //   }
  //   return ' - ';
  // }

  // function HorarioDaLimpezaRealizado(
  //   horaRevisao: string,
  //   horaHistorico: string,
  // ): string {
  //   if (horaRevisao) {
  //     return moment(horaRevisao).format('HH:mm:ss');
  //   }
  //   if (horaHistorico) {
  //     return moment(horaHistorico).format('HH:mm:ss');
  //   }
  //   return ' - ';
  // }

  return (
    <>
      <ModalConexao />

      <ModalFilter
        isOpen={showModalFilter}
        type="dashAmbientes"
        onRequestClose={() => setShowModalFilter(false)}
        onReturnData={e => setDataFilterModal(e)}
      />

      {!!dadosModal &&
        // !!dadosHistoricoModal &&
        // !!dadosHistoricoObsModal &&
        !!dataInicioModal &&
        !!dataFinalModal && (
          <ModalHistoricoAmbienteV2
            isOpen={modalDetalhesAmbiente}
            dados={dadosModal}
            // dadosHistorico={dadosHistoricoModal}
            dadosHistoricoDatas={dadosHistoricoDatasModal}
            onRequestClose={() => setModalDetalhesAmbiente(false)}
          />
        )}

      <Container>
        <ContainerTitle>
          <h1>Ambientes</h1>

          <Tooltip
            title="Nessa página você tem acesso a visualização detalhada do histórico de atividades realizadas pelos ASGs. Filtragem a partir dos ambientes."
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </ContainerTitle>

        {!isCoordenador && (
          <ContainerSelect>
            <select
              value={filialId}
              onChange={event => {
                const val = parseInt(event.target.value, 10);
                setFilialId(val);
              }}
            >
              <option value={-1}>Todos</option>
              {filiais.map(i => (
                <option key={i.ID} value={i.ID}>
                  {i.NOME}
                </option>
              ))}
            </select>
          </ContainerSelect>
        )}

        <ContainerSearch isFilterDateInterval>
          <Search
            onSearch={(value: string) => {
              setSearchDataInicial(value);
            }}
            nomePlaceHolder="Buscar data inicial"
            type="date"
          />

          <Search
            onSearch={(value: string) => {
              setSearchDataFinal(value);
            }}
            nomePlaceHolder="Buscar data final"
            type="date"
          />
        </ContainerSearch>

        <ContainerSearch isFilterDateInterval={false}>
          <Search
            onSearch={(value: string) => {
              setSearch(value);
            }}
            nomePlaceHolder="Buscar"
          />

          <button type="button" onClick={loadingItens}>
            <FiSearch size={20} />
          </button>

          <button type="button" onClick={() => setShowModalFilter(true)}>
            <img src={IconFilter} alt="iconFilter" />
          </button>
        </ContainerSearch>

        <DivCheck>
          <input
            type="checkbox"
            checked={isShowDadosOld}
            onChange={() => setIsShowDadosOld(!isShowDadosOld)}
          />
          <p>Mostrar dados anteriores à Agosto/2022</p>
          <Tooltip
            title="Marque somente caso queira obter dados da versão anterior do sistema referente aos dados inseridos antes de agosto de 2022."
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </DivCheck>

        {response.length === 0 && (
          <ContainerSemResultados>
            <h2>
              Não existe um histórico de ambientes para o intervalo de datas
              atual
            </h2>
          </ContainerSemResultados>
        )}

        <ContainerListItens>
          <>
            {responseData.map((res, index) => (
              <ContainerItem
                key={`${res.Ambiente.id}-${
                  res.Ambiente.name
                }-${index.toString()}`}
                isColor={index % 2 !== 0}
              >
                <button
                  type="button"
                  onClick={() => requestHistoricoAmbiente(res)}
                >
                  <ul>
                    <li>
                      <strong>Ambiente</strong>
                      <span>{res.Ambiente.name}</span>
                    </li>
                    <li>
                      <strong>Categoria</strong>
                      <span>{res.Ambiente.categoria}</span>
                    </li>
                    <li>
                      <strong>Setor</strong>
                      <span>{res.Ambiente.setor}</span>
                    </li>
                    <li>
                      <strong>Andar</strong>
                      <span>{res.Ambiente.andar}</span>
                    </li>
                  </ul>
                </button>

                <IoIosArrowForward />
              </ContainerItem>
            ))}

            <table id="tableListagemAmbientes" style={{ display: 'none' }}>
              <HeaderTable headers={headers} />
              <tbody>
                {responseDataExcel.map((res, index) => (
                  <tr
                    key={`${res.idAgendment}-${res.idAgenda}-${
                      res.Ambiente.id
                    }-${res.dataAgend}-${res.Task.id}-${
                      res.dataIniDone
                    }-${index.toString()}`}
                  >
                    <td>{res.idAgenda}</td>
                    <td>{res.empresa}</td>
                    <td>{res.Ambiente.name}</td>
                    <td>{res.Ambiente.categoria}</td>
                    <td>{res.Ambiente.setor}</td>
                    <td>{res.Ambiente.andar}</td>
                    <td>{res.User.name}</td>
                    <td>{res.User.nameUser}</td>
                    <td>{res.type}</td>
                    <td>{moment(res.dataAgend).format('DD/MM/YYYY')}</td>
                    <td>{res.dataIniAgend}</td>
                    <td>{res.dataFimAgend}</td>
                    <td>
                      {tempoLimpeza(
                        `2022-01-01 ${res.dataIniAgend}`,
                        `2022-01-01 ${res.dataFimAgend}`,
                      )}
                    </td>
                    <td>{res.dataIniDone.split(' ')[1]}</td>
                    <td>{res.dataFimDone.split(' ')[1]}</td>
                    <td>{tempoLimpeza(res.dataIniDone, res.dataFimDone)}</td>
                    <td>
                      {timeResult(
                        tempoLimpeza(
                          `2022-01-01 ${res.dataIniAgend}`,
                          `2022-01-01 ${res.dataFimAgend}`,
                        ),
                        tempoLimpeza(res.dataIniDone, res.dataFimDone),
                      )}
                    </td>
                    <td>{res.observacao}</td>
                    <td>{res.description}</td>
                    <td>{res.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        </ContainerListItens>

        <ContainerPagination>
          {responseData.length > 0 && (
            <>
              <div className="divPaginacao">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
                <DropDownPagination
                  onChangeItems={(value: string) => {
                    setCurrentPage(1);
                    if (value === 'Todos') {
                      setITEMS_PER_PAGE(1);
                    } else {
                      setITEMS_PER_PAGE(Number(value));
                    }
                  }}
                  objetoEnum={dropDown}
                  minWidth={30}
                />
              </div>

              <ReactHTMLTableToExcel
                id="export-excel"
                className="btn"
                table="tableListagemAmbientes"
                filename={`listagem-ambientes-${moment().format('DD-MM-YYYY')}`}
                sheet="tablexls"
                buttonText="Exportar Excel"
              />
            </>
          )}
        </ContainerPagination>
      </Container>

      {loading && <Loading />}
    </>
  );
};
