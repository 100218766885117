/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState, useMemo } from 'react';
import Modal from 'react-modal';
import { uuid } from 'uuidv4';
import jsPDF from 'jspdf';
import moment from 'moment';
import { AiOutlineException } from 'react-icons/ai';
import { VscFilePdf } from 'react-icons/vsc';
import { RiFileExcel2Line } from 'react-icons/ri';
import { FiAlertCircle } from 'react-icons/fi';
import { IoIosArrowForward } from 'react-icons/io';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Zoom from 'react-img-zoom';
import { Link } from 'react-router-dom';
import HeaderTable from '../HeaderTable';
import Search from '../Search';
import Button from '../Button';
import { maskCpf, maskTel } from '../InputOverview/mask';

import {
  ContainerModal,
  ContainerHeader,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
  ContainerMap,
  ContainerMapDetails,
  ContainerButtonsPdfExcel,
  ContainerButtonDetalhes,
  ContainerTableMacon,
  ContainerSearch,
  ContainerListCards,
  ButtonDetalhes,
  IconeAtrasado,
  HistoricoVazio,
  ZoomImg,
  ButtonImgNull,
  ButtonImg,
} from './styles';
import { HistoryProcedimentoSlim } from '../../models/Historic';

Modal.setAppElement('#root');

export interface HistAmbienteAgendamento {
  nameAmbiente: string;
  idAmbiente: number;
  categoria: string;
  setor: string;
  andar: string;
  horaIni: string;
  horaFim: string;
  horaRevisoes: {
    horaIni: string;
    horaEnd: string;
  }[];
  procedimentos: string[];
  companyId: number;
  history: HistoryProcedimentoSlim[];
}

export interface HistoricAmbUser {
  Ambiente: {
    name: string;
    id: number;
    categoria: string;
    setor: string;
    andar: string;
    horaIni: string;
    horaFim: string;
    revisoes: {
      horaIni: string;
      horaEnd: string;
    }[];
    procedAll: {
      name: string;
      id: number;
    }[];
  };
  User: {
    name: string;
    nameUser: string;
    id: number;
    telefone: string;
    email: string;
    cpf: string;
  };
  idAgendment: number;
  idAgenda: number;
  companyId: number;
  empresa: string;
  dataAgend: string;
  dataIniAgend: string;
  dataFimAgend: string;
  dataIniDone: string;
  dataFimDone: string;
  escondido?: boolean;
  timeLimpeza: string;
  observacao: string;
  description: string;
  Task: {
    id: number;
    name: string;
  };
  imagem: string;
  status:
    | 'Completo'
    | 'Incompleto'
    | 'A iniciar'
    | 'Não Realizado'
    | 'Cancelado com Justificativa'
    | 'Cancelado';
  type: 'Principal' | 'Revisão' | 'Extra' | '';
}

interface ModalDetalhesAmbientes {
  isOpen: boolean;
  dados: HistoricAmbUser[];
  // dadosHistorico: HistoryUserDate[];
  dadosHistoricoDatas: string;
  onRequestClose: () => void;
}

const headers = [
  { name: 'Ambiente', field: 'ambiente' },
  { name: 'Colaborador', field: 'colaborador' },
  { name: 'CPF', field: 'cpf' },
  { name: 'E-mail', field: 'email' },
  { name: 'Telefone', field: 'telefone' },
  {
    name: 'Horário base (agendado)',
    field: 'horarioPrimeiraLimpeza',
  },
];

const headersHorarios = [
  { name: 'Ambiente', field: 'ambiente' },
  { name: 'Colaborador', field: 'colaborador' },
  { name: 'CPF', field: 'cpf' },
  { name: 'E-mail', field: 'email' },
  { name: 'Telefone', field: 'telefone' },
  {
    name: 'Horário base (agendado)',
    field: 'horarioPrimeiraLimpeza',
  },
  { name: 'Data e hora inicial (realizado)', field: 'dataInicial' },
  { name: 'Data e hora final (realizado)', field: 'dataFinal' },
  { name: 'Observação', field: 'observacao' },
];

const headersRevisions = [
  { name: 'Ambiente', field: 'ambiente' },
  { name: 'Colaborador', field: 'colaborador' },
  { name: 'Nome', field: 'name' },
  { name: 'CPF', field: 'cpf' },
  { name: 'E-mail', field: 'email' },
  { name: 'Telefone', field: 'telefone' },
  {
    name: 'Horário base (agendado)',
    field: 'horarioPrimeiraLimpeza',
  },
  { name: 'Horário da revisão (agendado)', field: 'horarioRevisao' },
  { name: 'Data e hora inicial (realizado)', field: 'dataInicial' },
  { name: 'Data e hora final (realizado)', field: 'dataFinal' },
  { name: 'Procedimento', field: 'procedimento' },
  { name: 'Observação', field: 'observacao' },
  { name: 'Descrição', field: 'description' },
  {
    name: 'Horários das revisões (realizado)',
    field: 'horarioRealizadoRevisao',
  },
  { name: 'Foto', field: 'foto' },
];

export const ModalHistoricoAmbienteV2: React.FC<ModalDetalhesAmbientes> = ({
  isOpen,
  dados,
  dadosHistoricoDatas,
  onRequestClose,
}) => {
  const [search, setSearch] = useState('');
  const [searchDatas, setSearchDatas] = useState('');
  const [searchProcedimento, setSearchProcedimento] = useState('');

  // const [userToHistory, setUserToHistory] = useState<ResponseGetHistoric[]>([]);
  const [dateCreated, setDateCreated] = useState('');
  const [dadosHistoricoExcelPdf, setDadosHistoricoExcelPdf] = useState<
    HistoricAmbUser[]
  >([]);
  const [detalhesHistoricoExcelPdf, setDetalhesHistoricoExcelPdf] = useState<
    HistoricAmbUser[]
  >([]);
  const [
    procedimentosHistoricoExcelPdf,
    setProcedimentosHistoricoExcelPdf,
  ] = useState<HistoricAmbUser[]>([]);
  const [userResponseData, setUserResponseData] = useState<number>();
  const [exibirDetalhes, setExibirDetalhes] = useState(false);
  const [exibirDetalhesData, setExibirDetalhesData] = useState(false);
  const [newDetalhesHistorico, setNewDetalhesHistorico] = useState<
    HistoricAmbUser[]
  >();
  const [zoomImg, setZoomImg] = useState('');
  const [img, setImg] = useState<string | HistoricAmbUser[]>();

  // useEffect(() => {
  //   const dadosHistoricoData: ResponseGetHistoric[] = [];

  //   dadosHistorico.forEach(element => {
  //     element.historys.forEach(item => {
  //       item.historys.forEach(i => {
  //         dadosHistoricoData.push({
  //           data_fim: i.updatedAt,
  //           data_inicio: i.createdAt,
  //           horaFinalAmbiente: dados.horaFim,
  //           horaInicialAmbiente: dados.horaIni,
  //           observacao: i.observacao,
  //           procedimento: i.nameProcedimento,
  //           procedAll: dados.procedimentos,
  //           revisoes: dados.horaRevisoes,
  //           User: {
  //             cpf: element.User.cpf,
  //             email: element.User.email,
  //             id: element.User.id,
  //             name: element.User.nome,
  //             telefone: element.User.telefone,
  //           },
  //           imagem: i.imageUrl,
  //           isRevision: i.isRevisao,
  //         });
  //       });
  //     });
  //   });

  //   setUserToHistory(dadosHistoricoData);
  // }, [
  //   dados.horaFim,
  //   dados.horaIni,
  //   dados.horaRevisoes,
  //   dados.procedimentos,
  //   dadosHistorico,
  // ]);

  const onButtonClick = useCallback(
    dadoParam => {
      const newResponseData =
        newDetalhesHistorico &&
        newDetalhesHistorico.map(dado => {
          return JSON.stringify(dado) === JSON.stringify(dadoParam)
            ? { ...dado, escondido: !dado.escondido }
            : dado;
        });
      setNewDetalhesHistorico(newResponseData);
    },
    [newDetalhesHistorico],
  );

  // const findNote = useCallback(
  //   (dataHistory: ResponseGetHistoric): string => {
  //     for (let j = 0; j < dadosHistoricoObs.length; j += 1) {
  //       const dateDataInitHistory = moment(dataHistory.data_inicio).format(
  //         'DD/MM/YYYY HH:mm:ss',
  //       );
  //       const dateDataFinalHistory = moment(dataHistory.data_fim).format(
  //         'DD/MM/YYYY HH:mm:ss',
  //       );

  //       const dateInitNote = moment(dadosHistoricoObs[j].createdAt).format(
  //         'DD/MM/YYYY HH:mm:ss',
  //       );
  //       const dateFinalNote = moment(dadosHistoricoObs[j].updatedAt).format(
  //         'DD/MM/YYYY HH:mm:ss',
  //       );

  //       if (
  //         dataHistory.id_usuario === dadosHistoricoObs[j].userId &&
  //         dateDataInitHistory === dateInitNote &&
  //         dateDataFinalHistory === dateFinalNote
  //       ) {
  //         return dadosHistoricoObs[j].observacao;
  //       }
  //     }
  //     return '-';
  //   },
  //   [dadosHistoricoObs],
  // );

  // const findNoteDate = useCallback(
  //   dataHistory => {
  //     for (let j = 0; j < dadosHistoricoObs.length; j += 1) {
  //       if (
  //         dataHistory.id_usuario === dadosHistoricoObs[j].userId &&
  //         dataHistory.ambiente_nome === dados.nameAmbiente &&
  //         moment(new Date(dadosHistoricoObs[j].createdAt)).format(
  //           'YYYY-MM-DD',
  //         ) >= dadosDataInicio &&
  //         moment(new Date(dadosHistoricoObs[j].updatedAt)).format(
  //           'YYYY-MM-DD',
  //         ) <= dadosDataFinal
  //       ) {
  //         return true;
  //       }
  //     }

  //     return false;
  //   },
  //   [dados.nameAmbiente, dadosDataFinal, dadosDataInicio, dadosHistoricoObs],
  // );

  // const findNoteUser = useCallback(
  //   dataHistory => {
  //     for (let j = 0; j < dadosHistoricoObs.length; j += 1) {
  //       const dateDataHistory = moment(dataHistory.data_inicio).format(
  //         'DD/MM/YYYY HH:mm:ss',
  //       );
  //       const dateNote = moment(dadosHistoricoObs[j].createdAt).format(
  //         'DD/MM/YYYY HH:mm:ss',
  //       );

  //       if (
  //         dataHistory.id_usuario === dadosHistoricoObs[j].userId &&
  //         dateDataHistory === dateNote
  //       ) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   },
  //   [dadosHistoricoObs],
  // );

  // const findNoteDateUnic = useCallback(
  //   (dataHistory: ResponseGetHistoric): string => {
  //     for (let j = 0; j < dadosHistoricoObs.length; j += 1) {
  //       const dateDataHistory = moment(dataHistory.data_inicio).format(
  //         'DD/MM/YYYY HH:mm:ss',
  //       );
  //       const dateNote = moment(dadosHistoricoObs[j].createdAt).format(
  //         'DD/MM/YYYY HH:mm:ss',
  //       );

  //       if (
  //         dados.idAmbiente === dadosHistoricoObs[j].environmentId &&
  //         dateDataHistory === dateNote
  //       ) {
  //         return dadosHistoricoObs[j].observacao;
  //       }
  //     }
  //     return '-';
  //   },
  //   [dados.idAmbiente, dadosHistoricoObs],
  // );

  // const findNoteDateUnicRevision = useCallback(
  //   (dataHistory: ResponseGetHistoric): string => {
  //     for (let j = 0; j < dadosHistoricoObs.length; j += 1) {
  //       const dateDataHistory = moment(dataHistory.DATA_CRIACAO).format(
  //         'DD/MM/YYYY HH:mm:ss',
  //       );
  //       const dateNote = moment(dadosHistoricoObs[j].createdAt).format(
  //         'DD/MM/YYYY HH:mm:ss',
  //       );

  //       if (
  //         dados.idAmbiente === dadosHistoricoObs[j].environmentId &&
  //         dateDataHistory === dateNote
  //       ) {
  //         return dadosHistoricoObs[j].observacao;
  //       }
  //     }
  //     return '-';
  //   },
  //   [dados.idAmbiente, dadosHistoricoObs],
  // );

  // const handleButtonDate = useCallback(
  //   dado => {
  //     dadosHistorico !== undefined &&
  //       dadosHistorico.forEach(element => {
  //         if (
  //           dado.id_usuario === element.User.id &&
  //           dadosHistoricoDataInicio.indexOf(element.data_inicio) === -1 &&
  //           dadosHistoricoDataFim.indexOf(element.data_fim) === -1
  //         ) {
  //           dadosHistoricoDataInicio.push(element.data_inicio);
  //           dadosHistoricoDataFim.push(element.data_fim);
  //           newDetalhesHistoricoData.push({ ...element, escondido: false });
  //         }
  //       });

  //     setDataHistoryButtom(dado);
  //     setNewDetalhesHistorico(newDetalhesHistoricoData);
  //   },
  //   [
  //     dadosHistorico,
  //     newDetalhesHistoricoData,
  //     dadosHistoricoDataInicio,
  //     dadosHistoricoDataFim,
  //   ],
  // );

  // const filterScheduledReviews = useCallback(() => {
  //   const dataFilterRevision = userToHistory.filter(
  //     element =>
  //       element.data_inicio === dataHistoryButtom?.data_inicio &&
  //       element.data_fim === dataHistoryButtom?.data_fim,
  //   );

  //   const filterDateInitRevision: string[] = [];
  //   const filterDateFinalRevision: string[] = [];
  //   const filterDateRevisions: string[] = [];

  //   dataFilterRevision.forEach(element => {
  //     if (
  //       filterDateInitRevision.indexOf(element.HORA_REVISAO_INICIAL) === -1 &&
  //       filterDateFinalRevision.indexOf(element.HORA_REVISAO_FINAL) === -1
  //     ) {
  //       filterDateInitRevision.push(element.HORA_REVISAO_INICIAL);
  //       filterDateFinalRevision.push(element.HORA_REVISAO_FINAL);
  //       if (
  //         element.HORA_REVISAO_INICIAL !== null &&
  //         element.HORA_REVISAO_FINAL !== null
  //       ) {
  //         filterDateRevisions.push(
  //           `${element.HORA_REVISAO_INICIAL} - ${element.HORA_REVISAO_FINAL}`,
  //         );
  //       }
  //     }
  //   });

  //   return filterDateRevisions.length ? filterDateRevisions.join(', ') : '-';
  // }, [
  //   dataHistoryButtom?.data_fim,
  //   dataHistoryButtom?.data_inicio,
  //   userToHistory,
  // ]);

  // const filterPhotosReviews = useCallback(() => {
  //   const dataFilterRevision = userToHistory.filter(
  //     element =>
  //       element.DATA_CRIACAO &&
  //       element.DATA_FINALIZACAO &&
  //       element.data_inicio === dataHistoryButtom?.data_inicio &&
  //       element.data_fim === dataHistoryButtom?.data_fim &&
  //       moment(element.data_inicio).format('DD/MM/YYYY') ===
  //         moment(element.DATA_CRIACAO).format('DD/MM/YYYY'),
  //   );

  //   const filterDateIdRevision: number[] = [];
  //   const filterDateObjectRevision: ResponseGetHistoric[] = [];

  //   dataFilterRevision.forEach(element => {
  //     if (
  //       filterDateIdRevision.indexOf(element.revisao_id_table_revisao) === -1
  //     ) {
  //       filterDateIdRevision.push(element.revisao_id_table_revisao);
  //       filterDateObjectRevision.push(element);
  //     }
  //   });

  //   return filterDateObjectRevision;
  // }, [
  //   dataHistoryButtom?.data_fim,
  //   dataHistoryButtom?.data_inicio,
  //   userToHistory,
  // ]);

  // const filterSchedulesReviewsDone = useCallback(() => {
  //   const filterReviews: string[] = [];
  //   const filterReviewsDateInit: string[] = [];
  //   const filterReviewsDateFinal: string[] = [];

  //   filterPhotosReviews().forEach(element => {
  //     if (
  //       filterReviewsDateInit.indexOf(element.DATA_CRIACAO) === -1 &&
  //       filterReviewsDateFinal.indexOf(element.DATA_FINALIZACAO) === -1
  //     ) {
  //       filterReviewsDateInit.push(element.DATA_CRIACAO);
  //       filterReviewsDateFinal.push(element.DATA_FINALIZACAO);

  //       filterReviews.push(
  //         `${moment(element.DATA_CRIACAO).format('HH:mm:ss')} - ${moment(
  //           element.DATA_FINALIZACAO,
  //         ).format('HH:mm:ss')}`,
  //       );
  //     }
  //   });

  //   return filterReviews.length ? filterReviews.join(', ') : '-';
  // }, [filterPhotosReviews]);

  // const handleButtonProcedimentos = useCallback(
  //   (dado: ResponseGetHistoric) => {
  //     const newDetalhesHistoricoProcedimentos: ResponseGetHistoric[] = [];
  //     const filterDateId: number[] = [];

  //     userToHistory.forEach(element => {
  //       if (
  //         dado.data_inicio === element.data_inicio &&
  //         dado.data_fim === element.data_fim &&
  //         dado.id_usuario === element.id_usuario
  //       ) {
  //         filterDateId.push(element.id_historico);
  //         newDetalhesHistoricoProcedimentos.push(element);
  //       }
  //     });

  //     setDataHistoryButtom(dado);
  //     setNewHistoricoProcedimentos(newDetalhesHistoricoProcedimentos);
  //   },
  //   [userToHistory],
  // );

  // diferença de duas datas que entrega esse formato string HH:mm:ss
  const getDiffData = useCallback((data: string, data2: string): string => {
    const difTime =
      (new Date(data).getTime() - new Date(data2).getTime()) / 1000;
    const hours = Math.trunc(difTime / 3600);
    const minute = Math.trunc((difTime - 3600 * hours) / 60);
    const seconds = difTime - (3600 * hours + 60 * minute);
    // console.log(data, data2, hours, minute, seconds);
    return `${hours < 10 ? `0${hours}` : hours}:${
      minute < 10 ? `0${minute}` : minute
    }:${seconds < 10 ? `0${seconds}` : seconds}`;
  }, []);

  // diferença de tempo entre dois objetos de data inicial e final - Retorno: HH:mm:ss
  const getDiffTimes = useCallback(
    (
      obj1: { timeInit: string; timeEnd: string },
      obj2: { timeInit: string; timeEnd: string },
    ): string => {
      const difTimeIni =
        (new Date(obj1.timeInit).getTime() - new Date(obj1.timeEnd).getTime()) /
        1000;
      const difTimeEnd =
        (new Date(obj2.timeInit).getTime() - new Date(obj2.timeEnd).getTime()) /
        1000;

      if (difTimeEnd > difTimeIni) {
        const difTime = difTimeEnd - difTimeIni;

        const hours = Math.trunc(difTime / 3600);
        const minute = Math.trunc((difTime - 3600 * hours) / 60);
        const seconds = difTime - (3600 * hours + 60 * minute);

        return `${hours < 10 ? `0${hours}` : hours}:${
          minute < 10 ? `0${minute}` : minute
        }:${seconds < 10 ? `0${seconds}` : seconds}`;
      }
      const difTime = difTimeIni - difTimeEnd;

      const hours = Math.trunc(difTime / 3600);
      const minute = Math.trunc((difTime - 3600 * hours) / 60);
      const seconds = difTime - (3600 * hours + 60 * minute);

      return `- ${hours < 10 ? `0${hours}` : hours}:${
        minute < 10 ? `0${minute}` : minute
      }:${seconds < 10 ? `0${seconds}` : seconds}`;
    },
    [],
  );

  const handleDownloadPDF = useCallback(
    (dadosHistoricoParam: HistoricAmbUser[], type: string) => {
    const doc = new jsPDF(); // eslint-disable-line
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      doc.setFontType('bold');
      doc.text('Histórico do Ambiente', 103, 20, 'center');
      doc.setFontSize(14);
      // tamanho 207 tela a4
      let posY: number;
      posY = 30;
      const nameAmbiente =
        dadosHistoricoParam.length > 0
          ? dadosHistoricoParam[0].Ambiente.name
          : '';

      doc.text(`${nameAmbiente}`, 103, posY, {
        align: 'center',
        maxWidth: 175,
      });

      // inicio bloco de dados pessoais
      dadosHistoricoParam.forEach((res, index) => {
        index === 0 ? (posY += 28) : (posY += 20);

        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('Colaborador:', 10, posY, 'left');
        const names =
          res.User.nameUser !== ''
            ? `${res.User.name} - ${res.User.nameUser}`
            : `${res.User.name}`;
        let quebraLinha = doc.splitTextToSize(names, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 39, posY, { align: 'left', maxWidth: 175 });

        posY += 6;
        doc.setFontType('bold');
        doc.text('CPF:', 10, posY, 'left');
        quebraLinha = doc.splitTextToSize(`${maskCpf(res.User.cpf)}`, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 22, posY, { align: 'left', maxWidth: 175 });

        posY += 6;
        doc.setFontType('bold');
        doc.text('E-mail:', 10, posY, 'left');
        quebraLinha = doc.splitTextToSize(`${res.User.email}`, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 26, posY, { align: 'left', maxWidth: 175 });

        posY += 6;
        doc.setFontType('bold');
        doc.text('Telefone:', 10, posY, 'left');
        quebraLinha = doc.splitTextToSize(`${maskTel(res.User.telefone)}`, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 30, posY, { align: 'left', maxWidth: 175 });

        posY += 6;
        doc.setFontType('bold');
        doc.text('Horário base (agendado):', 10, posY, 'left');
        quebraLinha = doc.splitTextToSize(
          res.dataIniAgend && res.dataFimAgend
            ? `${res.dataIniAgend} a ${res.dataFimAgend}`
            : '-',
          70,
        );
        doc.setFontType('normal');
        doc.text(quebraLinha, 63, posY, { align: 'left', maxWidth: 175 });

        if (type === 'dadosHorarios' || type === 'detalhes') {
          const filterScheduledReviewsAux = res.Ambiente.revisoes
            .map(i => `${i.horaIni} - ${i.horaEnd}`)
            .join(', ');
          const filterScheduledReviewsAuxLength = filterScheduledReviewsAux.split(
            ',',
          ).length;

          posY += 6;
          doc.setFontType('bold');
          doc.text('Horário da revisão (agendado):', 10, posY, 'left');
          quebraLinha = doc.splitTextToSize(
            res.Ambiente.revisoes
              .map(i => `${i.horaIni} - ${i.horaEnd}`)
              .join(', '),
            70,
          );
          doc.setFontType('normal');
          // console.log(quebraLinha);
          doc.text(quebraLinha, 74, posY, { align: 'left', maxWidth: 130 });
          let posYAux;

          if (Math.ceil(filterScheduledReviewsAuxLength / 9) > 1) {
            posYAux = (filterScheduledReviewsAuxLength / 9) * 11;
          } else {
            posYAux = 6;
          }

          posY += posYAux;
          doc.setFontType('bold');
          doc.text('Data e hora inicial (realizado):', 10, posY, 'left');
          quebraLinha = doc.splitTextToSize(
            !res.dataIniDone
              ? '-'
              : `${moment(new Date(res.dataIniDone)).format(
                  'DD/MM/YYYY HH:mm:ss',
                )}`,
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 73, posY, { align: 'left', maxWidth: 175 });

          posY += 6;
          doc.setFontType('bold');
          doc.text('Data e hora final (realizado):', 10, posY, 'left');
          quebraLinha = doc.splitTextToSize(
            !res.dataFimDone
              ? '-'
              : `${moment(new Date(res.dataFimDone)).format(
                  'DD/MM/YYYY HH:mm:ss',
                )}`,
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 70, posY, { align: 'left', maxWidth: 175 });

          posY += 6;
          doc.setFontType('bold');
          doc.text('Tempo de execução (realizado):', 10, posY, 'left');
          quebraLinha = doc.splitTextToSize(
            !res.dataIniDone || !res.dataFimDone
              ? '-'
              : getDiffData(res.dataFimDone, res.dataIniDone),
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 77, posY, { align: 'left', maxWidth: 175 });

          posY += 6;
          doc.setFontType('bold');
          doc.text('Tempo estimado:', 10, posY, 'left');
          quebraLinha = doc.splitTextToSize(
            getDiffData(
              `2022-01-01 ${res.dataFimAgend}`,
              `2022-01-01 ${res.dataIniAgend}`,
            ),
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 47, posY, { align: 'left', maxWidth: 175 });

          posY += 6;
          doc.setFontType('bold');
          doc.text('Tempo otimizado:', 10, posY, 'left');
          quebraLinha = doc.splitTextToSize(
            !res.dataIniDone || !res.dataFimDone
              ? '-'
              : getDiffTimes(
                  {
                    timeEnd: res.dataFimDone,
                    timeInit: res.dataIniDone,
                  },
                  {
                    timeInit: `2022-01-01 ${res.dataFimAgend}`,
                    timeEnd: `2022-01-01 ${res.dataIniAgend}`,
                  },
                ),
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 48, posY, { align: 'left', maxWidth: 175 });
        }
        if (type === 'detalhes') {
          posY += 6;
          doc.setFontType('bold');
          doc.text('Procedimento:', 10, posY, 'left');
          quebraLinha = doc.splitTextToSize(
            `${!res.Task.name ? '-' : res.Task.name}`,
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 42, posY, { align: 'left', maxWidth: 175 });
        }
        if (type === 'dadosHorarios') {
          posY += 6;
          doc.setFontType('bold');
          doc.text('Procedimentos:', 10, posY, 'left');
          quebraLinha = doc.splitTextToSize(
            `${
              res.Ambiente.procedAll.length === 0
                ? '-'
                : res.Ambiente.procedAll.map(i => i.name).join(', ')
            }`,
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 45, posY, { align: 'left', maxWidth: 175 });
        }
        if (type === 'dadosHorarios' || type === 'detalhes') {
          posY += 6;
          doc.setFontType('bold');
          doc.text('Observação:', 10, posY, 'left');
          quebraLinha = doc.splitTextToSize(res.observacao, 70);
          doc.setFontType('normal');
          doc.text(quebraLinha, 38, posY, { align: 'left', maxWidth: 175 });

          if (type === 'detalhes') {
            posY += 6;
            doc.setFontType('bold');
            doc.text('Descrição:', 10, posY, 'left');
            quebraLinha = doc.splitTextToSize(
              `${!res.description ? '-' : res.description}`,
              70,
            );
            doc.setFontType('normal');
            doc.text(quebraLinha, 35, posY, { align: 'left', maxWidth: 175 });
          }

          posY += 6;
          doc.setFontType('bold');
          doc.text('Horário das revisões (realizado):', 10, posY, 'left');
          quebraLinha = doc.splitTextToSize(
            `${res.Ambiente.revisoes
              .map(i => `${i.horaIni} - ${i.horaEnd}`)
              .join(' ,')}`,
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 78, posY, { align: 'left', maxWidth: 120 });
        }

        if (posY > 207) {
          doc.addPage();
          posY = 0;
        }
      });

      doc.autoPrint();
      doc.output('dataurlnewwindow');
    },
    [getDiffData, getDiffTimes],
  );

  const responseData = useMemo(() => {
    // let computedResponses: ResponseGetHistoric[] = [];
    // let computedResponsesExcelPdf: ResponseGetHistoric[] = [];
    let computedResponses = dados;
    let computedResponsesExcelPdf = dados;

    computedResponses = [
      ...new Map(computedResponses.map(item => [item.User.id, item])).values(),
    ];
    computedResponsesExcelPdf = [
      ...new Map(
        computedResponsesExcelPdf.map(item => [item.User.id, item]),
      ).values(),
    ];

    if (search) {
      computedResponses = computedResponses.filter(res =>
        [res.User.name, res.User.nameUser, res.User.cpf].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        ),
      );

      computedResponsesExcelPdf = computedResponsesExcelPdf.filter(res =>
        [res.User.name, res.User.nameUser, res.User.cpf].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        ),
      );
    }

    setDadosHistoricoExcelPdf(computedResponsesExcelPdf);
    return computedResponses;
  }, [dados, search]);

  const responseDataDetalhes = useMemo(() => {
    let computedResponses = dados || [];
    let computedResponsesExcelPdf = dados || [];

    computedResponses = [
      ...new Map(
        computedResponses.map(item => [
          `${item.User.id}-${item.dataAgend}`,
          item,
        ]),
      ).values(),
    ];
    computedResponsesExcelPdf = [
      ...new Map(
        computedResponsesExcelPdf.map(item => [
          `${item.User.id}-${item.dataAgend}`,
          item,
        ]),
      ).values(),
    ];

    if (searchDatas) {
      computedResponses = computedResponses.filter(res => {
        const dataAgend = moment(new Date(res.dataAgend)).format(
          'DD/MM/YYYY HH:mm:ss',
        );
        // const dataFim = moment(new Date(res.dataFimDone)).format(
        //   'DD/MM/YYYY HH:mm:ss',
        // );

        return [dataAgend].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(searchDatas.toString().toLowerCase()),
        );
      });

      computedResponsesExcelPdf = computedResponsesExcelPdf.filter(res => {
        const dataAgend = moment(new Date(res.dataAgend)).format(
          'DD/MM/YYYY HH:mm:ss',
        );
        // const dataInicio = moment(new Date(res.dataIniDone)).format(
        //   'DD/MM/YYYY HH:mm:ss',
        // );
        // const dataFim = moment(new Date(res.dataFimDone)).format(
        //   'DD/MM/YYYY HH:mm:ss',
        // );

        return [dataAgend].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(searchDatas.toString().toLowerCase()),
        );
      });

      if (userResponseData) {
        computedResponsesExcelPdf = computedResponsesExcelPdf.filter(
          res => res.User.id === userResponseData,
        );
      }

      if (userResponseData) {
        computedResponsesExcelPdf = computedResponsesExcelPdf.filter(
          res => res.User.id === userResponseData,
        );
      }
    }

    setDetalhesHistoricoExcelPdf(computedResponsesExcelPdf);
    return computedResponses;
  }, [dados, searchDatas, userResponseData]);

  const responseDataProcedimentos = useMemo(() => {
    let computedResponses = dados || [];
    let computedResponsesExcelPdf = dados || [];

    if (dateCreated && userResponseData) {
      computedResponses = computedResponses.filter(
        i => i.dataAgend === dateCreated && i.User.id === userResponseData,
      );
    }

    if (searchProcedimento) {
      computedResponses = computedResponses.filter(res =>
        res.Task.name
          .toString()
          .toLowerCase()
          .includes(searchProcedimento.toLowerCase()),
      );
      computedResponsesExcelPdf = computedResponsesExcelPdf.filter(res =>
        res.Task.name
          .toString()
          .toLowerCase()
          .includes(searchProcedimento.toLowerCase()),
      );
    }
    setProcedimentosHistoricoExcelPdf(computedResponsesExcelPdf);
    return computedResponses;
  }, [dados, dateCreated, searchProcedimento, userResponseData]);

  const handleZoomImg = useCallback((imgString: string) => {
    setZoomImg('principal');
    setImg(imgString);
  }, []);

  const handleZoomImgRevisions = useCallback(
    (imgObjects: HistoricAmbUser[]) => {
      setZoomImg('revisoes');
      setImg(imgObjects);
    },
    [],
  );

  // const checkOutOfHours = useCallback(
  //   (
  //     horarioMarcadoInit,
  //     horarioRealizadoInit,
  //     horarioMarcadoFinal,
  //     horarioRealizadoFinal,
  //   ) => {
  //     if (
  //       horarioRealizadoInit < horarioMarcadoInit ||
  //       horarioRealizadoInit > horarioMarcadoFinal ||
  //       horarioRealizadoFinal < horarioMarcadoInit ||
  //       horarioRealizadoFinal > horarioMarcadoFinal
  //     ) {
  //       return true;
  //     }

  //     return false;
  //   },
  //   [],
  // );

  const onClose = useCallback(() => {
    setSearch('');
    setSearchDatas('');
    setSearchProcedimento('');
    setZoomImg('');
    setNewDetalhesHistorico([]);
    setExibirDetalhes(false);
    setExibirDetalhesData(false);
    onRequestClose();
  }, [onRequestClose]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ContainerModal>
        {!exibirDetalhes && !exibirDetalhesData && (
          <>
            <ContainerHeaderDados>Histórico do Ambiente</ContainerHeaderDados>

            <ContainerSearch>
              <Search
                onSearch={(value: string) => {
                  setSearch(value);
                }}
                nomePlaceHolder="Buscar"
              />

              <strong>{dadosHistoricoDatas}</strong>
            </ContainerSearch>
          </>
        )}
        {exibirDetalhes && (
          <>
            <ContainerHeaderDados>Datas do Histórico</ContainerHeaderDados>

            <ContainerSearch>
              <Search
                onSearch={(value: string) => {
                  setSearchDatas(value);
                }}
                nomePlaceHolder="Buscar datas"
              />

              <strong>{dadosHistoricoDatas}</strong>
            </ContainerSearch>
          </>
        )}
        {exibirDetalhesData && zoomImg === '' && (
          <>
            <ContainerHeaderDados>Detalhes do Histórico</ContainerHeaderDados>

            <ContainerSearch>
              <Search
                onSearch={(value: string) => {
                  setSearchProcedimento(value);
                }}
                nomePlaceHolder="Buscar procedimento"
              />

              <strong>{dadosHistoricoDatas}</strong>
            </ContainerSearch>
          </>
        )}

        {!exibirDetalhes && !exibirDetalhesData && (
          <ContainerHeader>
            {responseData.length !== 0 ? (
              <ContainerListCards>
                {responseData.map((res, index) => (
                  <ContainerMap
                    key={`${res.dataIniDone}-${res.dataFimDone}-${
                      res.User.id
                    }-${index.toString()}`}
                  >
                    <>
                      <ul>
                        <li>
                          <strong>Colaborador: </strong>
                          <span>
                            {!res.User.name ? '-' : res.User.name}
                            {!res.User.nameUser
                              ? ''
                              : ` - ${res.User.nameUser}`}
                          </span>
                        </li>
                        <li>
                          <strong>CPF: </strong>
                          <span>
                            {!res.User.cpf || res.User.cpf.trim() === ''
                              ? '-'
                              : maskCpf(res.User.cpf)}
                          </span>
                        </li>
                        <li>
                          <strong>E-mail: </strong>
                          <span>
                            {!res.User.email || res.User.email.trim() === ''
                              ? '-'
                              : res.User.email}
                          </span>
                        </li>
                        <li>
                          <strong>Telefone: </strong>
                          <span>
                            {!res.User.telefone ||
                            res.User.telefone.trim() === ''
                              ? '-'
                              : maskTel(res.User.telefone)}
                          </span>
                        </li>
                      </ul>

                      <article>
                        <IconeAtrasado>
                          {!!res.observacao && (
                            <FiAlertCircle
                              size={30}
                              style={{ cursor: 'default' }}
                            />
                          )}
                        </IconeAtrasado>

                        <ButtonDetalhes
                          type="button"
                          onClick={() => [
                            // setDadosHistoricoDataInicio([]),
                            // setDadosHistoricoDataFim([]),
                            // setNewDetalhesHistoricoData([]),
                            // handleButtonDate(res),
                            setUserResponseData(res.User.id),
                            // setNewDetalhesHistorico([res]),
                            setExibirDetalhes(true),
                          ]}
                        >
                          <IoIosArrowForward />
                        </ButtonDetalhes>
                      </article>
                    </>
                  </ContainerMap>
                ))}
              </ContainerListCards>
            ) : (
              <HistoricoVazio>
                <p>Dados não encontrados para o ambiente atual</p>
              </HistoricoVazio>
            )}
          </ContainerHeader>
        )}

        {exibirDetalhes && (
          <ContainerHeader>
            <ContainerListCards>
              {responseDataDetalhes.map((res, index) => (
                <ContainerMapDetails
                  key={`${index.toString()}-${res.dataIniAgend}-${
                    res.dataFimAgend
                  }`}
                >
                  <ul>
                    <li>
                      <strong>Colaborador: </strong>
                      <span>
                        {!res.User.name ? '-' : res.User.name}
                        {!res.User.nameUser ? '' : ` - ${res.User.nameUser}`}
                      </span>
                    </li>
                    <li>
                      <strong>CPF: </strong>
                      <span>
                        {!res.User.cpf || res.User.cpf.trim() === ''
                          ? '-'
                          : maskCpf(res.User.cpf)}
                      </span>
                    </li>
                    <li>
                      <strong>E-mail: </strong>
                      <span>{!res.User.email ? '-' : res.User.email}</span>
                    </li>
                    <li>
                      <strong>Telefone: </strong>
                      <span>
                        {!res.User.telefone ? '-' : maskTel(res.User.telefone)}
                      </span>
                    </li>
                    <li>
                      <strong>Data de Agendamento</strong>
                      <span>
                        {!res.dataAgend
                          ? '-'
                          : moment(new Date(res.dataAgend)).format(
                              'DD/MM/YYYY',
                            )}
                      </span>
                    </li>
                    <li>
                      <strong>Data e hora inicial (realizado):</strong>
                      <span>
                        {!res.dataIniDone
                          ? '-'
                          : moment(new Date(res.dataIniDone)).format(
                              'DD/MM/YYYY HH:mm:ss',
                            )}
                      </span>
                    </li>
                    <li>
                      <strong>Data e hora final (realizado):</strong>
                      <span>
                        {!res.dataFimDone
                          ? '-'
                          : moment(new Date(res.dataFimDone)).format(
                              'DD/MM/YYYY HH:mm:ss',
                            )}
                      </span>
                    </li>
                  </ul>

                  <article>
                    <IconeAtrasado>
                      {!!res.observacao && (
                        <FiAlertCircle onClick={() => onButtonClick(res)} />
                      )}
                    </IconeAtrasado>

                    <ButtonDetalhes
                      type="button"
                      onClick={() => [
                        setDateCreated(res.dataAgend),
                        setExibirDetalhes(false),
                        setExibirDetalhesData(true),
                      ]}
                    >
                      <IoIosArrowForward />
                    </ButtonDetalhes>
                  </article>
                </ContainerMapDetails>
              ))}
            </ContainerListCards>
          </ContainerHeader>
        )}

        {exibirDetalhesData &&
          zoomImg === '' &&
          responseDataProcedimentos !== undefined && (
            <ContainerHeader>
              <ContainerListCards>
                {responseDataProcedimentos
                  .filter(i => i.type === 'Principal')
                  .map(res => (
                    <ContainerMapDetails key={uuid()}>
                      {!res.imagem || res.imagem.split('/').pop() === 'null' ? (
                        <ButtonImgNull type="button">
                          <AiOutlineException size={90} />
                        </ButtonImgNull>
                      ) : (
                        <ButtonImg
                          type="button"
                          onClick={() => handleZoomImg(res.imagem)}
                        >
                          <img
                            src={res.imagem}
                            alt={!res.Task.name ? '-' : res.Task.name}
                          />
                        </ButtonImg>
                      )}

                      <ul>
                        <li>
                          <strong>Colaborador: </strong>
                          <span>
                            {!res.User.name ? '-' : res.User.name}
                            {!res.User.nameUser
                              ? ''
                              : ` - ${res.User.nameUser}`}
                          </span>
                        </li>
                        <li>
                          <strong>CPF: </strong>
                          <span>
                            {!res.User.cpf || res.User.cpf.trim() === ''
                              ? '-'
                              : maskCpf(res.User.cpf)}
                          </span>
                        </li>
                        <li>
                          <strong>E-mail: </strong>
                          <span>{!res.User.email ? '-' : res.User.email}</span>
                        </li>
                        <li>
                          <strong>Telefone: </strong>
                          <span>
                            {!res.User.telefone
                              ? '-'
                              : maskTel(res.User.telefone)}
                          </span>
                        </li>
                        <li>
                          <strong>Horário base (agendado):</strong>
                          <span>
                            {!res.dataIniAgend || !res.dataFimAgend
                              ? '-'
                              : `${res.dataIniAgend} - ${res.dataFimAgend}`}
                          </span>
                        </li>
                        <li>
                          <strong>Horário da revisão (agendado):</strong>
                          <span>
                            {res.Ambiente.revisoes
                              .map(i => `${i.horaIni} - ${i.horaEnd}`)
                              .join(' ,')}
                          </span>
                        </li>
                        <li>
                          <strong>Data e hora inicial (realizado):</strong>
                          <span>
                            {!res.dataIniDone
                              ? '-'
                              : moment(new Date(res.dataIniDone)).format(
                                  'DD/MM/YYYY HH:mm:ss',
                                )}
                          </span>
                        </li>
                        <li>
                          <strong>Data e hora final (realizado):</strong>
                          <span>
                            {!res.dataFimDone
                              ? '-'
                              : moment(new Date(res.dataFimDone)).format(
                                  'DD/MM/YYYY HH:mm:ss',
                                )}
                          </span>
                        </li>
                        <li>
                          <strong>Procedimento: </strong>
                          <span>{!res.Task.name ? '-' : res.Task.name}</span>
                        </li>
                        <li>
                          <strong>Observação: </strong>
                          <span>{res.observacao}</span>
                        </li>
                        <li>
                          <strong>Descrição: </strong>
                          <span>{res.description}</span>
                        </li>
                        <li>
                          <strong>Revisões (realizado): </strong>
                          <Link
                            to="#"
                            onClick={() => {
                              handleZoomImgRevisions(
                                responseDataProcedimentos.filter(
                                  i => i.type !== 'Principal',
                                ),
                              );
                            }}
                          >
                            Mostrar revisões
                          </Link>
                        </li>
                      </ul>
                    </ContainerMapDetails>
                  ))}
              </ContainerListCards>
            </ContainerHeader>
          )}

        {zoomImg === 'principal' && (
          <ZoomImg>
            <Zoom img={img} zoomScale={1} width="100%" height={380} />
          </ZoomImg>
        )}
        {zoomImg === 'revisoes' && (
          <ZoomImg>
            {img &&
              typeof img === 'object' &&
              img.map(imgRevision => (
                <section key={imgRevision.imagem}>
                  <span style={{ fontWeight: 500 }}>
                    {imgRevision.Task.name}
                  </span>
                  <article style={{ marginTop: -30, marginBottom: -23 }}>
                    <span>
                      {imgRevision.dataIniAgend} ás {imgRevision.dataFimAgend}
                    </span>
                  </article>

                  <span>{`Observação: ${imgRevision.observacao}`}</span>

                  {imgRevision.imagem ? (
                    <Zoom
                      img={imgRevision.imagem}
                      zoomScale={1}
                      width="100%"
                      height={380}
                    />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <AiOutlineException size={80} />
                    </div>
                  )}
                </section>
              ))}

            {img && !img.length && <h1>Histórico de revisões vazio</h1>}
          </ZoomImg>
        )}

        {!exibirDetalhes && !exibirDetalhesData && (
          <>
            <ContainerButtonAtualizar>
              <Button
                type="button"
                onClick={() => {
                  setSearch('');
                  setSearchDatas('');
                  setSearchProcedimento('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  setExibirDetalhesData(false);
                  onRequestClose();
                }}
              >
                Ok
              </Button>
            </ContainerButtonAtualizar>

            <ContainerButtonsPdfExcel>
              <button
                type="button"
                id="pdf"
                onClick={() => {
                  // console.log('ver');
                  handleDownloadPDF(responseData, 'dados');
                }}
              >
                <VscFilePdf size={22} />
              </button>

              <ReactHTMLTableToExcel
                type="button"
                id="excel"
                table="tableListagemDadosAmbientes"
                filename={`relatorio-ambiente-${moment().format('DD-MM-YYYY')}`}
                sheet="tablexls"
                buttonText={<RiFileExcel2Line size={22} />}
              />
            </ContainerButtonsPdfExcel>
          </>
        )}

        {exibirDetalhes && (
          <>
            <ContainerButtonDetalhes>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setSearch('');
                  setSearchDatas('');
                  setZoomImg('');
                  setSearchProcedimento('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  setExibirDetalhesData(false);
                  onRequestClose();
                }}
              >
                Ok
              </Button>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setExibirDetalhes(false);
                  setSearch('');
                  setSearchDatas('');
                  setSearchProcedimento('');
                  setZoomImg('');
                }}
              >
                Voltar
              </Button>
            </ContainerButtonDetalhes>

            <ContainerButtonsPdfExcel>
              <button
                type="button"
                id="pdf"
                onClick={() => {
                  handleDownloadPDF(responseDataDetalhes, 'dadosHorarios');
                }}
              >
                <VscFilePdf size={22} />
              </button>

              <ReactHTMLTableToExcel
                type="button"
                id="excel"
                table="tableListagemDetalhesAmbientes"
                filename={`relatorio-ambiente-${moment().format('DD-MM-YYYY')}`}
                sheet="tablexls"
                buttonText={<RiFileExcel2Line size={22} />}
              />
            </ContainerButtonsPdfExcel>
          </>
        )}

        {exibirDetalhesData && zoomImg === '' && (
          <>
            <ContainerButtonDetalhes>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setSearch('');
                  setSearchDatas('');
                  setZoomImg('');
                  setSearchProcedimento('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  setExibirDetalhesData(false);
                  onRequestClose();
                }}
              >
                Ok
              </Button>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setExibirDetalhesData(false);
                  setExibirDetalhes(true);
                  setSearch('');
                  setSearchDatas('');
                  setSearchProcedimento('');
                  setZoomImg('');
                }}
              >
                Voltar
              </Button>
            </ContainerButtonDetalhes>

            <ContainerButtonsPdfExcel>
              <button
                type="button"
                id="pdf"
                onClick={() => {
                  handleDownloadPDF(responseDataProcedimentos, 'detalhes');
                }}
              >
                <VscFilePdf size={22} />
              </button>

              <ReactHTMLTableToExcel
                type="button"
                id="excel"
                table="tableListagemProcedimentosAmbientes"
                filename={`relatorio-ambiente-${moment().format('DD-MM-YYYY')}`}
                sheet="tablexls"
                buttonText={<RiFileExcel2Line size={22} />}
              />
            </ContainerButtonsPdfExcel>
          </>
        )}

        {zoomImg !== '' && (
          <>
            <ContainerButtonDetalhes>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setSearch('');
                  setSearchDatas('');
                  setZoomImg('');
                  setSearchProcedimento('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  setExibirDetalhesData(false);
                  onRequestClose();
                }}
              >
                Ok
              </Button>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setZoomImg('');
                }}
              >
                Voltar
              </Button>
            </ContainerButtonDetalhes>

            {zoomImg === 'revisoes' && (
              <ContainerButtonsPdfExcel>
                <ReactHTMLTableToExcel
                  type="button"
                  id="excel"
                  table="tableListagemDetalhesRevisoesAmbientes"
                  filename={`relatorio-revisoes-ambiente-${moment().format(
                    'DD-MM-YYYY',
                  )}`}
                  sheet="tablexls"
                  buttonText={<RiFileExcel2Line size={22} />}
                />
              </ContainerButtonsPdfExcel>
            )}
          </>
        )}
      </ContainerModal>

      <ContainerTableMacon>
        <table id="tableListagemDadosAmbientes">
          <HeaderTable headers={headers} />
          <tbody>
            {dadosHistoricoExcelPdf !== undefined &&
              dadosHistoricoExcelPdf.map(res => (
                <tr key={uuid()}>
                  <td>{!res.Ambiente.name ? '-' : res.Ambiente.name}</td>
                  <td>
                    {!res.User.name ? '-' : res.User.name}
                    {!res.User.nameUser ? '' : ` - ${res.User.nameUser}`}
                  </td>
                  <td>{!res.User.cpf ? '-' : maskCpf(res.User.cpf)}</td>
                  <td>{!res.User.email ? '-' : res.User.email}</td>
                  <td>
                    {!res.User.telefone ? '-' : maskTel(res.User.telefone)}
                  </td>
                  <td>
                    {!res.dataIniAgend && !res.dataFimAgend
                      ? '-'
                      : `${res.dataIniAgend} - ${res.dataFimAgend}`}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </ContainerTableMacon>

      <ContainerTableMacon>
        <table id="tableListagemDetalhesAmbientes">
          <HeaderTable headers={headersHorarios} />
          <tbody>
            {detalhesHistoricoExcelPdf !== undefined &&
              detalhesHistoricoExcelPdf.map(res => (
                <tr key={uuid()}>
                  <td>{!res.Ambiente.name ? '-' : res.Ambiente.name}</td>
                  <td>
                    {!res.User.name ? '-' : res.User.name}
                    {!res.User.nameUser ? '' : ` - ${res.User.nameUser}`}
                  </td>
                  <td>{!res.User.cpf ? '-' : maskCpf(res.User.cpf)}</td>
                  <td>{!res.User.email ? '-' : res.User.email}</td>
                  <td>
                    {!res.User.telefone ? '-' : maskTel(res.User.telefone)}
                  </td>
                  <td>
                    {!res.dataIniAgend && !res.dataFimAgend
                      ? '-'
                      : `${res.dataIniAgend} - ${res.dataFimAgend}`}
                  </td>
                  <td>
                    {!res.dataIniDone
                      ? '-'
                      : moment(new Date(res.dataIniDone)).format(
                          'DD/MM/YYYY HH:mm:ss',
                        )}
                  </td>
                  <td>
                    {!res.dataFimDone
                      ? '-'
                      : moment(new Date(res.dataFimDone)).format(
                          'DD/MM/YYYY HH:mm:ss',
                        )}
                  </td>
                  <td>{res.observacao}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </ContainerTableMacon>

      <ContainerTableMacon>
        <table id="tableListagemProcedimentosAmbientes">
          <HeaderTable headers={headersRevisions} />
          <tbody>
            {procedimentosHistoricoExcelPdf !== undefined &&
              procedimentosHistoricoExcelPdf.map(res => (
                <tr key={uuid()}>
                  <td>{!res.Ambiente.name ? '-' : res.Ambiente.name}</td>
                  <td>{!res.User.name ? '-' : res.User.name}</td>
                  <td>{!res.User.nameUser ? '-' : res.User.nameUser}</td>
                  <td>{!res.User.cpf ? '-' : maskCpf(res.User.cpf)}</td>
                  <td>{!res.User.email ? '-' : res.User.email}</td>
                  <td>
                    {!res.User.telefone ? '-' : maskTel(res.User.telefone)}
                  </td>
                  <td>
                    {!res.dataIniAgend && !res.dataFimAgend
                      ? '-'
                      : `${res.dataIniAgend} - ${res.dataFimAgend}`}
                  </td>
                  <td>
                    {res.Ambiente.revisoes
                      .map(i => `${i.horaIni} - ${i.horaEnd}`)
                      .join(', ')}
                  </td>
                  <td>
                    {!res.dataIniDone
                      ? '-'
                      : moment(new Date(res.dataIniDone)).format(
                          'DD/MM/YYYY HH:mm:ss',
                        )}
                  </td>
                  <td>
                    {!res.dataFimDone
                      ? '-'
                      : moment(new Date(res.dataFimDone)).format(
                          'DD/MM/YYYY HH:mm:ss',
                        )}
                  </td>
                  <td>{!res.Task.name ? '-' : res.Task.name}</td>
                  <td>{res.observacao}</td>
                  <td>{res.description}</td>
                  <td>
                    {res.Ambiente.revisoes
                      .map(i => `${i.horaIni} - ${i.horaEnd}`)
                      .join(' ,')}
                  </td>
                  <td>
                    {!res.imagem || res.imagem.split('/').pop() === 'null' ? (
                      'Sem Foto'
                    ) : (
                      <a href={!res.imagem ? '-' : res.imagem}>Ir para Foto</a>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </ContainerTableMacon>

      <ContainerTableMacon>
        <table id="tableListagemDetalhesRevisoesAmbientes">
          <HeaderTable headers={headersRevisions} />
          <tbody>
            {responseDataProcedimentos !== undefined &&
              responseDataProcedimentos
                .filter(i => i.type !== 'Principal')
                .map(res => (
                  <tr key={uuid()}>
                    <td>{!res.Ambiente.name ? '-' : res.Ambiente.name}</td>
                    <td>{!res.User.name ? '-' : res.User.name}</td>
                    <td>{!res.User.nameUser ? '-' : res.User.nameUser}</td>
                    <td>{!res.User.cpf ? '-' : maskCpf(res.User.cpf)}</td>
                    <td>{!res.User.email ? '-' : res.User.email}</td>
                    <td>
                      {!res.User.telefone ? '-' : maskTel(res.User.telefone)}
                    </td>
                    <td>
                      {!res.dataIniAgend && !res.dataFimAgend
                        ? '-'
                        : `${res.dataIniAgend} - ${res.dataFimAgend}`}
                    </td>
                    <td>
                      {res.Ambiente.revisoes
                        .map(i => `${i.horaIni} - ${i.horaEnd}`)
                        .join(', ')}
                    </td>
                    <td>
                      {!res.dataIniDone
                        ? '-'
                        : moment(new Date(res.dataIniDone)).format(
                            'DD/MM/YYYY HH:mm:ss',
                          )}
                    </td>
                    <td>
                      {!res.dataFimDone
                        ? '-'
                        : moment(new Date(res.dataFimDone)).format(
                            'DD/MM/YYYY HH:mm:ss',
                          )}
                    </td>
                    <td>{!res.Task.name ? '-' : res.Task.name}</td>
                    <td>{res.observacao}</td>
                    <td>
                      {res.Ambiente.revisoes
                        .map(i => `${i.horaIni} - ${i.horaEnd}`)
                        .join(' ,')}
                    </td>
                    <td>
                      {!res.imagem || res.imagem.split('/').pop() === 'null' ? (
                        'Sem Foto'
                      ) : (
                        <a href={!res.imagem ? '-' : res.imagem}>
                          Ir para Foto
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </ContainerTableMacon>
    </Modal>
  );
};
