/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { uuid } from 'uuidv4';
import filesize from 'filesize';
import { Loading } from '../../../components/Loading';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import { InputOverview } from '../../../components/InputOverview';
import ModalConexao from '../../../components/ModalConexao';
import { useAuth } from '../../../hooks/auth';
import avatar from '../../../assets/IconAvatar.svg';
import {
  maskTel,
  maskDateOfBirth,
  maskCpf,
} from '../../../components/InputOverview/mask';
import Button from '../../../components/Button';
import { UploadImg } from '../../../components/UploadImg';
import { FileListImg } from '../../../components/FileListImg';

import { Container, Content } from './styles';

interface SignUpFormData {
  cpf: string;
  nome: string;
  email: string;
  dataNasimento: string;
  telefone: string;
  passwordOld: string;
  password: string;
  passwordConfirm: string;
  empresaPrincipal: string;
  nivelAcesso: string;
}

interface Uploaded {
  file: File | null;
  id: string;
  name: string;
  readableSize: string;
  preview: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  url: string;
}

const Profile: React.FC = () => {
  const { user, empresaPrincipal, updateUser } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [nome] = useState(user.nome);
  const [cpf] = useState(user.cpf);
  const [email, setEmail] = useState(user.email);
  const [telefone, setTelefone] = useState(user.telefone);
  const [dateNascimento] = useState(user.dataNascimento);

  const [uploadedFile, setUploadedFile] = useState<Uploaded>();

  const handleAvatarChange = useCallback(
    dataUser => {
      if (uploadedFile && uploadedFile.file) {
        setLoading(true);

        const data = new FormData();
        data.append('avatar', uploadedFile.file);
        data.append('user_id', user.id);
        api
          .patch('/users/avatar', data)
          .then(response => {
            setLoading(false);

            updateUser(response.data.user);
            addToast({
              type: 'success',
              title: 'Dados atualizados!',
            });
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro ao atualizar dados!',
            });
          });
      } else {
        setLoading(false);

        updateUser(dataUser);
        addToast({
          type: 'success',
          title: 'Dados atualizados!',
        });
      }
    },
    [addToast, updateUser, uploadedFile, user.id],
  );

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um email válido'),
          dataNascimento: Yup.string().required(
            'Digite sua data de nascimento',
          ),
          telefone: Yup.string().required('Telefone obrigatório'),
        });

        if (data.passwordOld.length === 0 && data.password.length !== 0) {
          if (data.password.length <= 5)
            formRef.current?.setErrors({
              passwordOld: 'Senha antiga é requirida',
              password: 'São no mínimo 6 dígitos ou caracteres',
            });
          else
            formRef.current?.setErrors({
              passwordOld: 'Senha antiga é requirida',
            });
          throw new Error();
        }

        if (data.passwordOld.length <= 5 && data.password.length !== 0) {
          if (data.password.length <= 5)
            formRef.current?.setErrors({
              passwordOld: 'São no mínimo 6 dígitos ou caracteres',
              password: 'São no mínimo 6 dígitos ou caracteres',
            });
          else
            formRef.current?.setErrors({
              passwordOld: 'São no mínimo 6 dígitos ou caracteres',
            });
          throw new Error();
        }

        if (data.passwordOld.length > 5 && data.password.length <= 5) {
          formRef.current?.setErrors({
            password: 'São no mínimo 6 dígitos ou caracteres',
          });
          throw new Error();
        }

        if (
          data.passwordOld.length > 5 &&
          data.password.length > 5 &&
          data.passwordConfirm.length === 0
        ) {
          formRef.current?.setErrors({
            passwordConfirm: 'Confirmação da senha é requirida',
          });
          throw new Error();
        } else if (
          data.passwordOld.length > 5 &&
          data.password.length > 5 &&
          data.passwordConfirm.length !== 0
        ) {
          if (data.passwordConfirm.length <= 5) {
            formRef.current?.setErrors({
              passwordConfirm: 'São no mínimo 6 dígitos ou caracteres',
            });
            throw new Error();
          }
        }

        await schema.validate(data, {
          abortEarly: false,
        });

        const { passwordOld, password, passwordConfirm } = data;

        if (password && passwordConfirm && passwordOld) {
          const post = {
            userId: user.id,
            nome: data.nome.trim(),
            telefone: data.telefone.trim(),
            ativo: true,
            password,
          };
          await api
            .put('users', post)
            .then(response => {
              handleAvatarChange(response.data.user);
            })
            .catch(() => {
              setLoading(false);
              addToast({
                type: 'error',
                title: 'Erro ao atualizar dados!',
              });
            });
        } else {
          await api
            .put('/users/update', {
              nome: data.nome.trim(),
              email: data.email.trim(),
              telefone: data.telefone.trim(),
            })
            .then(response => {
              handleAvatarChange(response.data.user);
            })
            .catch(() => {
              setLoading(false);
              addToast({
                type: 'error',
                title: 'Erro ao atualizar dados!',
              });
            });
        }
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar',
          description:
            'Ocorreu um erro ao atualizar os dados, por favor verifique os campos.',
        });
      }
    },
    [addToast, handleAvatarChange, user.id],
  );

  const handleUpload = useCallback(file => {
    const uploadedFileHandleUpload: Uploaded = {
      file: file[0],
      id: uuid(),
      name: file[0].name,
      readableSize: filesize(file[0].size),
      preview: URL.createObjectURL(file[0]),
      progress: 0,
      uploaded: false,
      error: false,
      url: '',
    };

    setUploadedFile(uploadedFileHandleUpload);
  }, []);

  const getNivel = useMemo((): string => {
    switch (user.roleId) {
      case 1:
        return 'Admin. Principal';
      case 2:
        return 'Administrador';
      case 3:
        return 'Coordenador';
      case 4:
        return 'Auditor';
      default:
        return 'Sem nível de acesso';
    }
  }, [user.roleId]);

  return (
    <>
      <ModalConexao />

      <Container>
        <h1>Meu perfil</h1>

        <Content>
          <section>
            <img
              src={user && user.avatar_url ? user.avatar_url : avatar}
              alt="avatar"
            />

            <article>
              {uploadedFile === undefined && (
                <UploadImg onUpload={(e: any) => handleUpload(e)} />
              )}

              {!!uploadedFile && (
                <FileListImg
                  file={uploadedFile}
                  onDelete={() => setUploadedFile(undefined)}
                />
              )}
            </article>
          </section>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <section>
              <article>
                <p>Nome completo</p>
                <InputOverview
                  name="nome"
                  placeholder="João Oliveira"
                  value={nome}
                  disabled
                />
              </article>

              <article>
                <p>CPF</p>
                <InputOverview
                  name="cpf"
                  placeholder="000.000.000-00"
                  value={maskCpf(cpf)}
                  disabled
                />
              </article>

              <article>
                <p>E-mail</p>
                <InputOverview
                  name="email"
                  placeholder="joaooliveira@gmail.com"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </article>

              <article>
                <p>Data de nascimento</p>
                <InputOverview
                  name="dataNascimento"
                  placeholder="01/01/1999"
                  value={maskDateOfBirth(dateNascimento)}
                  disabled
                />
              </article>

              <article>
                <p>Telefone</p>
                <InputOverview
                  name="telefone"
                  placeholder="(00) 0 0000-0000"
                  mask="phone"
                  value={maskTel(telefone)}
                  onChange={e => setTelefone(e.target.value)}
                />
              </article>

              <article>
                <p>Empresa principal</p>
                <InputOverview
                  name="empresaPrincipal"
                  placeholder="Empresa principal"
                  value={
                    empresaPrincipal.length
                      ? empresaPrincipal[0].NOME
                      : 'Todas as empresas'
                  }
                  disabled
                />
              </article>

              <article>
                <p>Nível de acesso</p>
                <InputOverview
                  name="nivelAcesso"
                  placeholder="Nível de acesso"
                  value={getNivel}
                  disabled
                />
              </article>
            </section>

            <section>
              <article>
                <p>Senha atual</p>
                <InputOverview name="passwordOld" placeholder="********" />
              </article>

              <article>
                <p>Nova senha</p>
                <InputOverview name="password" placeholder="********" />
              </article>

              <article>
                <p>Confirmar senha</p>
                <InputOverview name="passwordConfirm" placeholder="********" />
              </article>
            </section>

            <aside>
              <Button type="submit">Salvar alterações</Button>
            </aside>
          </Form>
        </Content>

        {loading && <Loading />}
      </Container>
    </>
  );
};

export default Profile;
