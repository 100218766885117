import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import Search from '../../../components/Search';
import ModalConexao from '../../../components/ModalConexao';
import api from '../../../services/api';
import Pagination from '../../../components/Pagination';
import HeaderTable from '../../../components/HeaderTable';
import { InputOverview } from '../../../components/InputOverview';
import Button from '../../../components/Button';
import { maskCnpj } from '../../../components/InputOverview/mask';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import getValidationErrors from '../../../utils/getValidationErrors';
import { SelectCustom } from '../../../components/SelectCustom';
import IconEdit from '../../../assets/IconEdit.svg';
import DropDownPagination from '../../../components/DropDownPagination';

import {
  Container,
  Aba,
  ContainerAba,
  AnimationContainer,
  ContainerButton,
  ContainerTable,
  ContainerPagination,
  ContainerSemResultados,
} from './styles';

interface SignUpFormData {
  nome: string;
  idEmpresa: number;
  endereco: string;
  numero: number;
  cnpj: string;
}

interface ResponseGet {
  ID: number;
  NOME: string;
  Id_Empresas: number;
  ENDERECO: string;
  NUMERO: number;
  CNPJ: string;
  ATIVO: boolean;
}

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '40', id: '40' },
  { valor: '80', id: '80' },
  { valor: '160', id: '160' },
  { valor: 'Todos', id: '1' },
];

const headers = [
  { name: 'ID', field: 'ID', sortable: true },
  { name: 'Nome', field: 'NOME', sortable: true },
  { name: 'CNPJ', field: 'CNPJ', sortable: true },
  { name: 'Endereço', field: 'ENDERECO', sortable: true },
  { name: 'Número', field: 'NUMERO', sortable: true },
  { name: 'Status', field: 'inativar', sortable: false },
  { name: 'Editar', field: 'editar', sortable: false },
];

const headersViewer = [
  { name: 'ID', field: 'ID', sortable: true },
  { name: 'Nome', field: 'NOME', sortable: true },
  { name: 'CNPJ', field: 'CNPJ', sortable: true },
  { name: 'Endereço', field: 'ENDERECO', sortable: true },
  { name: 'Número', field: 'NUMERO', sortable: true },
  { name: 'Status', field: 'inativar', sortable: false },
];

export const Filiais: React.FC = () => {
  const { user, cpfUserMaster, empresaPrincipal } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [cadastrar, setCadastrar] = useState(true);

  const [search, setSearch] = useState('');

  const [editar, setEditar] = useState(false);
  const [nomeFilialEdit, setNomeFilialEdit] = useState('');
  const [idEmpresaFilialEdit, setIdEmpresaFilialEdit] = useState<number>();
  const [enderecoFilialEdit, setEnderecoFilialEdit] = useState('');
  const [numeroFilialEdit, setNumeroFilialEdit] = useState<number>();
  const [cnpjFilialEdit, setCnpjFilialEdit] = useState('');
  const [statusFilialEdit, setStatusFilialEdit] = useState<boolean>();
  const [filialEdit, setFilialEdit] = useState<ResponseGet>();
  const [verificaResponse, setVerificaResponse] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);
  const [sorting, setSorting] = useState({ field: '', order: '' });

  const getItems = useCallback(() => {
    setLoading(true);
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
    api
      .get(`empresas/filiais?companyId=${companyId}`)
      .then(data => {
        setResponse(data.data);
        setVerificaResponse(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);

        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, por favor atualize a página',
        });
      });
  }, [addToast, empresaPrincipal]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome é obrigatório'),
          cnpj: Yup.string().required('CNPJ é obrigatório'),
          endereco: Yup.string().required('Endereço é obrigatório'),
          numero: Yup.string().required('Número é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let verificaFilialDuplicada = false;
        response.forEach(elementTemp => {
          if (elementTemp.NOME.toLowerCase() === data.nome.toLowerCase())
            verificaFilialDuplicada = true;
        });

        if (!verificaFilialDuplicada) {
          await api
            .post('empresas/saveEmpresaFilial', {
              NOME: data.nome,
              Id_Empresas: data.idEmpresa,
              ENDERECO: data.endereco,
              NUMERO: data.numero,
              CNPJ: data.cnpj,
            })
            .then(() => {
              setLoading(false);

              addToast({
                type: 'success',
                title: 'Sucesso',
                description: 'Filial cadastrada com sucesso',
              });

              setTimeout(() => {
                history.go(0);
              }, 1000);
            })
            .catch(() => {
              setLoading(false);

              addToast({
                type: 'error',
                title: 'Erro',
                description: 'Erro ao cadastrar filial',
              });
            });
        } else {
          setLoading(false);

          addToast({
            type: 'info',
            title: 'Filial duplicada',
            description: 'Já existe uma filial cadastrada com o mesmo nome',
          });
        }
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Campos em branco',
            description: 'Existem campos obrigatórios não preenchidos.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao cadastrar o dados',
          });
        }
      }
    },
    [addToast, history, response],
  );

  const handleSubmitEditar = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nomeEdit: Yup.string().required('Nome é obrigatório'),
          cnpjEdit: Yup.string().required('CNPJ é obrigatório'),
          enderecoEdit: Yup.string().required('Endereço é obrigatório'),
          numeroEdit: Yup.string().required('Número é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let verificaFilialDuplicada = false;
        response.forEach(elementTemp => {
          if (
            elementTemp.NOME.toLowerCase() === nomeFilialEdit.toLowerCase() &&
            elementTemp.ID !== filialEdit?.ID
          )
            verificaFilialDuplicada = true;
        });

        if (!verificaFilialDuplicada) {
          await api
            .put('empresas/updateEmpresaFilial', {
              ID: filialEdit?.ID,
              NOME: nomeFilialEdit,
              Id_Empresas: idEmpresaFilialEdit,
              ENDERECO: enderecoFilialEdit,
              NUMERO: numeroFilialEdit,
              CNPJ: cnpjFilialEdit,
              ATIVO: statusFilialEdit,
            })
            .then(() => {
              setLoading(false);

              addToast({
                type: 'success',
                title: 'Sucesso',
                description: 'Filial salva com sucesso',
              });

              setTimeout(() => {
                history.go(0);
              }, 1000);
            })
            .catch(() => {
              setLoading(false);

              addToast({
                type: 'error',
                title: 'Erro',
                description: 'Erro ao salvar filial',
              });
            });
        } else {
          setLoading(false);

          addToast({
            type: 'info',
            title: 'Filial duplicada',
            description: 'Já existe uma filial cadastrada com o mesmo nome',
          });
        }
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Campos em branco',
            description: 'Existem campos obrigatórios não preenchidos.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao editar o dados',
          });
        }
      }
    },
    [
      addToast,
      cnpjFilialEdit,
      enderecoFilialEdit,
      filialEdit,
      history,
      idEmpresaFilialEdit,
      nomeFilialEdit,
      numeroFilialEdit,
      response,
      statusFilialEdit,
    ],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response;

    if (search) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        [res.NOME, res.CNPJ, res.ENDERECO, res.NUMERO].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        ),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (typeof a[sorting.field] === 'object' && a[sorting.field] != null) {
          return (
            reversed *
            a[sorting.field]
              .join(', ')
              .localeCompare(b[sorting.field].join(', '))
          );
        }
        if (typeof a[sorting.field] === 'string') {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        }

        const aTemp = a[sorting.field] != null ? a[sorting.field] : '';
        const bTemp = b[sorting.field] != null ? b[sorting.field] : '';
        return reversed * aTemp.toString().localeCompare(bTemp.toString());
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    search,
    sorting.field,
    sorting.order,
    ITEMS_PER_PAGE,
    currentPage,
  ]);

  const handlePermitionRegister = useCallback(() => {
    if (verificaResponse) {
      setCadastrar(false);
      setSearch('');
    }
  }, [verificaResponse]);

  return (
    <>
      <ModalConexao />

      <Container>
        {empresaPrincipal.length > 0 ? (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button type="button" onClick={() => setCadastrar(true)}>
                Listar filiais
              </button>
            </ContainerAba>
            <ContainerAba className="aba1" cor={!cadastrar}>
              <button type="button" onClick={handlePermitionRegister}>
                Cadastrar filial
              </button>
            </ContainerAba>

            <Tooltip
              title="Nessa página você tem acesso a visualização, cadastro e edição de todas as filiais que fazem parte do seu grupo empresarial"
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        ) : (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button type="button">Listar filiais</button>
            </ContainerAba>

            <Tooltip
              title="Nessa página você tem acesso a visualização de todas as filiais de todas as empresas"
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        )}

        {cadastrar && !editar && (
          <Search
            onSearch={(value: string) => {
              setSearch(value);
            }}
            nomePlaceHolder="Buscar"
          />
        )}

        {!cadastrar && (
          <AnimationContainer>
            <header>
              <h1>Dados da filial</h1>
            </header>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <p>ID</p>
              <InputOverview
                name="idEmpresa"
                value={cpfUserMaster[0].Id_Empresas}
                disabled
              />

              <p>Nome</p>
              <InputOverview name="nome" placeholder="Greendot" />

              <p>CNPJ</p>
              <InputOverview
                name="cnpj"
                placeholder="000.000.000/00000"
                mask="cnpj"
              />

              <p>Endereço</p>
              <InputOverview
                name="endereco"
                placeholder="Fortaleza, Ceará, Brasil"
              />

              <p>Número</p>
              <InputOverview name="numero" type="number" placeholder="000" />

              <Button type="submit" widthProps="100%">
                Cadastrar
              </Button>
            </Form>
          </AnimationContainer>
        )}

        {cadastrar && !response.length && (
          <ContainerSemResultados>
            <h2>Não existem filiais cadastradas ainda.</h2>
          </ContainerSemResultados>
        )}

        {cadastrar && !editar && !!response.length && (
          <>
            <ContainerTable>
              <table>
                <HeaderTable
                  headers={
                    empresaPrincipal.length > 0 ? headers : headersViewer
                  }
                  onSorting={(field: string, order: string) => {
                    setSorting({ field, order });
                  }}
                />
                <tbody>
                  {responseData.map(res => (
                    <tr key={`${res.ID}-${res.CNPJ}`}>
                      <td>{res.ID ? res.ID : '-'}</td>
                      <td>{res.NOME ? res.NOME : '-'}</td>
                      <td>{res.CNPJ ? maskCnpj(res.CNPJ) : '-'}</td>
                      <td>{res.ENDERECO ? res.ENDERECO : '-'}</td>
                      <td>{res.NUMERO ? res.NUMERO : '-'}</td>
                      <td>
                        {res.ATIVO && 'Ativo'}
                        {!res.ATIVO && 'Inativo'}
                      </td>
                      {empresaPrincipal.length > 0 && (
                        <td style={{ width: 'auto' }}>
                          <button
                            type="button"
                            onClick={() => {
                              setEditar(true);
                              setNomeFilialEdit(res.NOME);
                              setIdEmpresaFilialEdit(res.Id_Empresas);
                              setEnderecoFilialEdit(res.ENDERECO);
                              setNumeroFilialEdit(res.NUMERO);
                              setCnpjFilialEdit(res.CNPJ);
                              setStatusFilialEdit(res.ATIVO);
                              setFilialEdit(res);
                            }}
                          >
                            <img src={IconEdit} alt="iconEdit" />
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>

              <table id="tableListagemFiliais" style={{ display: 'none' }}>
                <HeaderTable
                  headers={
                    empresaPrincipal.length > 0 ? headers : headersViewer
                  }
                  onSorting={(field: string, order: string) => {
                    return setSorting({ field, order });
                  }}
                />
                <tbody>
                  {responseData.map(res => (
                    <tr key={`${res.ID}-${res.CNPJ}`}>
                      <td>{res.ID ? res.ID : '-'}</td>
                      <td>{res.NOME ? res.NOME : '-'}</td>
                      <td>{res.CNPJ ? maskCnpj(res.CNPJ) : '-'}</td>
                      <td>{res.ENDERECO ? res.ENDERECO : '-'}</td>
                      <td>{res.NUMERO ? res.NUMERO : '-'}</td>
                      <td>
                        {res.ATIVO && 'Ativo'}
                        {!res.ATIVO && 'Inativo'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ContainerTable>

            <ContainerPagination>
              {response.length > 0 && (
                <>
                  <div className="divPaginacao">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page: number) => setCurrentPage(page)}
                    />

                    <DropDownPagination
                      onChangeItems={(value: string) => {
                        setCurrentPage(1);
                        if (value === 'Todos') {
                          setITEMS_PER_PAGE(1);
                        } else {
                          setITEMS_PER_PAGE(Number(value));
                        }
                      }}
                      objetoEnum={dropDown}
                      minWidth={30}
                    />
                  </div>
                  <ReactHTMLTableToExcel
                    id="export-excel"
                    className="btn"
                    table="tableListagemFiliais"
                    filename={`listagem-filiais-${moment().format(
                      'DD-MM-YYYY',
                    )}`}
                    sheet="tablexls"
                    buttonText="Exportar Excel"
                  />
                </>
              )}
            </ContainerPagination>
          </>
        )}

        {cadastrar && editar && (
          <AnimationContainer>
            <header>
              <h1>Editar filial</h1>
            </header>

            <Form ref={formRef} onSubmit={handleSubmitEditar}>
              <p>ID</p>
              <InputOverview
                name="idEmpresaEdit"
                value={idEmpresaFilialEdit}
                onValue={value => setIdEmpresaFilialEdit(parseInt(value, 10))}
                disabled
              />

              <p>Nome</p>
              <InputOverview
                name="nomeEdit"
                value={nomeFilialEdit}
                onValue={value => setNomeFilialEdit(value)}
                placeholder="Nome da Filial"
              />

              <p>CNPJ</p>
              <InputOverview
                name="cnpjEdit"
                value={cnpjFilialEdit}
                onValue={value => setCnpjFilialEdit(value)}
                placeholder="CNPJ da Filial"
                mask="cnpj"
              />

              <p>Endereço</p>
              <InputOverview
                name="enderecoEdit"
                value={enderecoFilialEdit}
                onValue={value => setEnderecoFilialEdit(value)}
                placeholder="Endereço da Filial"
              />

              <p>Número</p>
              <InputOverview
                name="numeroEdit"
                type="number"
                value={numeroFilialEdit}
                onValue={value => setNumeroFilialEdit(parseInt(value, 10))}
                placeholder="Número da Filial"
              />

              <p>Status</p>
              <SelectCustom
                name="status"
                type="status"
                defaultValue="Status"
                optionsDataStatus={['Ativo', 'Inativo']}
                value={statusFilialEdit === true ? 'Ativo' : 'Inativo'}
                onValue={e => setStatusFilialEdit(e === 'Ativo')}
              />

              <ContainerButton>
                <Button
                  className="divButtonCancelar"
                  type="button"
                  widthProps="100%"
                  onClick={() => setEditar(false)}
                >
                  Cancelar
                </Button>
                <Button type="submit" widthProps="100%">
                  Salvar
                </Button>
              </ContainerButton>
            </Form>
          </AnimationContainer>
        )}
      </Container>

      {loading && <Loading />}
    </>
  );
};
