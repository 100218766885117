import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';
import { FiAlertCircle, FiEye, FiEyeOff } from 'react-icons/fi';
import Swal from 'sweetalert2';

import { Container, Error } from './styles';

import {
  maskCpf,
  maskTel,
  maskCnpj,
  maskCep,
  maskDateOfBirth,
  isCpf,
  isDate,
} from './mask';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  mask?: string;
  onValue?: (arg: string) => void;
}

export const InputOverview: React.FC<InputProps> = ({
  name,
  mask,
  onValue,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [whatMask, setWhatMask] = useState<string | number | null>();
  const [eyeClick, setEyeClick] = useState(true);

  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    if (mask === 'cpf' && isCpf(inputRef.current?.value) === true) {
      setIsFilled(true);
      return;
    }

    if (
      mask === 'cpf' &&
      isCpf(inputRef.current?.value) === false &&
      inputRef.current?.value !== ''
    ) {
      Swal.fire({
        icon: 'info',
        title: 'Esse CPF não é válido! ',
      });
      setWhatMask('');
      setIsFilled(false);
      return;
    }

    if (mask === 'dateOfBirth' && isDate(inputRef.current?.value) === true) {
      setWhatMask(inputRef.current?.value);
      setIsFilled(true);
      return;
    }
    if (
      mask === 'dateOfBirth' &&
      isCpf(inputRef.current?.value) === false &&
      inputRef.current?.value !== ''
    ) {
      setWhatMask('');

      Swal.fire({
        icon: 'info',
        title: 'A Data de nascimento é inválida! ',
      });

      setIsFilled(false);
      return;
    }

    // Se for vazio será false se estiver preenchido será true.
    setIsFilled(!!inputRef.current?.value);
  }, [mask]);

  const handleInputChange = useCallback(
    e => {
      if (onValue) {
        switch (mask) {
          case 'cpf':
            onValue(maskCpf(e.target.value));
            return;
          case 'cnpj':
            onValue(maskCnpj(e.target.value));
            return;
          case 'phone':
            onValue(maskTel(e.target.value));
            return;
          case 'cep':
            onValue(maskCep(e.target.value));
            return;
          case 'dateOfBirth':
            onValue(maskDateOfBirth(e.target.value));
            return;

          default:
            onValue(e.target.value);
        }
      }
      switch (mask) {
        case 'cpf':
          setWhatMask(maskCpf(e.target.value));
          return;
        case 'cnpj':
          setWhatMask(maskCnpj(e.target.value));
          return;
        case 'phone':
          setWhatMask(maskTel(e.target.value));
          return;
        case 'cep':
          setWhatMask(maskCep(e.target.value));
          return;
        case 'dateOfBirth':
          setWhatMask(maskDateOfBirth(e.target.value));
          return;

        default:
          setWhatMask(e.target.value);
      }
    },
    [mask, onValue],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
      <input
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        value={whatMask || ''}
        onChange={eForChange => {
          handleInputChange(eForChange);
        }}
        type={
          eyeClick &&
          (name.includes('password') || name.includes('confirmPassword'))
            ? 'password'
            : 'text'
        }
        {...rest}
      />

      {(name.includes('password') || name.includes('confirmPassword')) && (
        <button type="button" onClick={() => setEyeClick(!eyeClick)}>
          {!eyeClick && <FiEyeOff id="iconEye" size={17} />}
          {!!eyeClick && <FiEye id="iconEye" size={17} />}
        </button>
      )}

      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};
