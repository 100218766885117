import styled from 'styled-components';

export const ContainerHeader = styled.div`
  width: auto;
  display: flex;
  justify-content: center;

  h1 {
    color: ${props => props.theme.colors.textBlack};
    font-family: Poppins, sans-serif;
    text-align: center;
  }

  svg {
    margin: 0px auto;
    height: 100px;
    width: 100px;
    color: gray;
  }
`;

export const ContainerHeaderDados = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-weight: bold;
    margin-top: 20px;
    font-size: 24px;

    & + p {
      font-weight: normal;
      margin-top: 10px;
      font-size: 18px;
    }
  }

  div.info {
    display: flex;
    max-width: 600px;
  }

  div.agenda {
    display: flex;
    flex-direction: column;
    flex: 2;
    justify-content: center;
    align-items: flex-start;

    p {
      font-size: 16px;
      font-weight: 600;
    }
  }

  div.user {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;

    img {
      height: 120px;
      width: 120px;
      border-radius: 60px;
      object-fit: cover;
    }
    svg {
      background-color: ${props => props.theme.colors.grayWhite};
      color: ${props => props.theme.colors.grayBlack};
      height: 120px;
      width: 120px;
      border-radius: 50%;
      padding: 6px;
    }
  }

  div.dados {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;

    div.title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;

      p {
        font-size: 16px;
        font-weight: 500;
      }
    }
    div.table {
      border: 1px solid ${props => props.theme.colors.grayWhite};
      border-radius: 10px;
      padding: 10px;
      max-height: 200px;
      overflow: auto;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
`;

export const ContainerButtonAtualizar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 40px;
  }
`;
