import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';
import { InputOverview } from '../../../components/InputOverview';
import { useAuth } from '../../../hooks/auth';
import Button from '../../../components/Button';
import { SelectCustom } from '../../../components/SelectCustom';

import { AnimationContainer, DivPass } from './styles';
import api from '../../../services/api';
import { Loading } from '../../../components/Loading';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../hooks/toast';
import { Empresa } from '../../../models/Empresa';
import { User } from '../../../models/User';

interface DataForm {
  confirmPassword: string;
  cpf: string;
  email: string;
  filial: string;
  idEmpresa: string;
  nascimento: string;
  nivel: string;
  nome: string;
  password: string;
  phone: string;
}

interface PostUser {
  nome: string;
  email: string;
  password: string;
  dataNascimento: string;
  cpf: string;
  telefone: string;
}

interface Props {
  empresas: Empresa[];
}

export const Cadastro: React.FC<Props> = ({ empresas }) => {
  const { user, empresaPrincipal } = useAuth();
  const { addToast } = useToast();
  const { go } = useHistory();

  const [loading, setLoading] = useState(false);
  const [filialId, setFilialId] = useState<number>();
  const [nivel, setNivel] = useState('ASG');

  const formRef = useRef<FormHandles>(null);

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  const createAdm = useCallback(
    (data: PostUser, companyId: number) => {
      // console.log('TESTE ADM', data, companyId);
      setLoading(true);
      api
        .post('/empresas/adminLocal', {
          companyId,
          nome: data.nome,
          email: data.email,
          password: data.password,
          dataNascimento: data.dataNascimento,
          cpf: data.cpf,
          telefone: data.telefone,
        })
        .then(async resp => {
          const userAdd: User = resp.data;
          await api.post('/nameUser', { userId: userAdd.id, name: data.nome });

          setLoading(false);
          addToast({
            title: 'Sucesso!',
            description: `O usuário foi cadastrado com sucesso.`,
            type: 'success',
          });

          setTimeout(() => {
            go(0);
          }, 1500);
        })
        .catch(() => {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar!',
            description: 'Verifique os dados!',
          });
        });
    },
    [addToast, go],
  );

  const createCoordenador = useCallback(
    (data: PostUser, filId: number) => {
      // console.log('TESTE COOR', data, filId);
      setLoading(true);
      api
        .post('/empresas/coordenador', {
          filiaId: filId,
          nome: data.nome,
          email: data.email,
          password: data.password,
          dataNascimento: data.dataNascimento,
          cpf: data.cpf,
          telefone: data.telefone,
        })
        .then(async resp => {
          const userAdd: User = resp.data;
          await api.post('/nameUser', { userId: userAdd.id, name: data.nome });

          setLoading(false);
          addToast({
            title: 'Sucesso!',
            description: `O usuário foi cadastrado com sucesso.`,
            type: 'success',
          });

          setTimeout(() => {
            go(0);
          }, 1500);
        })
        .catch(() => {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar!',
            description: 'Verifique os dados!',
          });
        });
    },
    [addToast, go],
  );

  const createAuditor = useCallback(
    (data: PostUser, companyId: number) => {
      // console.log('TESTE AUD', data, companyId);
      setLoading(true);
      api
        .post('/empresas/auditor', {
          companyId,
          nome: data.nome,
          email: data.email,
          password: data.password,
          dataNascimento: data.dataNascimento,
          cpf: data.cpf,
          telefone: data.telefone,
        })
        .then(async resp => {
          const userAdd: User = resp.data;
          await api.post('/nameUser', { userId: userAdd.id, name: data.nome });

          setLoading(false);
          addToast({
            title: 'Sucesso!',
            description: `O usuário foi cadastrado com sucesso.`,
            type: 'success',
          });

          setTimeout(() => {
            go(0);
          }, 1500);
        })
        .catch(() => {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar!',
            description: 'Verifique os dados!',
          });
        });
    },
    [addToast, go],
  );

  const createASG = useCallback(
    (data: PostUser, filId: number) => {
      setLoading(true);
      api
        .post('/empresas/asg', {
          filiaId: filId,
          nome: data.nome,
          email: data.email,
          password: data.password,
          dataNascimento: data.dataNascimento,
          cpf: data.cpf,
          telefone: data.telefone,
        })
        .then(async resp => {
          const userAdd: User = resp.data;
          await api.post('/nameUser', { userId: userAdd.id, name: data.nome });
          setLoading(false);
          addToast({
            title: 'Sucesso!',
            description: `O usuário foi cadastrado com sucesso.`,
            type: 'success',
          });

          setTimeout(() => {
            go(0);
          }, 1500);
        })
        .catch(() => {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar!',
            description: 'Verifique os dados!',
          });
        });
    },
    [addToast, go],
  );

  const handleSubmit = useCallback(
    async (data: DataForm) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um email válido'),
          cpf: Yup.string().required('CPF obrigatório'),
          nascimento: Yup.string().required('Data de nascimento obrigatória'),
          phone: Yup.string().required('Telefone obrigatório'),
          password: Yup.string().min(6, 'No mínimo 6 dígitos'),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Senhas precisam ser iguais',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const obj: PostUser = {
          cpf: data.cpf.replace(/\D+/g, ''),
          dataNascimento: data.nascimento,
          email: data.email,
          nome: data.nome,
          password: data.password,
          telefone: data.phone.replace(/\D+/g, ''),
        };
        const companyId =
          empresaPrincipal.length > 0 ? empresaPrincipal[0].ID : -1;

        if (data.nivel === 'Administrador') {
          createAdm(obj, companyId);
        } else if (data.nivel === 'Coordenador') {
          createCoordenador(obj, Number(data.idEmpresa));
        } else if (data.nivel === 'Auditor') {
          createAuditor(obj, companyId);
        } else if (data.nivel === 'ASG') {
          createASG(obj, Number(data.idEmpresa));
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Campos em branco',
            description: 'Existem campos obrigatórios não preenchidos.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao cadastrar o dados',
          });
        }
      }
    },
    [
      addToast,
      createASG,
      createAdm,
      createAuditor,
      createCoordenador,
      empresaPrincipal,
    ],
  );

  const optionNivel = useMemo(() => {
    if (isCoordenador) {
      return ['Coordenador', 'ASG'];
    }
    return ['Administrador', 'Coordenador', 'Auditor', 'ASG'];
  }, [isCoordenador]);

  const filialSel = useMemo(() => {
    if (filialId === undefined && empresas.length > 0) {
      const id = isCoordenador ? user.companyId : empresas[0].ID;
      let aux = '';
      empresas.forEach(item => {
        if (item.ID === id) {
          aux = `${item.ID} - ${item.NOME}`;
          setFilialId(item.ID);
        }
      });
      return aux;
    }
    let aux = '';
    empresas.forEach(item => {
      if (item.ID === filialId) {
        aux = `${item.ID} - ${item.NOME}`;
      }
    });
    return aux;
  }, [filialId, empresas, isCoordenador, user.companyId]);

  return (
    <AnimationContainer>
      <header>
        <h1>Dados da filial</h1>
      </header>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <p>ID</p>
        <InputOverview name="idEmpresa" value={user.companyId} disabled />

        <p>Nível de Acesso</p>
        <SelectCustom
          name="nivel"
          type="status"
          optionsDataStatus={optionNivel}
          value={nivel}
          onValue={e => setNivel(e)}
        />

        {(nivel === 'Coordenador' || nivel === 'ASG') && (
          <>
            <p>Filial</p>
            <SelectCustom
              name="filial"
              defaultValue="Empresa"
              optionsDataCompany={empresas}
              value={filialSel}
              disabled={isCoordenador}
              onValue={e => setFilialId(parseInt(e.split('-')[0], 10))}
            />
          </>
        )}

        <p>Nome</p>
        <InputOverview name="nome" placeholder="Greendot" />

        <p>Email</p>
        <InputOverview name="email" placeholder="user@..." mask="email" />

        <p>CPF</p>
        <InputOverview name="cpf" placeholder="000.000.000-00" mask="cpf" />

        <p>Data de Nascimento</p>
        <InputOverview
          name="nascimento"
          placeholder="01/01/2004"
          mask="dateOfBirth"
        />

        <p>Telefone</p>
        <InputOverview
          name="phone"
          placeholder="(85) 99999-9999"
          mask="phone"
        />

        <p>Senha</p>
        <DivPass>
          <InputOverview name="password" mask="password" />
        </DivPass>

        <p>Confirmar senha</p>
        <DivPass>
          <InputOverview name="confirmPassword" mask="confirmPassword" />
        </DivPass>
        <Button type="submit" widthProps="100%">
          Cadastrar
        </Button>
      </Form>

      {loading && <Loading />}
    </AnimationContainer>
  );
};
