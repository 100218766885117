import styled, { keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 720px;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 992px) {
    max-width: 100%;

    align-items: flex-start;
  }
`;

export const AnimationContainer = styled.div`
  width: 100%;
  padding: 30px;
  animation: ${appearFromLeft} 1s;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    width: 388px;

    @media screen and (max-width: 992px) {
      width: 90%;
    }

    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 40px;

      color: ${props => props.theme.colors.bluePrimary};
    }

    p {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 16px;
      margin-bottom: 8px;

      display: flex;
      align-items: center;

      color: ${props => props.theme.colors.textBlack};
    }

    article {
      margin-top: 24px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: ${props => props.theme.colors.bluePrimary};
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    position: absolute;
    width: 400px;
    height: 400px;
    object-fit: contain;
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
`;
