import styled from 'styled-components';

export const Wrapper = styled.button`
  background-color: transparent;
  border: 0;
  width: 200px;
  height: 80px;
  border-radius: 4px;
  border: 1px #dcdcdc solid;
  display: flex;
`;

export const StatusLine = styled.div`
  background-color: green;
  margin-right: auto;
  width: 8px;
  height: 100%;
  border-radius: 4px 0px 0px 4px;
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
  padding: 8px;
  gap: 4px;

  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;

export const ScheduleNumber = styled.h2`
  font-size: 10px;
  font-weight: 400;
  color: #686868;
`;

export const EnvironmentName = styled.h2`
  font-size: 12px;
  font-weight: 700;
  color: #252525;
`;

export const SectorName = styled.h2`
  font-size: 10px;
  font-weight: 400;
  color: #686868;
`;

export const ContainerFiUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eaeaea;
  width: 14px;
  height: 14px;
  border-radius: 50px;
  margin-right: 4px;
  min-width: fit-content;
`;

export const ContributorName = styled.h2`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  font-weight: 400;
  color: #686868;
`;

export const FooterCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Hours = styled.h2`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 10px;
  color: #686868;
  min-width: fit-content;
  svg {
    width: 10px;
    height: 10px;
    margin-right: 2px;
  }
  &:nth-child(2) {
    margin-left: auto;
    margin-right: 8px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
`;
