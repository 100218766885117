import React from 'react';

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';

import styles from './styles/colors';

import { DrawerHeader } from './components/DrawerHeader';

import { HeaderMenu } from './components/HeaderMenu';

import { Container, Content } from './styles';

import GlobalStyle from './styles/global';

import AppProvider from './hooks';

import Routes from './routes';

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <Router>
      <ThemeProvider theme={styles}>
        <QueryClientProvider client={queryClient}>
          <AppProvider>
            <Container>
              <DrawerHeader />
              <Content>
                <HeaderMenu />
                <Routes />
              </Content>
            </Container>
          </AppProvider>
          <GlobalStyle />
        </QueryClientProvider>
      </ThemeProvider>
    </Router>
  );
};
export default App;
