import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Loading } from '../../../components/Loading';
import ModalConexao from '../../../components/ModalConexao';
import { InputOverview } from '../../../components/InputOverview';
import Button from '../../../components/Button';
import api from '../../../services/api';
import CreditsSpan from '../../../components/Credits';
import ImgLogo from '../../../assets/Tapp.svg';

import { Container, Content, AnimationContainer, Background } from './styles';

interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().min(6, 'No mínimo 6 dígitos'),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Senhas precisam ser iguais',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const token = location.search.replace('?search=', '');
        if (!token) {
          throw new Error();
        }
        await api
          .post('/password/reset', {
            password: data.password,
            passwordConfirmation: data.password_confirmation,
            token,
          })
          .then(() => {
            setLoading(false);
            addToast({
              type: 'success',
              title: 'Sucesso',
              description: 'Senha resetada com sucesso',
            });

            history.push('/');
          })
          .catch(() => {
            setLoading(false);

            addToast({
              type: 'error',
              title: 'Erro',
              description: 'Falha ao resetar senha',
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao resetar senha',
          description: 'Ocorreu um erro ao resetar senha, tente novamente',
        });
      }
    },
    [addToast, history, location.search],
  );

  return (
    <>
      <ModalConexao />
      <Container>
        <Background>
          <img src={ImgLogo} className="logo" alt="ImgLogo" />
        </Background>
        <Content>
          <AnimationContainer>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Resetar senha</h1>

              <p>Nova senha</p>
              <InputOverview name="password" placeholder="********" />

              <p>Confirmar senha</p>
              <InputOverview
                name="password_confirmation"
                placeholder="********"
              />

              <article>
                <Button type="submit">Alterar senha</Button>
              </article>
            </Form>
          </AnimationContainer>

          <CreditsSpan />
        </Content>
      </Container>

      {loading && <Loading />}
    </>
  );
};

export default ResetPassword;
