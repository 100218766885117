import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 200px);
  overflow: auto;
  overflow-x: hidden;

  header {
    padding-bottom: 30px;
    border-bottom: 0.6px solid ${props => props.theme.colors.grayBlack};
    margin-bottom: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 45px;
      color: ${props => props.theme.colors.textBlack};
    }
  }

  /* select {
    background: ${props => props.theme.colors.whitePrimary};
    border: 1px solid ${props => props.theme.colors.grayWhite};
    box-sizing: border-box;
    border-radius: 8px;
    padding: 15px 16px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;

    flex: 1;
    display: flex;
    align-items: center;

    color: ${props => props.theme.colors.textBlack};

    &::placeholder {
      color: ${props => props.theme.colors.grayBlack};
    }

    @media screen and (max-width: 992px) {
      width: 90%;
    }
  } */

  section {
    overflow: auto;

    table {
      width: 100%;
      background: ${props => props.theme.colors.whitePrimary};
      border-collapse: collapse;

      thead {
        position: sticky;
        top: 0;
        z-index: 2;
        background: ${props => props.theme.colors.whitePrimary};

        th {
          padding: 20px 30px;
          text-align: left;
          white-space: nowrap;

          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: ${props => props.theme.colors.textBlack};
        }

        th:nth-last-child(-n + 1) {
          text-align: center;
        }
      }

      tbody {
        tr {
          td {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: ${props => props.theme.colors.textBlack};
            padding: 20px 30px;
            white-space: nowrap;

            button {
              border: none;
              background: transparent;
              margin: 0 auto;

              display: flex;
              align-items: center;
              justify-content: center;
            }

            aside {
              display: flex;
              align-items: center;
              justify-content: center;

              button {
                background: transparent;
                border: none;
                color: ${props => props.theme.colors.grayBlack};

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                transition: color 0.2s;

                &:hover {
                  color: ${props => props.theme.colors.greenPrimary};
                }

                & + button {
                  margin-left: 15px;

                  transition: color 0.2s;

                  &:hover {
                    color: ${props => props.theme.colors.redPrimary};
                  }
                }

                svg {
                  width: 25px;
                  height: 25px;
                }
              }
            }
          }

          &:nth-child(odd) {
            background-color: ${props => props.theme.colors.grayWhite};
          }
        }
      }
    }
  }

  button[type='submit'] {
    margin: 30px auto 0 auto;
  }
`;

export const ButtonPlus = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.bluePrimary};
  border-radius: 16px;
  margin-left: 10px;
  height: 50px;
  width: 50px;
  border: none;
  transition: 0.2s;

  &:hover {
    background: ${props => shade(0.2, props.theme.colors.bluePrimary)};
  }

  svg {
    color: ${props => props.theme.colors.whitePrimary};
  }
`;

export const ValSelectedButton = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin: 10px 0px;
  width: 432px;
  overflow: auto;

  button {
    height: 36px;
    min-width: 36px;
    border-radius: 8px;
    background: ${props => props.theme.colors.whitePrimary};
    border: 1px solid ${props => props.theme.colors.bluePrimary};
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    margin: 0px 10px;
    padding: 4px;

    p {
      color: ${props => props.theme.colors.textBlack};
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      margin: 0px;
    }
  }
  button:hover {
    background: ${props => props.theme.colors.redPrimary};
    border: 1px solid ${props => props.theme.colors.redPrimary};
    p {
      color: ${props => props.theme.colors.whitePrimary};
    }
  }
`;

export const DivMensal = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 18px;
  max-width: 432px;

  div.input {
    flex: 1;
    display: flex;
    align-items: center;
  }
`;

export const DivToot = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  column-gap: 10px;

  svg {
    height: 22px;
    width: 22px;
    color: ${props => props.theme.colors.bluePrimary};
  }
`;
